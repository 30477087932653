import { createAction } from '../../utils/actions';

export const setIntegration = createAction('integration/set');
export const setSegments = createAction('integration/set_segments');
export const addSegment = createAction('integration/add_segment');
export const updateSegment = createAction('integration/update_segment');
export const removeSegment = createAction('integration/remove_segment');
export const setWebhookEvents = createAction('integration/set_webhook_events');
export const addWebhookEvents = createAction('integration/add_webhook_events');
export const clearIntegrationAction = createAction('integration/clear');
export const setHubSpotConnections = createAction('integration/set_hubspot_connections');
export const removeHubSpotConnection = createAction('integration/remove_hubspot_connections');
export const setMailChimpConnections = createAction('integration/set_mailchimp_connections');
export const removeMailChimpConnection = createAction('integration/remove_mailchimp_connections');

