import { useCallback, useMemo, useState } from 'react';
import { message, notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import useApi from './useApi';
import {
  eventsSelector,
  hubspotConnectionsSelector,
  integrationSelector,
  mailChimpConnectionsSelector,
  segmentsSelector
} from '../store/integration/selectors';
import {
  addSegment,
  addWebhookEvents,
  clearIntegrationAction,
  removeHubSpotConnection,
  removeMailChimpConnection,
  removeSegment,
  setHubSpotConnections,
  setIntegration,
  setMailChimpConnections,
  setSegments,
  setWebhookEvents,
  updateSegment
} from '../store/integration/actions';
import {
  HubSpotConnection,
  IntegrationType,
  MailChimpConnection,
  Segment,
  WebhookEvents
} from '../store/integration/types';
import errorParser, { Errors } from '../utils/errors';
import * as T from './requestTypes';
import { CreateCustomDomain, UpdateCustomDomain } from './requestTypes';
import { userSelector } from '../store/auth/selectors';
import { useHistory } from 'react-router-dom';
import { queryToString } from '../utils/query';
import { CommonStoreEnum } from '../store/common/types';
import { brandsTitlesAdapter, domainsSelector } from '../store/common/selectors';
import { setCommonField } from '../store/common';
import { BrandTitles, CustomDomain } from '../utils/type';

type iUseIntegration = {
  getIntegration: () => void;
  regenerateKey: () => void;
  clearIntegration: () => void;
  errors: Errors;
  createSegment: (title: string, cb?: () => void) => void;
  editSegment: (id: number, title: string, cb?: () => void) => void;
  getSegments: () => void;
  events: WebhookEvents;
  getWebhookEvents: (page: number) => void;
  deleteSegment: (id: number) => void;
  resendEvent: (id: string) => void;
  clearErrors: () => void;
  getEvent: (id: string, cb: (value: EventDetails) => void) => void;
  integration: IntegrationType;
  loaded: boolean;
  busy: boolean;
  segments: Segment[];
  webhooks: string[];
  createIntegration: (data: T.CreateIntegrationData, cb?: () => void) => void;
  updateIntegration: (data: T.CreateIntegrationData, cb?: () => void) => void;

  createBrandHubSpotConnection: (brandId: number, data: T.CreateHubSpotConnectionData, cb: () => void) => void;
  getBrandHubSpotClients: (brandId: number, id: number, cb: (value: any) => void) => void;
  getAccountBrandsHubSpotConnections: (accountId?: number) => void;
  deleteBrandHubSpotConnection: (brandId: number, id: number) => void;
  confirmBrandHubSpotConnection: () => void;
  hubspotConnections: HubSpotConnection[];
  onSetHubSpotCode: (code: string) => void;
  testHubSpotSubmitContact: (id: number, brandId: number, data: T.TestHubSpotContactCreationData) => void;

  createBrandMailChimpConnection: (brandId: number, data: T.CreateHubSpotConnectionData, cb: (id: number) => void) => void;
  getBrandMailChimpAudiences: (brandId: number, id: number, cb: (value: any) => void) => void;
  getBrandMailChimpAudiencesFields: (brandId: number, id: number, listId: T.GetMailChimpListFieldsData, cb: (value: any) => void) => void;
  getAccountBrandsMailChimpConnections: (accountId?: number) => void;
  deleteBrandMailChimpConnection: (brandId: number, id: number) => void;
  confirmBrandMailChimpConnection: (id: number, code: string) => void;
  mailChimpConnections: MailChimpConnection[];
  testMailChimpSubmitContact: (id: number, brandId: number, data: T.TestMailChimpContactCreationData) => void;

  getCustomDomains: () => void;
  createCustomDomain: (data: CreateCustomDomain) => void;
  updateCustomDomain: (data: UpdateCustomDomain) => void;
  domain: CustomDomain | null;
  brandTitles: BrandTitles[];
  getBrandsTitles: () => void;
  deleteCustomDomain: (cb: () => void) => void;
}

export type EventDetails = {
  accountId: number;
  body: string;
  createdAt: string;
  errorMessage?: string;
  status: string;
  type: string;
  updatedAt: string;
  campaignId: number;
  _id: string;
}

const useIntegration = ():iUseIntegration => {
  const dispatch = useDispatch();
  const [ loaded, onChangeLoaded ] = useState<boolean>(false);
  const [ busy, onChangeBusy ] = useState<boolean>(false);
  const integration = useSelector(integrationSelector);
  const [ errors, onChangeErrors ] = useState({});
  const segments = useSelector(segmentsSelector);
  const events = useSelector(eventsSelector);
  const hubspotConnections = useSelector(hubspotConnectionsSelector);
  const mailChimpConnections = useSelector(mailChimpConnectionsSelector);

  const user = useSelector(userSelector);
  const domain = useSelector(domainsSelector);
  const brandTitles = useSelector(brandsTitlesAdapter);
  const isAdmin = useMemo(() => user.role === 'admin' || user.role === 'super_admin', [user.role]);
  const [ hubSpotCode, onChangeHubSpotCode ] = useState<string | null>(null);
  const api = useApi();
  const history = useHistory();


  const getIntegration = useCallback(() => {
    api.getAccountIntegration()
      .then((response: AxiosResponse) => {
        onChangeLoaded(true);
        dispatch(setIntegration(response.data));
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404) {
          onChangeLoaded(true);
        }
      })
  }, []);


  const regenerateKey = useCallback(() => {
    api.regenerateIntegrationKey()
      .then((response: AxiosResponse) => {
        message.success('API key re-generated');
        dispatch(setIntegration(response.data));
      });
  }, []);


  const createIntegration = useCallback((data: T.CreateIntegrationData, cb?: () => void) => {
    onChangeErrors({});
    onChangeBusy(true);
    api.createAccountIntegration(data)
      .then((response: AxiosResponse) => {
        notification.success({
          message: 'Success',
          description: 'Integration was successfully created'
        });
        dispatch(setIntegration(response.data));
        onChangeBusy(false);
        if (cb) cb();
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
        if (error.response?.data.statusCode === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      })
  }, []);


  const updateIntegration = useCallback((data: T.CreateIntegrationData, cb?: () => void) => {
    onChangeErrors({});
    api.updateAccountIntegration(data)
      .then((response: AxiosResponse) => {
        message.success('Integration updated');
        dispatch(setIntegration(response.data));
        if (cb) cb();
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
        if (error.response?.data.statusCode === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      });
  }, []);

  const getSegments = useCallback(() => {
    if (isAdmin) return;
    api.getIntegrationSegments()
      .then((response: AxiosResponse) => {
        dispatch(setSegments(response.data));
      })
  }, [isAdmin]);

  const createSegment = useCallback((title: string, cb?: () => void) => {
    onChangeErrors({});
    onChangeBusy(true);
    api.createIntegrationSegments({ title })
      .then((response: AxiosResponse) => {
        dispatch(addSegment(response.data));
        if (cb) cb();
        onChangeBusy(false);
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
        if (error.response?.data.statusCode === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      });
  }, []);

  const editSegment = useCallback((id: number, title: string, cb?: () => void) => {
    onChangeErrors({});
    onChangeBusy(true);
    api.updateIntegrationSegments(id, { title })
      .then((response: AxiosResponse) => {
        dispatch(updateSegment(response.data));
        notification.success({
          message: 'Success',
          description: 'Segment was successfully updated'
        });
        if (cb) cb();
        onChangeBusy(false);
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
        if (error.response?.data.statusCode === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      });
  }, []);

  const deleteSegment = useCallback((id: number) => {
    api.deleteIntegrationSegments(id)
      .then(() => {
        dispatch(removeSegment(id));
      });
  }, []);

  const resendEvent = useCallback((id: string) => {
    api.resendIntegrationWebhookEvent(id)
      .then(() => {
        message.success('Webhook event was successfully resend.')
      });
  }, []);

  const getEvent = useCallback((id: string, cb: (value: EventDetails) => void) => {
    api.getIntegrationWebhookEvent(id)
      .then((response: AxiosResponse) => {
        cb(response.data);
      });
  }, []);

  const webhooks = useMemo(() => ([
    'game.ended',
    'gameplay.finished',
    'player.created',
    'player.updated',
    'prize.assigned',
    'segment.created',
    'segment.updated',
    'segment.deleted',
  ]), []);

  const getWebhookEvents = useCallback((page: number) => {
    api.getIntegrationWebhookEvents(page)
      .then((response: AxiosResponse) => {
        if (page === 1) {
          dispatch(setWebhookEvents(response.data));
        } else {
          dispatch(addWebhookEvents(response.data));
        }
      })
  }, []);

  const getAccountBrandsHubSpotConnections = useCallback((accountId?: number) => {
    if (!isAdmin) {
      api.getAccountBrandsHubSpotConnections()
        .then((response: AxiosResponse) => {
          dispatch(setHubSpotConnections(response.data));
        });
      return;
    }

    if (accountId && isAdmin) {
      api.adminGetAccountBrandsHubSpotConnections(accountId)
        .then((response: AxiosResponse) => {
          dispatch(setHubSpotConnections(response.data));
        });
    }
  }, [isAdmin]);

  const createBrandHubSpotConnection = useCallback((brandId: number, data: T.CreateHubSpotConnectionData, cb: () => void) => {
    api.setBrandHubSpotConnection(brandId, data)
      .then(cb);
  }, []);

  const confirmBrandHubSpotConnection = useCallback(() => {
    if (!hubSpotCode) return;
    api.confirmBrandHubSpotConnection({ hubspot_token: hubSpotCode })
      .then(() => {
        message.success('HubSpot connection successfully created');
        history.push('/settings/integrations?integration=hubspot&section=settings');
      })
      .catch(() => {
        history.push('/settings/integrations?integration=hubspot&section=settings');
      })
  }, [hubSpotCode]);

  const getBrandHubSpotClients = useCallback((brandId: number, id: number, cb: (value: any) => void) => {
    const func = isAdmin ? api.adminGetBrandHubSpotClientsFields : api.getBrandHubSpotClientsFields
    func(id, brandId)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
  }, []);

  const deleteBrandHubSpotConnection = useCallback((brandId: number, id: number) => {
    api.deleteBrandHubSpotConnection(brandId, id)
      .then(() => {
        message.success('HubSpot connection successfully deleted');
        dispatch(removeHubSpotConnection(id));
      })
  }, []);

  const testHubSpotSubmitContact = useCallback((id: number, brandId: number, data: T.TestHubSpotContactCreationData) => {
    onChangeErrors({});
    api.testHubSpotSubmitContact(id, brandId, data)
      .then(() => {
        message.success('Test contact has been successfully sent');
      })
      .catch((error: AxiosError) => {
        if (error.response?.data.statusCode === 422) {
          onChangeErrors({ error: [error.response?.data?.message] });
        }
        if (error.response?.data.statusCode === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      })
  }, []);

  const clearIntegration = useCallback(() => {
    dispatch(clearIntegrationAction());
  }, []);

  const clearErrors = useCallback(() => {
    onChangeErrors({});
  }, []);

  const onSetHubSpotCode = useCallback((code: string) => {
    onChangeHubSpotCode(code);
  }, []);

  const getAccountBrandsMailChimpConnections = useCallback((accountId?: number) => {
    if (isAdmin && accountId) {
      api.adminGetAccountBrandsMailChimpConnections(accountId)
        .then((response: AxiosResponse) => {
          dispatch(setMailChimpConnections(response.data))
        })
    }

    if (!isAdmin) {
      api.getAccountBrandsMailChimpConnections()
        .then((response: AxiosResponse) => {
          dispatch(setMailChimpConnections(response.data))
        })
    }
  }, []);

  const createBrandMailChimpConnection = useCallback((brandId: number, data: T.CreateMailChimpConnectionData, cb: (id: number) => void) => {
    api.createBrandMailChimpConnection(brandId, data)
      .then((response: AxiosResponse) => {
        cb(response.data.id);
      })
  }, []);

  const confirmBrandMailChimpConnection = useCallback((id: number, code: string) => {
    api.confirmBrandMailChimpConnection(id, { code })
      .then(() => {
        message.success('Mailchimp successfully created')
      })
  }, [hubSpotCode]);

  const getBrandMailChimpAudiences = useCallback((brandId: number, id: number, cb: (value: any) => void) => {
    const func = isAdmin ? api.adminGetBrandMailChimpAudiences : api.getBrandMailChimpAudiences
    func(id, brandId)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
  }, []);

  const getBrandMailChimpAudiencesFields = useCallback((brandId: number, id: number, data: T.GetMailChimpListFieldsData, cb: (value: any) => void) => {
    const query = queryToString(data);
    const func = isAdmin ? api.adminGetBrandMailChimpAudiencesFields : api.getBrandMailChimpAudiencesFields
    func(id, query, brandId)
      .then((response: AxiosResponse) => {
        cb(response.data);
      })
  }, []);

  const deleteBrandMailChimpConnection = useCallback((brandId: number, id: number) => {
    api.deleteBrandMailChimpConnection(brandId, id)
      .then(() => {
        message.success('Mailchimp connection successfully deleted')
        dispatch(removeMailChimpConnection(id))
      })
  }, []);

  const testMailChimpSubmitContact = useCallback((id: number, brandId: number, data: T.TestMailChimpContactCreationData) => {
    onChangeErrors({});
    api.testMailChimpSubmitContact(id, brandId, data)
      .then(() => {
        message.success('Test contact has been sent to mailchimp')
      })
      .catch((error: AxiosError) => {
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
        if (error.response?.data.statusCode === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      })
  }, []);

  const getCustomDomains = useCallback(() => {
    api.getCustomDomains()
      .then((response: AxiosResponse) => {
        if (response.data) {
          dispatch(setCommonField(response.data, CommonStoreEnum.DOMAIN))
        }
      })
  }, []);

  const createCustomDomain = useCallback((data: CreateCustomDomain) => {
    onChangeBusy(true);
    api.createCustomDomain(data)
      .then((response: AxiosResponse) => {
        onChangeBusy(false);
        if (response.data) {
          dispatch(setCommonField(response.data, CommonStoreEnum.DOMAIN))
        }
      })
      .catch(() => {
        onChangeBusy(false);
      })
  }, []);

  const updateCustomDomain = useCallback((data: UpdateCustomDomain) => {
    onChangeErrors({});
    onChangeBusy(true);
    api.updateCustomDomain(data)
      .then((response: AxiosResponse) => {
        onChangeBusy(false);
        message.success('Custom domain successfully updated');
        if (response.data) {
          dispatch(setCommonField(response.data, CommonStoreEnum.DOMAIN))
        }
      })
      .catch((error) => {
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
        onChangeBusy(false);
      })
  }, []);

  const deleteCustomDomain = useCallback((cb: () => void) => {
    onChangeBusy(true);
    api.deleteCustomDomain()
      .then(() => {
        message.success('Custom domain successfully deleted');
        onChangeBusy(false);
        dispatch(setCommonField(null, CommonStoreEnum.DOMAIN));
        cb();
      })
      .catch(() => {
        onChangeBusy(false);
        message.error('Oops! Something went wrong!');
      })
  }, []);

  const getBrandsTitles = useCallback(() => {
    api.getBrandsTitles()
      .then((response: AxiosResponse) => {
        dispatch(setCommonField(response.data, CommonStoreEnum.BRAND_TITLES))
      })
  }, []);

  return {
    getIntegration,
    clearErrors,
    resendEvent,
    editSegment,
    regenerateKey,
    webhooks,
    getSegments,
    clearIntegration,
    getEvent,
    getWebhookEvents,
    busy,
    deleteSegment,
    loaded,
    integration,
    createSegment,
    createIntegration,
    errors,
    events,
    segments,
    updateIntegration,
    createBrandHubSpotConnection,
    getBrandHubSpotClients,
    getAccountBrandsHubSpotConnections,
    deleteBrandHubSpotConnection,
    hubspotConnections,
    confirmBrandHubSpotConnection,
    testHubSpotSubmitContact,
    onSetHubSpotCode,
    createBrandMailChimpConnection,
    getBrandMailChimpAudiences,
    getAccountBrandsMailChimpConnections,
    deleteBrandMailChimpConnection,
    confirmBrandMailChimpConnection,
    mailChimpConnections,
    testMailChimpSubmitContact,
    getBrandMailChimpAudiencesFields,
    getCustomDomains,
    createCustomDomain,
    domain,
    getBrandsTitles,
    brandTitles,
    deleteCustomDomain,
    updateCustomDomain,
  }
}

export default useIntegration;
