import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { message, Modal } from 'antd';
import cx from 'classnames';
import { InputProps } from 'antd/lib/input';
import Typo from '../../typo';
import Button from '../button';
import Input from '../input';
import useUpload from '../../../domain/useUpload';
import styles from './upload.module.css';
import CropBox from './cropper';
import BackgroundEditor from './backgroundedit';

interface Props extends InputProps {
  className?: string;
  name: string;
  value?: string;
  type: 'gameBackground' | 'gameSettings' | 'favicon' | 'newsPost';
  refProp?: any;
  disabled?: boolean;
  accept?: string;
  maxWidth?: number;
  maxHeight?: number;
  removable?: boolean;
  disableRemove?: boolean;
  onRemoveKeepValue?: boolean;
  onRemove?: () => void;
  onChange: (value: any) => void;
  onChangeUrl?: (value: any) => void;
  tourId?: string;
  mockUpload?: boolean;
}

enum Modals {
  UPLOAD,
  PREVIEW,
  BACKGROUND,
  CROP
}

const Uploader:FunctionComponent<Props> = ({ name, onRemove, type, disabled, removable, onRemoveKeepValue, disableRemove, maxWidth, maxHeight, value, accept = ".svg, .png, .jpg, .jpeg, .gif, .webp, .avif, .jfif", onChange, className, onChangeUrl, tourId}) => {
  const defaultSizes = useMemo(() => ({
    width: 0,
    height: 0,
    xy: 1,
    yx: 1
  }), []);

  const fileTypesSizeMapping: Record<string, number> = useMemo(() => ({
    gameBackground: 1024 * 1024 * 5,
    gameSettings: 1024 * 1024 * 1.5,
    favicon: 1024 * 100,
  }), []);

  const { upload, url, progress, cancelUpload } = useUpload();
  const [ showModal, onChangeShowModal ] = useState<Modals | null>(null);
  const [ file, setFile ] = useState<File | null>(null);
  const [ sizes, onChangeSize ] = useState(defaultSizes);
  const [ fileUrl, onChangeFileUrl ] = useState<string | ArrayBuffer | null>(null);
  const [ originalFile, setOriginalFile ] = useState<File | null>(null);
  const [ originalFileUrl, onChangeOriginalFileUrl ] = useState<string | ArrayBuffer | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const isUploading = useMemo(() => progress > 0, [progress]);

  const onSelectFile = useCallback((file: File) => {
    setFile(file);
    setOriginalFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onChangeShowModal(Modals.PREVIEW);
      onChangeFileUrl(reader.result);
      onChangeOriginalFileUrl(reader.result);
      const preloadImage = new Image();
      //@ts-ignore
      preloadImage.src = reader.result;
      preloadImage.onload = () => {
        onInitializeImageSizes(preloadImage);
      }
    };
    reader.onerror = error => message.error(error);
  }, [setFile, onChangeFileUrl]);

  const compressFile = useCallback((file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(function (blob: Blob | null) {
          const compressedFile = new File([blob as Blob], `${Date.now()}.jpeg`, { type: 'image/jpeg' });
          if (compressedFile.size > fileTypesSizeMapping[type]) {
            message.error(`Failed to compress image to ${fileTypesSizeMapping[type] / 1024}KB`);
            return;
          }
          onSelectFile(compressedFile);
        }, 'image/jpeg', 0.5);
      }
    };
    reader.onerror = error => message.error(error);
  }, [fileTypesSizeMapping, type]);

  const checkFileSize = useCallback((file: File) => {
    if (file.size > fileTypesSizeMapping[type]) {
      Modal.confirm({
        title: (
          <Fragment>
            <Typo type="p" bold className={styles.modalConfirmText}>{`Uploaded file size is higher than ${fileTypesSizeMapping[type] / 1024}KB`}</Typo>
            <Typo type="p" className={styles.modalConfirmText}>We can attempt to compress this image for you.</Typo>
            <Typo type="p" className={styles.modalConfirmText}>Compressing a image will result in image's transparency loss.</Typo>
          </Fragment>
        ),
        onOk: () => compressFile(file)
      });
      return;
    }

    onSelectFile(file);
  }, [fileTypesSizeMapping, type, compressFile]);

  const onChangeInput = useCallback(async (event: any) => {
    const file = event.target.files[0];
    event.target.value = null;
    checkFileSize(file);
  }, [onSelectFile, type, fileTypesSizeMapping, compressFile]);

  const handleFileDrop = useCallback(
    (monitor: DropTargetMonitor) => {
      if (monitor) {
        const file = monitor.getItem().files[0];
        const [tail] = file.name.split('.').reverse();
        if (accept.includes(tail.toLowerCase())) {
          checkFileSize(file);
        } else {
          message.error('File is unsupported');
        }
      }
    }, [checkFileSize]);

  const onCloseModal = useCallback(() => {
    if (isUploading) {
      Modal.confirm({
        title: 'Upload in progress. Do you want cancel this operation?',
        onOk: () => {
          cancelUpload();
          onChangeShowModal(null);
        }
      });
      return;
    }
    onChangeShowModal(null);
  }, [isUploading, cancelUpload]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFileDrop(monitor);
    },
    canDrop(item, monitor) {
      const files = monitor.getItem().files;
      return files?.length < 2;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (url) {
      onChange(url);
      if (onChangeUrl) onChangeUrl(url);
      onChangeSize(defaultSizes);
      onChangeShowModal(null);
      setFile(null);
      onChangeFileUrl(null);
    }
  }, [url, onChangeSize, onChangeShowModal, setFile, onChangeFileUrl]);

  useEffect(() => {
    if (tourId) {
      onOpenModal();
    }
  }, [tourId]);

  const resetImage = useCallback(() => {
    onChangeFileUrl(originalFileUrl);
    setFile(originalFile);
    const preloadImage = new Image();
    //@ts-ignore
    preloadImage.src = originalFileUrl;
    preloadImage.onload = () => {
      onInitializeImageSizes(preloadImage);
    }
  }, [originalFileUrl, imageRef.current, originalFile]);

  const onInitializeImageSizes = useCallback((imageElement: any) => {
    let width = imageElement.naturalWidth;
    let height = imageElement.naturalHeight;
    if (maxWidth && maxHeight) {
      width = imageElement.naturalWidth > maxWidth ? maxWidth : imageElement.naturalWidth;
      height = imageElement.naturalHeight > maxHeight ? maxHeight : imageElement.naturalHeight;
    }
    onChangeSize({
      height,
      width,
      xy: (imageElement.naturalHeight / imageElement.naturalWidth) || 1,
      yx: (imageElement.naturalWidth / imageElement.naturalHeight) || 1
    });
  }, []);

  const onChangeImageSize = useCallback((event: any, field: string) => {
    let value = event.target.value;
    const anotherField = field === 'width' ? 'height' : 'width';
    let currentMaxSize = value;
    if (maxWidth && maxHeight) {
      currentMaxSize = field === 'width' ? maxWidth : maxHeight;
      value = value > currentMaxSize ? currentMaxSize : value;
    }
    const ratio = field === 'width' ? sizes.xy : sizes.yx;
    const anotherValue = Math.ceil(value * ratio);
    onChangeSize({
      ...sizes,
      [field]: value,
      [anotherField]: anotherValue
    });
  }, [onChangeSize, sizes]);

  const onOpenModal = useCallback(() => {
    if (disabled) return;

    if (value) {
      const preloadImage = new Image();
      preloadImage.src = value;
      preloadImage.onload = () => {
        onInitializeImageSizes(preloadImage);
      }

      const request = new XMLHttpRequest();
      request.open('GET', value, true);
      request.responseType = 'blob';
      request.onload = () => {
        const existingFile = new File([request.response], `${new Date().getTime()}.png`, { type: 'image/png' });
        onSelectFile(existingFile);
      };
      request.send();
    }

    onChangeShowModal(value ? Modals.PREVIEW : Modals.UPLOAD);
  }, [disabled, onChangeShowModal, value, onSelectFile]);

  const onRemoveImage = useCallback(() => {
    if (disableRemove) return;

    if (!onRemoveKeepValue) onChange('');
    if (onRemove) onRemove();
  }, [onChange, onRemove, disableRemove, onRemoveKeepValue]);

  const onUpload = useCallback(() => {
    if (file) {
      upload(file, type, sizes.width, sizes.height);
    }
  }, [file, type, sizes]);

  const footer = useMemo(() => {
    const disableUpload = !file || isUploading;
    return (
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <Button className={styles.footerBtn} newForm type="ghost" onClick={onCloseModal} name={`${name}-component-cancel`}>Cancel</Button>
          <Button className={styles.footerBtn} newForm type="primary" onClick={onUpload} disabled={disableUpload} name={`${name}-component-upload`}>Upload</Button>
        </div>
      </div>
    )
  }, [onCloseModal, file, onUpload, isUploading]);

  const removableImage = useMemo(() => (
    <label className={cx(styles.uploader, styles.removableWrapper, className, { [styles.disabled]: disabled })} cypress-id={`${name}-component-wrapper`}>
      {value && value !== ''
        ? <Fragment>
            <img className={styles.img} src={value} alt={name} draggable={false}/>
            <div className={styles.buttonsImageWrapper}>
              <Button size="small" type="primary" onClick={onOpenModal} name={`${name}-component-update`}>Update</Button>
              <Button size="small" type="primary" disabled={disableRemove} danger onClick={onRemoveImage} name={`${name}-component-remove`}>Remove</Button>
            </div>
          </Fragment>
        : <PlusOutlined />
      }
    </label>
  ), [value, className, disabled, name, onOpenModal, onRemoveImage, disableRemove]);

  const plainImage = useMemo(() => (
    <label className={cx(styles.uploader, className, { [styles.empty]: !value, [styles.disabled]: disabled })} cypress-id={`${name}-component-wrapper`} onClick={onOpenModal} >
      {value
        ? <img className={styles.img} src={value} alt={name} draggable={false}/>
        : <div className={styles.uploaderButtonBody}><PlusOutlined className={styles.uploaderButtonIcon} /> <div>Upload</div></div>}
    </label>
  ), [className, value, disabled, name, onOpenModal]);

  const onSubmit = useCallback((url: string | ArrayBuffer | null, file: File, newSizes: any) => {
    if (file.size > fileTypesSizeMapping[type]) {
      message.error(`Generated file size is higher than ${fileTypesSizeMapping[type] / 1024}KB`);
      return;
    }
    onChangeFileUrl(url);
    setFile(file);
    if (newSizes) onChangeSize(newSizes);
    onChangeShowModal(Modals.PREVIEW);
  }, []);

  const onChangeModalBody = useCallback((type: Modals) => {
    if (type !== Modals.UPLOAD && !file) return;
    onChangeShowModal(type);
  }, [file, imageRef.current]);

  useEffect(() => {
    if (imageRef.current) {
      onInitializeImageSizes(imageRef.current);
    }
  }, [showModal, imageRef.current]);

  const onGoToCrop = useCallback(() => {
    onChangeModalBody(Modals.CROP);
  }, [onChangeModalBody])

  const tabs = useMemo(() => (
    <div className={styles.tabsWrapper}>
      <div className={cx(styles.tab, {[styles.active]: showModal === Modals.UPLOAD})} typeof="button" onClick={() => onChangeModalBody(Modals.UPLOAD)}>
        <span className={styles.tabIcon}>
          <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.22224 6.22266H1.00004C0.4476 6.22266 0 6.67023 0 7.2224V26.5563C0 27.109 0.447585 27.556 1.00004 27.556H23.0004L23.0003 27.5558C23.5527 27.5558 24 27.1088 24 26.5563H23.9994L23.9997 7.2224C23.9997 6.67026 23.5521 6.22266 22.9997 6.22266H18.6667V8.53863H21.6253L21.625 21.4509H16.3738L12.8702 15.9882L11.4879 18.1437L13.6088 21.4509H12.3262L10.8466 19.1441L7.86733 14.4987L3.40904 21.4509H2.30556V8.53863H6.22224V6.22266Z"/>
            <path d="M19.9471 12.9032C19.9471 14.0459 19.0205 14.9725 17.8778 14.9725C16.7352 14.9725 15.8086 14.0459 15.8086 12.9032C15.8086 11.7606 16.7352 10.834 17.8778 10.834C19.0205 10.834 19.9471 11.7612 19.9471 12.9032Z"/>
            <path d="M17.5074 6.09867C17.1461 6.4694 16.6033 6.4694 16.242 6.09867L13.3493 3.13023V12.4056C13.3493 12.8695 12.988 13.3334 12.4452 13.3334C11.9029 13.3334 11.541 12.8695 11.541 12.4056V3.13023L8.64832 6.09867C8.46737 6.28436 8.19625 6.37688 8.0153 6.37688C7.74419 6.37688 7.56324 6.28436 7.38228 6.09867C7.02101 5.72794 7.02101 5.17088 7.38228 4.80016L11.8114 0.255078C11.9016 0.162558 11.9924 0.0693899 12.0825 0.0693899C12.2635 -0.02313 12.5346 -0.02313 12.8057 0.0693899C12.8959 0.0693899 12.9867 0.161909 13.0768 0.255078L17.506 4.80016C17.8685 5.17089 17.8685 5.72731 17.5072 6.09867H17.5074Z"/>
          </svg>
        </span>
        <Typo type='p' className={styles.label}>Image upload</Typo>
      </div>
      <div className={cx(styles.tab, {[styles.active]: showModal === Modals.PREVIEW}, {[styles.disabled]: !file})} typeof="button" onClick={() => onChangeModalBody(Modals.PREVIEW)}>
        <span className={styles.tabIcon}>
          <svg width="24" height="22" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M23.0004 21.3334H1.00004C0.447585 21.3334 0 20.8864 0 20.3336V0.999746C0 0.447577 0.4476 0 1.00004 0H22.9997C23.5521 0 23.9997 0.447599 23.9997 0.999746L23.9994 20.3337H24C24 20.8861 23.5527 21.3331 23.0003 21.3331L23.0004 21.3334ZM21.6253 2.31598H2.30556V15.2283H3.40904L7.86733 8.27609L10.8466 12.9215L12.3262 15.2283H13.6088L11.4879 11.9211L12.8702 9.76556L16.3738 15.2283H21.625L21.6253 2.31598Z"/>
            <path d="M19.9471 6.68057C19.9471 7.82323 19.0205 8.74981 17.8778 8.74981C16.7352 8.74981 15.8086 7.82323 15.8086 6.68057C15.8086 5.53791 16.7352 4.61133 17.8778 4.61133C19.0205 4.61133 19.9471 5.5385 19.9471 6.68057Z"/>
          </svg>
        </span>
        <Typo type='p' className={styles.label}>Preview Image</Typo>
      </div>
      <div className={cx(styles.tab, {[styles.active]: showModal === Modals.CROP}, {[styles.disabled]: !file})} typeof="button" onClick={onGoToCrop}>
        <span className={styles.tabIcon}>
          <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0385 15.775H17.6987V4.66033L19.067 3.29199C19.4446 2.91682 19.4446 2.30844 19.067 1.93327C18.6919 1.5581 18.0835 1.5581 17.7083 1.93327L16.34 3.30161H5.22529V0.961758C5.22529 0.430504 4.79495 0 4.26353 0C3.73194 0 3.30177 0.430504 3.30177 0.961758L3.30161 3.30161H0.961758C0.430504 3.30161 0 3.73211 0 4.26354C0 4.79512 0.430504 5.22529 0.961758 5.22529H3.30161V16.7366C3.30161 16.8016 3.30877 16.8664 3.32082 16.9289C3.32798 16.9601 3.3353 16.9914 3.34491 17.0226C3.37127 17.1092 3.40985 17.1909 3.46274 17.2679C3.47951 17.2943 3.49627 17.3208 3.51808 17.3449C3.53729 17.369 3.55893 17.3929 3.58302 17.417C3.60711 17.4411 3.63104 17.4627 3.65513 17.4819C3.67922 17.5036 3.70575 17.5205 3.73212 17.5373C3.8091 17.5902 3.89081 17.6287 3.9774 17.6551C4.00865 17.6647 4.0399 17.6719 4.07115 17.6792C4.13365 17.6914 4.19859 17.6986 4.2637 17.6986H15.775V20.0382C15.775 20.5698 16.2055 21 16.7368 21C17.2681 21 17.6986 20.5695 17.6986 20.0382V17.6986H20.0382C20.5698 17.6986 21 17.2681 21 16.7368C21.0002 16.2052 20.5697 15.7749 20.0384 15.7749L20.0385 15.775ZM5.2255 5.22533H14.416L5.2255 14.4137V5.22533ZM6.58684 15.775L15.7753 6.58451V15.775H6.58684Z"/>
          </svg>
        </span>
        <Typo type='p' className={styles.label}>Crop Image</Typo>
      </div>
      <div className={cx(styles.tab, {[styles.active]: showModal === Modals.BACKGROUND}, {[styles.disabled]: !file})} typeof="button" onClick={() => onChangeModalBody(Modals.BACKGROUND)}>
         <span className={styles.tabIcon}>
           <svg width="24" height="22" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
             <path fillRule="evenodd" clipRule="evenodd" d="M23.0004 21.3334H1.00004C0.447585 21.3334 0 20.8864 0 20.3336V0.999746C0 0.447577 0.4476 0 1.00004 0H22.9997C23.5521 0 23.9997 0.447599 23.9997 0.999746L23.9994 20.3337H24C24 20.8861 23.5527 21.3331 23.0003 21.3331L23.0004 21.3334ZM21.6245 2.31641H2.30469V15.2287H3.40816L7.86646 8.27652L10.8457 12.9219L12.3253 15.2287H13.608L11.487 11.9215L12.8694 9.76599L16.3729 15.2287H21.6242L21.6245 2.31641Z"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M3.19922 5.69781H5.70604V3.19922L3.19922 3.19948V5.69781ZM8.21313 3.19922H10.7199V5.69755H8.21313V3.19922ZM13.227 3.19922H15.7339V4.71131C15.4695 4.99308 15.2608 5.32768 15.1253 5.69755H13.227V3.19922ZM15.3677 8.19615H13.227V8.49421L14.5521 10.6945H15.7339V8.67848C15.5958 8.53128 15.4729 8.36967 15.3677 8.19615ZM18.2407 9.56866V10.6945H20.7475L20.7478 8.19615H20.3307C19.8865 8.92873 19.1271 9.449 18.2407 9.56866ZM20.5729 5.69755H20.7475L20.7478 3.19922H18.2407V3.82115C19.3189 3.96679 20.2093 4.7055 20.5729 5.69755ZM16.0567 13.1931L15.734 12.6571V10.6947H18.2408V13.1931H20.7478L20.7475 15.6914H18.2407V13.1931H16.0567ZM5.70604 9.9839C5.55204 10.2231 5.39809 10.4608 5.24579 10.6947L3.19922 10.6945V8.19615H5.70604V9.9839ZM8.21299 6.93371L7.88533 6.43359C7.58625 6.96326 7.22577 7.56527 6.83596 8.19615H5.70617V5.69781H8.21299V6.93371ZM10.6769 10.6945L9.04011 8.19615H10.7199V10.6945H10.6769ZM10.7567 10.6947L10.7201 10.7361V10.6947H10.7567ZM13.0475 8.19615L13.016 8.14383L12.9697 8.19615H10.7201V5.69781H13.2269V8.19615H13.0475Z"/>
             <path d="M19.9471 6.68057C19.9471 7.82323 19.0205 8.74981 17.8778 8.74981C16.7352 8.74981 15.8086 7.82323 15.8086 6.68057C15.8086 5.53791 16.7352 4.61133 17.8778 4.61133C19.0205 4.61133 19.9471 5.5385 19.9471 6.68057Z"/>
           </svg>
         </span>
        <Typo type='p' className={styles.label}>Remove Background</Typo>
      </div>
    </div>
  ), [showModal, onGoToCrop, onChangeModalBody, file]);

  const sizesWrapper = useMemo(() => (
    <div className={styles.imageSizesWrapper}>
      <Input label="Width" className={styles.sizesInput} disabled={showModal === Modals.CROP} name={`${name}-component-width`} type="number" value={sizes.width} onChange={(event) => onChangeImageSize(event, 'width')}/>
      <span className={styles.chainIcon}>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.47186 7.42224L2.31457 6.57953C2.70707 6.18703 3.34343 6.18703 3.73593 6.57953C4.10039 6.94399 4.12643 7.51872 3.81403 7.91322L3.73593 8.00089L2.89322 8.8436C1.71573 10.0211 1.71573 11.9302 2.89322 13.1077C4.02866 14.2431 5.84437 14.2837 7.0284 13.2293L7.15729 13.1077L8 12.265C8.3925 11.8725 9.02886 11.8725 9.42136 12.265C9.78582 12.6294 9.81185 13.2041 9.49946 13.5986L9.42136 13.6863L8.57864 14.529C6.61616 16.4915 3.43435 16.4915 1.47186 14.529C-0.438977 12.6182 -0.489262 9.55134 1.32101 7.57966L1.47186 7.42224ZM7.42224 1.47186C9.38472 -0.490621 12.5665 -0.490621 14.529 1.47186C16.4915 3.43435 16.4915 6.61616 14.529 8.57864L13.6863 9.42136C13.2938 9.81385 12.6575 9.81385 12.265 9.42136C11.8725 9.02886 11.8725 8.3925 12.265 8L13.1077 7.15729C14.2852 5.9798 14.2852 4.07071 13.1077 2.89322C11.9302 1.71573 10.0211 1.71573 8.8436 2.89322L8.00089 3.73593C7.60839 4.12843 6.97203 4.12843 6.57953 3.73593C6.18703 3.34343 6.18703 2.70707 6.57953 2.31457L7.42224 1.47186ZM8.42172 6.15765C8.81422 5.76516 9.45058 5.76516 9.84308 6.15765C10.2356 6.55015 10.2356 7.18651 9.84308 7.57901L7.57901 9.84308C7.18651 10.2356 6.55015 10.2356 6.15765 9.84308C5.76516 9.45058 5.76516 8.81422 6.15765 8.42172L8.42172 6.15765Z"/>
        </svg>
      </span>
      <Input label="Height" className={styles.sizesInput} disabled={showModal === Modals.CROP} name={`${name}-component-height`} type="number" value={sizes.height} onChange={(event) => onChangeImageSize(event, 'height')}/>
    </div>
  ), [sizes, showModal, onChangeImageSize]);


  const previewBody = useMemo(() => (
    <div className={styles.sidesWrapper}>
      <div className={styles.leftSide}>
        <div className={styles.imageWrapper}>
          <img
            className={styles.imagePreview}
            ref={imageRef}
            draggable={false}
            //@ts-ignore
            src={fileUrl} alt="preview"
          />
        </div>
      </div>
      <div className={styles.rightSide}>
        <div>
          { sizesWrapper }
          <div className={styles.btnsWrapper}>
            <Button className={styles.btn} onClick={resetImage}>Reset all changes</Button>
          </div>
        </div>
      </div>
    </div>
  ), [imageRef, fileUrl, sizesWrapper, resetImage]);

  const uploaderBody = useMemo(() => (
    <div className={cx(styles.dndArea, { [styles.canDrop]: canDrop || isOver })} ref={drop}>
      <div className={styles.iconWrapper}>
        <FontAwesomeIcon icon={faCloudUploadAlt}/>
      </div>
      <Typo type="p">Drag file to upload</Typo>
      <Typo type="p">or</Typo>
      <Button type="primary" className={styles.inputFileBtn}>
        <span>Browse files</span>
        <input className={styles.inputFile} type="file" accept={accept} onChange={onChangeInput} cypress-id={`${name}-component-browsefile`}/>
      </Button>
    </div>
  ), [canDrop, isOver, drop, accept]);

  const bodyTypes = useMemo(() => ({
    [Modals.UPLOAD]: uploaderBody,
    [Modals.PREVIEW]: previewBody,
    [Modals.BACKGROUND]: <BackgroundEditor maxSize={fileTypesSizeMapping[type]} sizes={sizes}
                                           fileUrl={fileUrl} originalFile={originalFileUrl} onSubmit={onSubmit}/>,
    [Modals.CROP]: <CropBox fileUrl={fileUrl} type={file?.type} onSubmit={onSubmit} onChangeSizes={onChangeSize}
                            sizesWrapper={sizesWrapper} onCloseModal={onCloseModal}/>,
  }), [fileUrl, onSubmit, onChangeSize, onCloseModal, sizesWrapper, previewBody, uploaderBody, sizes, file]);

  const showFooter = useMemo(() => (showModal === Modals.PREVIEW || showModal === Modals.BACKGROUND), [showModal]);

  const body = useMemo(() => (
      <Fragment>
        { tabs }
        <div data-tour={tourId}>
          { showModal !== null ? bodyTypes[showModal] : null }
          { showFooter ? footer : null }
        </div>
      </Fragment>
  ), [showModal, bodyTypes, tabs, showFooter, tourId]);

  return (
    <Fragment>
      {!tourId ? (removable && value ? removableImage : plainImage) : null}
      <Modal
        visible={showModal !== null}
        onCancel={onCloseModal}
        className={styles.modal}
        footer={[]}
      >
        <Fragment>
          <Typo type="p" className={styles.modalTitle}>Image Upload</Typo>
          <div className={styles.modalWrapper}>
            { isUploading
              ?
              <div className={styles.busyWrapper}>
                <div className={styles.addyThink}/>
                <Typo className={styles.hint} type="p">Upload in progress. Refresh in a few moments to see your new image...</Typo>
              </div>
              : body
            }
          </div>
        </Fragment>
      </Modal>
    </Fragment>
  );
};

export default Uploader;
