import { createAction } from '../../utils/actions';

export const updateAdactTour = createAction('ui/update_adact_tour');
export const setDisableBodyScroll = createAction('ui/set_disable_body_scroll');
export const setFonts = createAction('ui/set_fonts');
export const increaseSpinner = createAction('ui/increase_spinner');
export const decreaseSpinner = createAction('ui/decrease_spinner');
export const setBrandsFonts = createAction('ui/set_brands_fonts');
export const addBrandFont = createAction('ui/add_brand_font');
export const removeBrandFont = createAction('ui/remove_brand_font');
export const showRestrictionModal = createAction('ui/show_restriction_modal');
export const hideRestrictionModal = createAction('ui/hide_restriction_modal');
export const setRestrictionModalText = createAction('ui/set_restriction_modal_type');
export const setDebugState = createAction('ui/set_debug_state');
export const showSidebarAction = createAction('ui/show_sidebar');
export const hideSidebarAction = createAction('ui/hide_sidebar');
export const maximizeSidebarAction = createAction('ui/maximize_sidebar');
export const minimizeSidebarAction = createAction('ui/minimize_sidebar');
export const setDisabledSidebar = createAction('ui/set_disabled_sidebar');
export const setFullPreviewTrue = createAction('ui/set_full_preview_true');
export const setFullPreviewFalse = createAction('ui/set_full_preview_false');
export const setUIField = createAction('ui/set_field');
