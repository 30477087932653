import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from '../mediaFiles.module.css';
import Typo from '../../../../typo';
import cx from 'classnames';
import { SelectedFile } from '../index';

type Props = {
  selectedFiles: SelectedFile[];
}

type MediaFileSize = {
  width: number;
  height: number;
}

const MediaFilesPreview:FunctionComponent<Props> = ({ selectedFiles }) => {
  const sizesMapping = useRef<Record<number, MediaFileSize>>({});
  const [ imageLoadedAt, setImageLoadedAt ] = useState<number>(new Date().getTime());

  useLayoutEffect(() => {
    const promises = selectedFiles.filter((item) => !sizesMapping.current[item.id]).map((item) => {
      return new Promise<void>((resolve) => {
        const image = new Image();
        image.src = item.file_key;
        image.onload = () => {
          sizesMapping.current[item.id] = {
            width: image.width,
            height: image.height,
          };
          resolve();
        };
      });
    });

    Promise.all(promises).then(() => {
      setImageLoadedAt(new Date().getTime());
    });
  }, [selectedFiles]);


  return (
    <div className={styles.emptyPreview}>
      {
        Boolean(selectedFiles.length) ? (
          <>
            <div className={styles.previewList}>
              {
                selectedFiles.map((item: SelectedFile, idx: number) => (
                  <div
                    key={item.id}
                    className={cx(styles.previewImageWrapper, {[styles.multipreview]: selectedFiles.length !== 1})}
                    style={{ top: idx * 20, left: idx * 30 }}
                  >
                    <div className={styles.previewImageContainer}>
                      <img className={styles.previewImage} src={item.file_key} alt={item.file_name}/>
                    </div>
                    <Typo
                      type="p"
                      key={imageLoadedAt}
                      style={{ visibility: sizesMapping.current[item.id] ? 'visible' : 'hidden' }}
                    >
                      {sizesMapping.current[item.id]?.width}x{sizesMapping.current[item.id]?.height}px
                    </Typo>
                  </div>
                ))
              }
            </div>
          </>
        ) : (
          <Typo type="p">Preview Image</Typo>
        )
      }
    </div>
  );
};

export default MediaFilesPreview;
