import React, { FunctionComponent } from 'react';
import AntRadio from 'antd/lib/radio';
import Typo from '../../typo';
import cx from 'classnames';
import styles from './radio.module.css';
import Field from '../field';

interface Props {
  className?: string;
  label?: string;
  name?: string;
  refProp?: any;
  checked: boolean;
  value?: string | number;
  onRadioChange: () => void;
  disabled?: boolean;
  errors?: string[];
  cypressId?: string;
}

const Radio:FunctionComponent<Props> = (props) => {
  const { className, label, refProp, errors, name, checked, onRadioChange, cypressId, ...other } = props;
  return (
    <Field className={cx(className, styles.wrapper)} errors={errors}>
      <label className={styles.radio}>
        <AntRadio {...other} name={name} checked={checked} onChange={onRadioChange} ref={refProp} data-cypress-id={`${cypressId || name}-component`}/>
        {label ? <Typo type="p" className={styles.label}>{label}</Typo> : null}
      </label>
    </Field>
  );
};

export default Radio;
