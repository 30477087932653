import React, { FunctionComponent, ReactElement } from 'react';
import AntSelect, { SelectProps } from 'antd/lib/select';
import styles from './select.module.css';
import Field from '../field';
import cx from 'classnames';

type Option = {
  label: string;
  value: string | number;
}

interface Props extends SelectProps<Props> {
  className?: string;
  label?: string;
  name: string;
  onBlurCallback?: () => void;
  onChange?: (value: any) => void;
  required?: boolean;
  options: Option[];
  value?: any;
  icon?: string | ReactElement;
  refProp?: any;
  newForm?: boolean;
  errors?: string[]
}

const Select:FunctionComponent<Props> = (props) => {
  const { className, label, refProp, icon, errors, onBlur, newForm, onBlurCallback, onChange, name, options, ...other } = props;
  return (
    <Field className={className} label={label} errors={errors}>
      <div className={styles.wrapper}>
        {icon ? <span className={cx(styles.icon, {[styles.newIcon]: newForm})}>{icon}</span> : null}
        <AntSelect
          className={cx(styles.select, {[styles.withIcon]: icon, [styles.newForm]: newForm})}
          {...other}
          onBlur={onBlurCallback ? onBlurCallback : onBlur}
          //@ts-ignore
          name={name}
          ref={refProp}
          cypress-id={`${name}-component-select`}
          onChange={onChange}
        >
          {options.map((item: Option, idx: number) => (
            <AntSelect.Option key={idx} value={item.value} title={item.label} cypess-id={`${name}-component-option-${idx}`}>{item.label}</AntSelect.Option>
          ))}
        </AntSelect>
      </div>
    </Field>
  );
};

export default Select;
