import { GameSettingTypesEnum } from '../../enums';

export const GAME_CONTENT_TYPES: Record<GameSettingTypesEnum, any> = {
  [GameSettingTypesEnum.TRIVIA]: 'text_game',
  [GameSettingTypesEnum.MEMORY]: 'text_game',
  [GameSettingTypesEnum.PERSONALITY_TEST]: 'text_game',
  [GameSettingTypesEnum.DROP_GAME]: 'phaser',
  [GameSettingTypesEnum.WHEEL_OF_FORTUNE]: 'phaser',
  [GameSettingTypesEnum.VERTICAL_SCROLLER]: 'phaser',
  [GameSettingTypesEnum.SMASH_GAME]: 'phaser',
  [GameSettingTypesEnum.MATCH3]: 'phaser',
  [GameSettingTypesEnum.TOWER_STACKING]: 'phaser',
  [GameSettingTypesEnum.SCRATCHCARD]: 'phaser',
  [GameSettingTypesEnum.WHACK_A_MOLE]: 'phaser',
  [GameSettingTypesEnum.HOOPS]: 'phaser',
  [GameSettingTypesEnum.ENDLESS_RUNNER]: 'phaser',
  [GameSettingTypesEnum.PUZZLE]: 'phaser',
  [GameSettingTypesEnum.SEQUENCE]: 'phaser',
  [GameSettingTypesEnum.THROWING]: 'phaser',
  [GameSettingTypesEnum.TAPPING]: 'phaser',
  [GameSettingTypesEnum.JEOPARDY]: 'wide_text_maximize',
  [GameSettingTypesEnum.HOTSPOT]: 'video',
  [GameSettingTypesEnum.VIDEO_TRIVIA]: 'video',
  [GameSettingTypesEnum.STACKING]: 'phaser',
  [GameSettingTypesEnum.MULTIPLE_VIDEO_TRIVIA]: 'video',
  [GameSettingTypesEnum.FLAPPY_BIRD]: 'phaser',
  [GameSettingTypesEnum.PICTURE_REVEAL]: 'text_game',
  [GameSettingTypesEnum.OPEN_THE_BOX]: 'phaser_fullscreen',
  [GameSettingTypesEnum.MULTIPLAYER_TRIVIA]: 'text_game',
  [GameSettingTypesEnum.PLINKO]: 'phaser',
  [GameSettingTypesEnum.SWIPE_POLL]: 'text_game',
  [GameSettingTypesEnum.BATTLE]: 'text_game',
  [GameSettingTypesEnum.SURVEY]: 'text_game',
  [GameSettingTypesEnum.HIDDEN_OBJECT]: 'wide_text_game',
  [GameSettingTypesEnum.COMMUNITY_CONTEST]: 'advent',
  [GameSettingTypesEnum.WORDLE]: 'text_game',
  [GameSettingTypesEnum.MULTI_PATH_QUIZ]: 'text_game',
  [GameSettingTypesEnum.BUBBLE_SHOOTER]: 'phaser',
  [GameSettingTypesEnum.ADVENT_CALENDAR]: 'advent',
  [GameSettingTypesEnum.STICK_NINJA]: 'phaser_fullscreen',
  [GameSettingTypesEnum.WORD_SEARCH]: 'text_game',
  [GameSettingTypesEnum.PREDICTION]: 'text_game',
  [GameSettingTypesEnum.TRIVIA_BUILDER]: 'text_game',
  [GameSettingTypesEnum.TIC_TAC_TOE]: 'wide_text_maximize',
  [GameSettingTypesEnum.SLIDING_PUZZLE]: 'wide_text_maximize',
}
