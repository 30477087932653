import { RafflingVariant } from '../../enums';

export const raffleWinningCriteriaFieldMapping: Partial<Record<RafflingVariant, any>> = {
  [RafflingVariant.LEADERBOARD]: {
    from: 'leaderboardFrom',
    to: 'leaderboardTo',
  },
  [RafflingVariant.REFERRER_COUNT]: {
    from: 'referrersFrom',
    to: 'referrersTo',
  },
  // [RafflingVariant.SCORE]: {
  //   from: 'pointsFrom',
  //   to: 'pointsTo',
  // },
}
