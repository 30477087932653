import { useCallback, useEffect, useState } from 'react';
import useCampaign from '../../../domain/useCampaign';
import { cloneDeep, isEqual, set } from 'lodash';
import { Campaign, FlattenedPath } from '../../type';
import { useConfigHelper } from '../useConfigHelper';
import urlToHttpsUrl from '../../urlToHttps';

type Props<T> = {
  linkFields?: FlattenedPath<T>[];
  adapter?: (data: T) => T;
  campaignField?: keyof Campaign;
}

export const useCampaignConfigField = <T, >(props: Props<T>) => {
  const { adapter, campaignField = 'game_config', linkFields } = props;
  const { update, campaign, gameSettings, campaignBackground, campaignContent, campaignGameArea, leaderboard, side_bar_config, custom_errors, privacy_policy, tac, embed_options_config } = useCampaign();
  const { setFieldValues } = useConfigHelper();

  const fieldMapping: Partial<Record<keyof Campaign, any>> = {
    game_config: gameSettings,
    content_config: campaignContent,
    background_config: campaignBackground,
    game_area_config: campaignGameArea,
    leaderboard_config: leaderboard,
    side_bar_config: side_bar_config,
    errors_config: custom_errors,
    privacy_policy_config: privacy_policy,
    terms_and_conditions_config: tac,
    embed_options_config: embed_options_config,
  }

  const [ configs, setConfigs ] = useState<T>(fieldMapping[campaignField]);

  const updateConfigField = useCallback((data: Record<string, any>) => {
    const newItem: T = cloneDeep(configs);
    Object.entries(data).forEach(([key, value]: [string, any]) => {
      set(newItem as Record<string, any>, key, value);
    });
    setConfigs(newItem);
  }, [configs]);

  const updateLinkField = (value: string) => urlToHttpsUrl(value);

  const updateConfigs = () => {
    let newConfig = cloneDeep({
      ...fieldMapping[campaignField],
      ...configs,
    });

    if (adapter) {
      newConfig = adapter(newConfig);
    }

    if (linkFields) {
      newConfig = setFieldValues(newConfig, linkFields as string[], updateLinkField);
    }

    if (!isEqual(fieldMapping[campaignField], newConfig)) {
      update(campaign.id, {
        [campaignField]: JSON.stringify(newConfig)
      });
    }
  };

  useEffect(() => {
    updateConfigs();
  },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(configs)]);

  return {
    campaign,
    configs,
    updateConfigField,
  }
}
