import React, { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import styles from '../upload.module.css';
import { message } from 'antd';
import { times } from 'lodash';
import { Button } from '../../index';
import CropPoint from './croppoint';

type Props = {
  type?: string;
  fileUrl: string | ArrayBuffer | null;
  onSubmit: (values: string | ArrayBuffer | null, file: File, sizes: any) => void;
  onChangeSizes: (sizes: any) => void;
  onCloseModal: () => void;
  sizesWrapper: any;
}

let dragStartWrapper: any = null;

const CropBox:FunctionComponent<Props> = ({ fileUrl, onSubmit, onCloseModal, onChangeSizes, sizesWrapper, type }) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const cropWrapperRef = useRef<HTMLDivElement | null>(null);
  const cropAreaRef = useRef<HTMLDivElement | null>(null);
  const [dragging, onDragging] = useState<boolean>(false);
  const [ cropSizes, onChangeAreaPosition ] = useState<any>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const image = imageRef.current;
    if (image) {
      onChangeAreaPosition({
        top: image.offsetHeight * 0.2,
        left: image.offsetWidth * 0.2,
        width: image.offsetWidth * 0.6,
        height: image.offsetHeight * 0.6,
      });
      onSizesChange(image.offsetWidth * 0.6, image.offsetHeight * 0.6);
    }
  }, [imageRef.current]);

  const startDrag = (e: any) => {
    e.stopPropagation();
    onDragging(true);
    dragStartWrapper = ({
      x: e.clientX,
      y: e.clientY,
    })
  }

  const endDrag = (e: any) => {
    e.stopPropagation();
    onDragging(false);
    dragStartWrapper = null;
  }

  useEffect(() => {
    const cropArea = cropAreaRef.current;
    const wrapper = cropWrapperRef.current;
    const containerRef = container.current;
    if (!cropArea || !wrapper || !containerRef) return;
    cropArea.addEventListener('pointerdown', startDrag);
    wrapper.addEventListener('pointerup', endDrag);
    wrapper.addEventListener('pointermove', dragArea);
    containerRef.addEventListener('pointerout', endDrag);
    return () => {
      cropArea.removeEventListener('pointerdown', startDrag);
      wrapper.removeEventListener('pointerup', endDrag);
      wrapper.removeEventListener('pointermove', dragArea);
      containerRef.removeEventListener('pointerout', endDrag);
    };
  }, [cropAreaRef.current, cropWrapperRef.current, cropSizes, dragging, container.current]);

  const dragArea = (event: any) => {
    if (!dragging || !dragStartWrapper || !cropAreaRef?.current || !cropWrapperRef.current) return;
    event.stopPropagation();
    const newX = dragStartWrapper.x - event.clientX;
    const newY = dragStartWrapper.y - event.clientY;
    dragStartWrapper = ({ x: event.clientX, y: event.clientY });
    let left = cropAreaRef.current.offsetLeft - newX;
    let top = cropAreaRef.current.offsetTop - newY;

    if (left < 0 ) {
      left = 0;
    }

    if (top < 0 ) {
      top = 0;
    }

    if (left + cropSizes.width >= cropWrapperRef.current.offsetWidth) {
      left = cropWrapperRef.current.offsetWidth - cropSizes.width;
    }

    if (top + cropSizes.height >= cropWrapperRef.current.offsetHeight) {
      top = cropWrapperRef.current.offsetHeight - cropSizes.height;
    }

    if (
      left < 0 ||
      top < 0 ||
      left + cropSizes.width > cropWrapperRef.current.offsetWidth ||
      top + cropSizes.height > cropWrapperRef.current.offsetHeight
    ) {
      return;
    }

    onChangeAreaPosition({ ...cropSizes, left, top });
  }

  const onSizesChange = useCallback((width: number, height: number) => {
    const image = imageRef.current;

    if (image) {
      const normalizedWidth = Math.round(image.naturalWidth / image.offsetWidth * width);
      const normalizedHeight = Math.round(image.naturalHeight / image.offsetHeight * height);

      const newImageSizes = {
        width: normalizedWidth,
        height: normalizedHeight,
        xy: normalizedHeight / normalizedWidth,
        yx: normalizedWidth / normalizedHeight
      };
      onChangeSizes(newImageSizes);
    }

  }, [imageRef.current]);

  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const image = imageRef.current;
    const canvas = previewCanvasRef.current;
    if (!image || !canvas || !cropSizes) return;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropSizes.width * scaleX;
    canvas.height = cropSizes.height * scaleY;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.drawImage(
      image,
      cropSizes.left * scaleX,
      cropSizes.top * scaleY,
      cropSizes.width * scaleX,
      cropSizes.height * scaleY,
      0,
      0,
      cropSizes.width * scaleX,
      cropSizes.height * scaleY
    );

  }, [cropSizes]);

  const submit = useCallback(() => {
    const canvas = previewCanvasRef.current;

    if (!canvas) return;

    const newImageSizes = {
      width: canvas.width,
      height: canvas.height,
      xy: canvas.height / canvas.width,
      yx: canvas.width / canvas.height
    };

    canvas.toBlob((blob ) => {
      const file = new File([blob as BlobPart], `${Date.now()}`, { type: type || 'image/png' });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => onSubmit(reader.result, file, newImageSizes);
      reader.onerror = error => message.error(error);
    }, (type || 'image/png'));
  }, [previewCanvasRef.current, type]);

  return (
    <Fragment>
      <div className={styles.sidesWrapper}>
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            width: Math.round(cropSizes?.width ?? 0),
            height: Math.round(cropSizes?.height ?? 0)
          }}
        />
        <div className={styles.leftSide} ref={container}>
          <div className={styles.cropWrapper} ref={cropWrapperRef}>
            <img
              ref={imageRef}
              //@ts-ignore
              src={fileUrl}
              className={styles.imagePreview}
              alt="crop"
            />
            <div className={styles.cropBox} ref={cropAreaRef} style={cropSizes}>
              {
                times(8, (i: number) => <CropPoint key={i} position={i} cropSizes={cropSizes} cropWrapperRef={cropWrapperRef} containerRef={container} onChangeCropPoint={(value) => onChangeAreaPosition(value)} onDragEnd={onSizesChange}/>)
              }
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div>
            { sizesWrapper }
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <Button className={styles.footerBtn} newForm type="ghost" onClick={onCloseModal}>Cancel</Button>
          <Button className={styles.footerBtn} newForm type="primary" onClick={submit}>Apply</Button>
        </div>
      </div>
    </Fragment>
  );
};

export default CropBox;
