import React, { FunctionComponent, ReactText, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import WhiteCheckbox from '../whiteCheckbox';
import WhiteField from '../field/white';
import arrow from './arrow.svg';
import styles from './input.module.css';

type Props = {
  name: string;
  value: any[];
  onChange: (value: any[]) => void;
  label?: string;
  placeholder: string;
  className?: string;
  errors?: string[];
  required?: boolean;
  disabled?: boolean;
  options: Option[];
  labelWidth?: number;
  inputClassName?: string;
  tiny?: boolean;
  inline?: boolean;
  showAll?: boolean;
  showTagNames?: boolean;
}

type Option = {
  label: string | number | ReactText;
  value: string | number | undefined;
  disabled?: boolean;
}

const WhiteMultiSelect:FunctionComponent<Props> = (props) => {
  const {
    name, value, onChange,
    label, required, disabled, options,
    className, inputClassName, labelWidth,
    placeholder, errors, tiny, inline, showAll = true,
    showTagNames,
  } = props;
  const [ show, onChangeShow ] = useState<boolean>(false);
  const uniqName = useMemo(() => (
    `adact-white-multi-select-${name.replaceAll(/[^a-zA-Z0-9]/g, '')}`
  ), [name]);

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [show]);

  const handleClick = useCallback((event) => {
    const inSelect = event.target?.closest(`.${uniqName}`);
    if (show && !inSelect) {
      onChangeShow(false);
    }
    return;
  }, [show, uniqName]);

  const handleShow = useCallback(() => {
    if (disabled) return;
    onChangeShow(!show)
  }, [show, disabled]);

  const onClick = useCallback((event, itemValue: any) => {
    event.preventDefault();
    const newValue = value.includes(itemValue) ? value.filter((item: any) => item !== itemValue) : [...value, itemValue];
    onChange(newValue);
  }, [onChange, value]);

  const allOptionsValue = useMemo(() => options.filter((item: Option) => !item.disabled).map((item: Option) => item.value), [options]);
  const allSelectedValueLabels = useMemo(() => options.filter((item: Option) => value.includes(item.value)).map((item: Option) => item.label), [options, value]);

  const handleChangeAll = useCallback((event) => {
    event.preventDefault();
    const newValue = value.length === allOptionsValue.length ? [] : allOptionsValue;
    onChange(newValue);
  }, [allOptionsValue, value, onChange]);

  return (
    <WhiteField width={labelWidth} className={cx(className, styles.wrapper, {[styles.inlineWrapper]: inline, [styles.withErrors]: errors?.length})} name={name} label={label} inline={inline}>
      <div className={cx(styles.select, inputClassName, uniqName, {[styles.active]: show})}>
        <input type="hidden" name="name" value={value} required={required} disabled={disabled}/>
        {errors ? <div className={styles.errors}>{errors.join('; ')}</div> : null}
        <div onClick={handleShow} cypress-id={`${name}-multiselect-toggler`} className={cx(styles.toggler, 'adact-select-toggler', { [styles.disabled]: disabled, [styles.hasError]: errors, [styles.tiny]: tiny || inline})}>
          <span className={styles.placeholder}>{showTagNames ? allSelectedValueLabels.join(', ') : `${placeholder}${value.length > 0 ? `(${value.length})` : ''}`} </span>
          <img src={arrow} alt="arrow" draggable={false} className={styles.arrow}/>
        </div>
        {
          show
          ?
            <div className={styles.listWrapper}>
              <ul>
                {allOptionsValue.length > 0 && showAll
                  ?
                    <li role="button" onClick={(event) => handleChangeAll(event)}>
                      <WhiteCheckbox cypress-id={`${name}-multiselect-all`} name={`${name}_list_item_all`} checked={value.length === options.length} label="All"/>
                    </li>
                  : null
                }
                {options.map((item: Option, idx: number) => (
                  <li role="button" key={idx} onClick={(event) => onClick(event, item.value)}>
                    <WhiteCheckbox disabled={item.disabled} cypress-id={`${name}-multiselect-list-item-${idx}`} name={`${name}_list_item_${idx}`} checked={value.includes(item.value)} label={item.label}/>
                  </li>
                ))}
              </ul>
            </div>
          : null
        }
      </div>
    </WhiteField>
  );
}

export default WhiteMultiSelect;
