import React, { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { debounce } from 'lodash';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Typo from '../../typo';
import Field from '../field';
import styles from './autocomplete.module.css';

type Option = {
  value: string | number | any;
  label: any | ReactElement;
  activeLabel?: any | ReactElement;
}

type Props = {
  className?: string;
  label?: string;
  name: string;
  value: string | number | any;
  placeholder?: string;
  disabled?: boolean;
  onLoad?: (search: string) => void;
  errors?: string[];
  onChange: (value: string) => void;
  onSelect?: (option: Option) => void;
  onClear?: () => void;
  options: Option[];
  excluded?: any[];
  newForm?: boolean;
}

const Autocomplete:FunctionComponent<Props> = (props) => {
  const { className, label, errors, name, options, onSelect, excluded = [], onChange, value, onClear, placeholder, disabled, onLoad, newForm } = props;
  const input = useRef<HTMLInputElement>(null);
  const [ search, onChangeSearch ] = useState<string>('');
  const [ currentValue, onChangeCurrentValue ] = useState<Option | undefined>(options.find((item: Option) => item.value === value));
  const [ hide, onChangeHide ] = useState<boolean>(true);

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);
    return () => {
      document.removeEventListener('click', onClickOutside, true);
    }
  }, [hide]);

  useEffect(() => {
    const newValue = options.find((item: Option) => item.value === value);
    if (newValue && newValue !== currentValue) {
      onChangeCurrentValue(newValue);
      return;
    }
    if (!newValue) onChangeCurrentValue(undefined);
  }, [value, options]);

  const onClickOutside = useCallback((event: any) => {
    const inDropdown = event.target.closest(`.${name}`);
    if (!hide && !inDropdown) {
      onChangeSearch('');
      onChangeHide(true);
    }
  }, [hide, onChangeSearch, name, onChangeHide]);

  const onClickLabel = useCallback(() => {
    if (disabled) return;
    onChangeHide(false);
    if (onClear) onClear();
    const timer = setTimeout(() => {
      if (input.current) input.current.focus();
      clearTimeout(timer);
    }, 100);
  }, [disabled, input.current, onChangeHide]);

  const makeDebouncedRequest = useCallback(debounce((search: string) => {
    if (onLoad) {
      onLoad(search);
    }
  }, 500 , { trailing: true }), [onLoad]);

  useEffect(() => {
    if (search !== '') makeDebouncedRequest(search);
  }, [search]);

  const onChangeValue = useCallback((item: Option) => {
    onChange(item.value);
    const newCurrentValue = options.find((item: Option) => (item.value === value));
    if (newCurrentValue) onChangeCurrentValue(newCurrentValue);
    onChangeSearch('');
    onChangeHide(true);
    if (onSelect) onSelect(item);
  }, [onChange, onChangeSearch, onSelect, options, onClear, onChangeHide]);

  const clear = useCallback(() => {
    onChange('');
    onChangeSearch('');
    onChangeHide(true);
  }, [onChange, onChangeSearch, onClear, onChangeHide]);

  const filteredOptions = useMemo(() => {
    if (onLoad) {
      return options.filter((option: Option) => !excluded.includes(option.value));
    }
    return options.filter((option: Option) => option.label.toLowerCase().includes(search.toLowerCase())).filter((option: Option) => !excluded.includes(option.value));
  }, [options, onLoad, excluded, search]);

  return (
    <Field className={cx(className, name)} label={label} errors={errors} disabled={disabled} newForm={newForm}>
      <div className={cx(styles.wrapper, {[styles.newForm]: newForm})}>
        {currentValue && onClear ? <FontAwesomeIcon className={styles.clear} onClick={clear} icon={faTimesCircle} cypress-id={`${name}-component-clear`}/> : null}
        {hide
          ?
            <div onClick={onClickLabel} className={cx(styles.value, {[styles.disabled]: disabled}, {[styles.newFormSelect]: newForm})} cypress-id={`${name}-component-select`}>
              <span className={styles.valueText}>{currentValue?.label.replace(/_custom/g, '') || placeholder}</span>
            </div>
          :
            <div className={styles.dropdown}>
              <div className={styles.inputWrapper}>
                <FontAwesomeIcon className={styles.inputIcon} icon={faSearch}/>
                <input type="text" value={search} ref={input} className={cx({[styles.newForm]: newForm})} onChange={(event) => onChangeSearch(event.target.value)} cypress-id={`${name}-component-input`}/>
              </div>
              <div className={styles.listWrapper}>
                <ul className={styles.list}>
                  {filteredOptions.map((item: Option, index: number) => (
                    <li role="button" className={styles.section} key={index} onClick={() => onChangeValue(item)} cypress-id={`${name}-component-option-${index}`}>
                      {item.activeLabel ? item.activeLabel : null}
                      <Typo type="p" className={item.activeLabel ? styles.withIcon : ''}>{item.label}</Typo>
                    </li>
                  ))}
                  {filteredOptions.length < 1 ? <li className={styles.listPlaceholder}><Typo type="p">No results...</Typo></li> : null}
                </ul>
              </div>
            </div>
        }
      </div>
    </Field>
  );
};

export default Autocomplete;
