import React, { FunctionComponent, ReactElement, useCallback } from 'react';
import cx from 'classnames';
import styles from './input.module.css';
import { WhiteField } from '../index';
import InputType from '../../../utils/type/input.type';

type Option = {
  label: string | ReactElement;
  activeLabel?: string | ReactElement;
  value: string | number;
}

interface Props extends InputType {
  className?: string;
  label?: string;
  name: string;
  options: Option[];
  refProp?: any;
  onChange?: (value: any) => void;
  value: any;
  defaultOption?: any;
  inline?: boolean;
  tiny?: boolean;
  removable?: boolean;
  disabled?: boolean;
  labelWidth?: number;
  texts?: boolean;
  errors?: string[];
  multiSelect?: boolean;
}

const WhiteRadioGroup:FunctionComponent<Props> = (props) => {
  const { className, label, errors, value, options, texts, tiny, labelWidth, onChange, disabled, inline, removable, defaultOption, name, multiSelect, changeConfigField } = props;

  const onClick = useCallback((input: string | number) => {
    if (disabled) return;
    if (multiSelect) {
      if (value.includes(input)) {
        const newValue = value.filter((item: any) => item !== input);
        if (onChange) onChange(newValue);
        if (changeConfigField) changeConfigField({ [name]: newValue })
        return;
      }
      if (onChange) onChange([...value, input]);
      if (changeConfigField) changeConfigField({ [name]: [...value, input] })
      return;
    }

    if (value === input && removable) {
      if (onChange) onChange(defaultOption);
      if (changeConfigField) changeConfigField({ [name]: defaultOption })
      return;
    }
    if (onChange) onChange(input);
    if (changeConfigField) changeConfigField({ [name]: input })
  }, [disabled, multiSelect, value, removable, onChange, changeConfigField, name, defaultOption]);

  const active = useCallback((item) => multiSelect ? value.includes(item.value) : value === item.value, [multiSelect, value]);

  return (
    <WhiteField width={labelWidth} className={className} name={name} label={label} errors={errors} inline={inline}>
      <div className={styles.radioWrapper}>
        {options.map((item: Option, idx: number) => (
          <button
            key={idx}
            disabled={disabled}
            className={cx(styles.radio, {[styles.active]: active(item), [styles.tiny]: inline || tiny || !label, [styles.textRadio]: texts})}
            onClick={() => onClick(item.value)}
            cypress-id={`${name}-component-${idx}`}
          >
            {active(item) && item.activeLabel ? item.activeLabel : item.label}
          </button>
        ))}
      </div>
    </WhiteField>
  );
};

export default WhiteRadioGroup;
