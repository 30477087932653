import { UserRoles } from '../enums';
import { User } from '../type';

export const hasFullAccess = (user: User | undefined) => {
  if (!user) {
    return false;
  }

  return [UserRoles.ACCOUNT_OWNER, UserRoles.ACCOUNT_ADMIN, UserRoles.ADMIN, UserRoles.SUPER_ADMIN].includes(user.role);
};
