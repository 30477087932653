import { createAction } from '../../utils/actions';

export const setCampaign = createAction('campaign/set');
export const clearCampaign = createAction('campaign/clear');
export const clearCampaignHistory = createAction('campaign/clear_history');
export const addCampaignToHistory = createAction('campaign/add_to_history');
export const removeLastFromHistory = createAction('campaign/remove_last_from_history');
export const setActiveElement = createAction('campaign/set_active_element');
export const setLandingPageElements = createAction('campaign/set_landing_page_elements');
export const setStatuses = createAction('campaign/set_statuses');
export const setPrizes = createAction('campaign/set_prizes');
export const setPrizeCodes = createAction('campaign/set_prize_codes');
export const clearPrizeCodes = createAction('campaign/clear_prize_codes')
export const addPrize = createAction('campaign/add_prizes');
export const updatePrize = createAction('campaign/update_prizes');
export const removePrize = createAction('campaign/remove_prizes');
export const setEmailTemplates = createAction('campaign/update_emails');
export const updateEmailTemplate = createAction('campaign/update_email');
export const setWinners = createAction('campaign/set_winners');
export const addWinners = createAction('campaign/add_winners');
export const removeWinner = createAction('campaign/remove_winner');
export const removeWinners = createAction('campaign/remove_winners');
export const updatePrizeCount = createAction('campaign/update_prize_count');
export const setRelatedCampaigns = createAction('campaign/set_related_campaigns');
export const changeUpdatedNowBy = createAction('campaign/change_updated_now_by');
export const setCampaignInputList = createAction('campaign/set_input_list');
export const setEditingTemplate = createAction('campaign/set_editing_template');
export const updateLandingPageElementsParams = createAction('campaign/update_landing_page_elements_params');
export const deleteCampaignConnection = createAction('campaign/delete_campaign_connection');
export const setPagination = createAction('campaign/set_pagination');
export const updateOneInPagination = createAction('campaign/update_one_in_pagination');
export const updatePagination = createAction('campaign/update_pagination');
export const addOnePagination = createAction('campaign/add_one_pagination');
export const deleteOneInPagination = createAction('campaign/delete_one_in_pagination');
export const setField = createAction('campaign/set_field');
