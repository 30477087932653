import { createSelector } from 'reselect';
import { IntegrationState } from './types';
import { Store } from '../types';

const integrationStoreSelector = (store: Store) => store.integration;
export const integrationSelector = createSelector(integrationStoreSelector, (integrationStore: IntegrationState) => integrationStore.integration?.asMutable({ deep: true }));
export const segmentsSelector = createSelector(integrationStoreSelector, (integrationStore: IntegrationState) => integrationStore.segments.asMutable({ deep: true }));
export const eventsSelector = createSelector(integrationStoreSelector, (integrationStore: IntegrationState) => integrationStore.events.asMutable({ deep: true }));
export const hubspotConnectionsSelector = createSelector(integrationStoreSelector, (integrationStore: IntegrationState) => integrationStore.hubspotConnections.asMutable({ deep: true }));
export const mailChimpConnectionsSelector = createSelector(integrationStoreSelector, (integrationStore: IntegrationState) => integrationStore.mailchimpConnections.asMutable({ deep: true }));
