import { Options } from '../../domain/useOptions';

export const TIMEZONES_OPTIONS: Options[] = [
  {
    label: '(UTC-12:00) International Date Line West',
    value: 'Pacific/Wallis'
  },
  {
    label: '(UTC-11:00) Coordinated Universal Time-11',
    value: 'Pacific/Pago_Pago'
  },
  {
    label: '(UTC-10:00) Hawaii',
    value: 'US/Hawaii'
  },
  {
    label: '(UTC-09:00) Alaska',
    value: 'US/Alaska'
  },
  {
    label: '(UTC-08:00) Baja California',
    value: 'America/Los_Angeles'
  },
  {
    label: '(UTC-08:00) Pacific Time (US & Canada)',
    value: 'America/Los_Angeles'
  },
  {
    label: '(UTC-07:00) Arizona',
    value: 'America/Denver'
  },
  {
    label: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    value: 'America/Chihuahua'
  },
  {
    label: '(UTC-07:00) Mountain Time (US & Canada)',
    value: 'US/Mountain'
  },
  {
    label: '(UTC-06:00) Central America',
    value: 'America/Managua'
  },
  {
    label: '(UTC-06:00) Central Time (US & Canada)',
    value: 'US/Central'
  },
  {
    label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    value: 'America/Mexico_City'
  },
  {
    label: '(UTC-06:00) Saskatchewan',
    value: 'Canada/Saskatchewan'
  },
  {
    label: '(UTC-05:00) Bogota, Lima, Quito',
    value: 'America/Lima'
  },
  {
    label: '(UTC-05:00) Eastern Time (US & Canada)',
    value: 'US/Eastern'
  },
  {
    label: '(UTC-05:00) Indiana (East)',
    value: 'US/East-Indiana'
  },
  {
    label: '(UTC-04:30) Caracas',
    value: 'America/Caracas'
  },
  {
    label: '(UTC-04:00) Asuncion',
    value: 'America/Santiago'
  },
  {
    label: '(UTC-04:00) Atlantic Time (Canada)',
    value: 'Canada/Atlantic'
  },
  {
    label: '(UTC-04:00) Cuiaba',
    value: 'America/Sao_Paulo'
  },
  {
    label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    value: 'America/La_Paz'
  },
  {
    label: '(UTC-04:00) Santiago',
    value: 'America/Santiago'
  },
  {
    label: '(UTC-03:30) Newfoundland',
    value: 'Canada/Newfoundland'
  },
  {
    label: '(UTC-03:00) Brasilia',
    value: 'America/Sao_Paulo'
  },
  {
    label: '(UTC-03:00) Buenos Aires',
    value: 'America/Argentina/Buenos_Aires'
  },
  {
    label: '(UTC-03:00) Cayenne, Fortaleza',
    value: 'America/Cayenne'
  },
  {
    label: '(UTC-03:00) Greenland',
    value: 'America/Godthab'
  },
  {
    label: '(UTC-03:00) Montevideo',
    value: 'America/Montevideo'
  },
  {
    label: '(UTC-03:00) Salvador',
    value: 'America/Montevideo'
  },
  {
    label: '(UTC-02:00) Coordinated Universal Time-02',
    value: 'America/Godthab'
  },
  {
    label: '(UTC-02:00) Mid-Atlantic - Old',
    value: 'America/Noronha'
  },
  {
    label: '(UTC-01:00) Azores',
    value: 'Atlantic/Azores'
  },
  {
    label: '(UTC-01:00) Cape Verde Is.',
    value: 'Atlantic/Cape_Verde'
  },
  {
    label: '(UTC) Casablanca',
    value: 'Africa/Casablanca'
  },
  {
    label: '(UTC) Coordinated Universal Time',
    value: 'Etc/Greenwich'
  },
  {
    label: '(UTC) Dublin, Edinburgh, Lisbon, London',
    value: 'Europe/London'
  },
  {
    label: '(UTC) Monrovia, Reykjavik',
    value: 'Africa/Monrovia'
  },
  {
    label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'Europe/Amsterdam'
  },
  {
    label: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: 'Europe/Budapest'
  },
  {
    label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    value: 'Europe/Paris'
  },
  {
    label: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    value: 'Europe/Warsaw'
  },
  {
    label: '(UTC+01:00) West Central Africa',
    value: 'Africa/Lagos'
  },
  {
    label: '(UTC+01:00) Windhoek',
    value: 'Africa/Windhoek'
  },
  {
    label: '(UTC+02:00) Athens, Bucharest',
    value: 'Europe/Athens'
  },
  {
    label: '(UTC+02:00) Beirut',
    value: 'Asia/Beirut'
  },
  {
    label: '(UTC+02:00) Cairo',
    value: 'Africa/Cairo'
  },
  {
    label: '(UTC+02:00) Damascus',
    value: 'Asia/Damascus'
  },
  {
    label: '(UTC+02:00) E. Europe',
    value: 'Europe/London'
  },
  {
    label: '(UTC+02:00) Harare, Pretoria',
    value: 'Africa/Harare'
  },
  {
    label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: 'Europe/Helsinki'
  },
  {
    label: '(UTC+02:00) Istanbul',
    value: 'Europe/Istanbul'
  },
  {
    label: '(UTC+02:00) Jerusalem',
    value: 'Asia/Jerusalem'
  },
  {
    label: '(UTC+02:00) Tripoli',
    value: 'Africa/Tripoli'
  },
  {
    label: '(UTC+03:00) Amman',
    value: 'Asia/Amman'
  },
  {
    label: '(UTC+03:00) Baghdad',
    value: 'Asia/Baghdad'
  },
  {
    label: '(UTC+03:00) Kaliningrad, Minsk',
    value: 'Europe/Minsk'
  },
  {
    label: '(UTC+03:00) Kuwait, Riyadh',
    value: 'Asia/Kuwait'
  },
  {
    label: '(UTC+03:00) Nairobi',
    value: 'Africa/Nairobi'
  },
  {
    label: '(UTC+03:30) Tehran',
    value: 'Asia/Tehran'
  },
  {
    label: '(UTC+04:00) Abu Dhabi, Muscat',
    value: 'Asia/Muscat'
  },
  {
    label: '(UTC+04:00) Baku',
    value: 'Asia/Baku'
  },
  {
    label: '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
    value: 'Europe/Moscow'
  },
  {
    label: '(UTC+04:00) Port Louis',
    value: 'Indian/Mauritius'
  },
  {
    label: '(UTC+04:00) Tbilisi',
    value: 'Asia/Tbilisi'
  },
  {
    label: '(UTC+04:00) Yerevan',
    value: 'Asia/Yerevan'
  },
  {
    label: '(UTC+04:30) Kabul',
    value: 'Asia/Kabul'
  },
  {
    label: '(UTC+05:00) Ashgabat, Tashkent',
    value: 'Asia/Tashkent'
  },
  {
    label: '(UTC+05:00) Islamabad, Karachi',
    value: 'Asia/Karachi'
  },
  {
    label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    value: 'Asia/Kolkata'
  },
  {
    label: '(UTC+05:30) Sri Jayawardenepura',
    value: 'Asia/Calcutta'
  },
  {
    label: '(UTC+05:45) Kathmandu',
    value: 'Asia/Katmandu'
  },
  {
    label: '(UTC+06:00) Astana',
    value: 'Asia/Dhaka'
  },
  {
    label: '(UTC+06:00) Dhaka',
    value: 'Asia/Dhaka'
  },
  {
    label: '(UTC+06:00) Ekaterinburg',
    value: 'Asia/Yekaterinburg'
  },
  {
    label: '(UTC+06:30) Yangon (Rangoon)',
    value: 'Asia/Rangoon'
  },
  {
    label: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    value: 'Asia/Bangkok'
  },
  {
    label: '(UTC+07:00) Novosibirsk',
    value: 'Asia/Novosibirsk'
  },
  {
    label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    value: 'Asia/Hong_Kong'
  },
  {
    label: '(UTC+08:00) Krasnoyarsk',
    value: 'Asia/Krasnoyarsk'
  },
  {
    label: '(UTC+08:00) Kuala Lumpur, Singapore',
    value: 'Asia/Kuala_Lumpur'
  },
  {
    label: '(UTC+08:00) Perth',
    value: 'Australia/Perth'
  },
  {
    label: '(UTC+08:00) Taipei',
    value: 'Asia/Taipei'
  },
  {
    label: '(UTC+08:00) Ulaanbaatar',
    value: 'Asia/Ulaanbaatar'
  },
  {
    label: '(UTC+09:00) Irkutsk',
    value: 'Asia/Irkutsk'
  },
  {
    label: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    value: 'Asia/Tokyo'
  },
  {
    label: '(UTC+09:00) Seoul',
    value: 'Asia/Seoul'
  },
  {
    label: '(UTC+09:30) Adelaide',
    value: 'Australia/Adelaide'
  },
  {
    label: '(UTC+09:30) Darwin',
    value: 'Australia/Darwin'
  },
  {
    label: '(UTC+10:00) Brisbane',
    value: 'Australia/Brisbane'
  },
  {
    label: '(UTC+10:00) Canberra, Melbourne, Sydney',
    value: 'Australia/Melbourne'
  },
  {
    label: '(UTC+10:00) Guam, Port Moresby',
    value: 'Pacific/Port_Moresby'
  },
  {
    label: '(UTC+10:00) Hobart',
    value: 'Australia/Hobart'
  },
  {
    label: '(UTC+10:00) Yakutsk',
    value: 'Asia/Yakutsk'
  },
  {
    label: '(UTC+11:00) Solomon Is., New Caledonia',
    value: 'Pacific/Guadalcanal'
  },
  {
    label: '(UTC+11:00) Vladivostok',
    value: 'Asia/Vladivostok'
  },
  {
    label: '(UTC+12:00) Auckland, Wellington',
    value: 'Pacific/Auckland'
  },
  {
    label: '(UTC+12:00) Coordinated Universal Time+12',
    value: 'Pacific/Kwajalein'
  },
  {
    label: '(UTC+12:00) Fiji',
    value: 'Pacific/Fiji'
  },
  {
    label: '(UTC+12:00) Magadan',
    value: 'Asia/Magadan'
  },
  {
    label: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    value: 'Asia/Kamchatka'
  },
  {
    label: `(UTC+13:00) Nuku'alofa`,
    value: 'Pacific/Tongatapu'
  },
  {
    label: '(UTC+13:00) Samoa',
    value: 'Pacific/Samoa'
  }
];
