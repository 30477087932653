export enum CampaignScreenSetup {
  CLASSIC = 'classic',
  BEFORE_GAME = 'before_game',
  AFTER_GAME = 'after_game',
  BEFORE_AND_AFTER_GAME = 'before_and_after_game',
  ONLY_GAME = 'only_game',
  AFTER_ONLY_GAME = 'after_only_game',
  MODAL = 'modal',
  COMMUNITY_CONTEST_MODAL = 'community_contest_modal',
}
