import { useHistory } from 'react-router-dom';
import addyConfirm from '../../../components/confirm';

export const useBrandLimitNotification = () => {
  const history = useHistory();

  return () => {
    addyConfirm({
      title: 'Oops',
      content: 'Unfortunately, adding more brands is not allowed in your current license.',
      onOk: () => history.push('/settings/subscription?tab=licenses'),
      okText: 'Upgrade your license'
    });
  }
}
