import { useEffect } from 'react';
import client from '../utils/apiClient';
import * as T from './requestTypes';
import {
  AssignPrizeToPlayersDto,
  CreateCustomDomain,
  UpdateCustomDomain
} from './requestTypes';
import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { clearCampaigns } from '../store/campaigns/actions';
import { logout as signOut } from '../store/auth/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountLicense, PaymentAddon } from '../store/common/account-licenses/types';
import {
  CreateInvitationPayloadType,
  DeleteMediaFilePayloadType,
  ExportFileType,
  ReceiveCampaignNotificationsPayloadType,
  SystemNotificationPayloadType,
  UpdateMediaFilePayloadType,
  UserUpdatePayloadType,
  CreateGameTagPayloadType,
  UpdateGameTagPayloadType, DuplicateCampaignByAdmin, RafflePrizePayload, EmailTemplate,
  CampaignUnlockByTokenPayload,
} from '../utils/type';
import { BuyTokensType } from '../utils/type/tokens/buyTokens.type';
import { CreateCampaignUnlockByTokenPayloadType } from '../utils/type/tokens/createCampaignUnlockByTokenPayload.type';
import { PaginationPayload } from '../utils/type/tokens/paginationPayload.type';
import { CheckoutSessionPayload } from '../utils/type/payments/checkoutSession.type';
import { CreateSubscriptionPayload } from '../utils/type/subscriptions/createSubscription.type';
import { CreateEnterpriseSubscriptionPayload } from '../utils/type/subscriptions/createEnterpriseSubscription.type';
import { UpdateSubscriptionPayload } from '../utils/type/subscriptions/updateSubscription.type';
import { UpdateSubscriptionByAdminPayload } from '../utils/type/subscriptions/updateSubscriptionByAdmin.type';
import { UpdateAccountPayload } from '../utils/type/account/updateAccountPayload.type';
import { PortalSessionPayload } from '../utils/type/payments/portalSessionPayload.type';
import { InvoiceCustomFieldsPayload } from '../utils/type/payments/invoiceCustomFieldsPayload.type';
import { CancelSubscriptionPayload } from '../utils/type/payments/cancelSubscriptionPayload.type';
import { AccountLimitsUpdatePayload } from '../utils/type/account/accountLimitsUpdatePayload.type';

const API = (token: string | null) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  client.defaults.headers = {
    Authorization: token ? `Bearer ${token}` : null
  };

  useEffect(() => {
    const id = client.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      const status = error.response?.status;
      if (status === 401) {
        dispatch(clearCampaigns());
        dispatch(signOut());
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('refresh_token_expired_at');
        if (history && location && location.pathname !== '/login') history.push('/login', { from: location.pathname });
      }
      return Promise.reject(error);
    });
    return () => {
      client.interceptors.response.eject(id);
    }
  }, []);

  return {
    getListOfLicenses: (id: number) => client.get(`accounts/${id}/payments`),
    getLicense: (accountId: number, licenseId: number) => client.get(`accounts/${accountId}/payments/${licenseId}`),
    createLicense: (id: number, data: Partial<AccountLicense>) => client.post(`accounts/${id}/payments`, data),
    createExpiredLicense: (id: number, data: Partial<AccountLicense>) => client.post(`accounts/${id}/payments/expired-license`, data),
    updateLicense: (accountId: number, licenseId: number, data: Partial<AccountLicense>) => client.put(`accounts/${accountId}/payments/${licenseId}`, data),
    activateLicense: (accountId: number, licenseId: number) => client.post(`accounts/${accountId}/payments/${licenseId}/activate`),
    expireLicense: (accountId: number, licenseId: number) => client.post(`accounts/${accountId}/payments/${licenseId}/expire`),
    upgradeTrialLicense: (accountId: number, licenseId: number, data: Partial<AccountLicense>) => client.post(`accounts/${accountId}/payments/${licenseId}/rewrite`, data),
    deleteLicense: (accountId: number, licenseId: number) => client.delete(`accounts/${accountId}/payments/${licenseId}`),
    createAddon: (accountId: number, licenseId: number, data: Partial<PaymentAddon>) => client.post(`accounts/${accountId}/payments/${licenseId}/addons`, data),
    deleteAddon: (accountId: number, licenseId: number, addonId: number, data: Pick<PaymentAddon, 'deleting_reason'>) => client.delete(`accounts/${accountId}/payments/${licenseId}/addons/${addonId}`, { data }),
    getAllLicenses: (query: string) => client.get(`payment-licenses?limit=50&${query}`),
    exportLicenses: (query: string, type: string) => client.get(`payment-licenses/export?exportingType=${type}&${query}`, { responseType: 'blob'}),
    getLicensesMonthlyStatistics: (query: string) => client.get(`payment-monthly-statistics?${query}`),
    getStatisticByLicenseType: (query: string) => client.get(`payment-statistics?${query}`),

    //ADMIN PAYMENT API

    // AUTH API
    signIn: (data: T.SignInData) => client.post('/auth/sign-in', data, { headers: {'Authorization': token ? `Bearer ${token}` : null }}),
    join: (data: T.JoinData) => client.post('/account', data),
    signUp: (data: T.SignUpData) => client.post('/auth/sign-up', data),
    forgotPassword: (data: T.ForgotPasswordData) => client.post('/auth/forgot-password', data),
    saveNewPassword: (data: T.SaveNewPasswordData) => client.post('/auth/save-new-password', data),
    updateProfile: (data: T.UpdateProfileData | T.UpdateAccount) => client.put('/auth/update-profile', data),
    changePassword: (data: T.ChangePasswordData) => client.put('/auth/change-password', data),
    confirmEmail: (data: T.ConfirmEmailData) => client.put('/account/confirm-email', data),
    me: () => client.get('/auth/me'),
    signInByRefreshToken: (data: T.SignInByRefreshTokenData) => client.post('/auth/sign-in-by-refresh-token', data),

    // ACCOUNT API

    getAccount: () => client.get('/account'),
    getAccountLimits: () => client.get('/account/used-limits'),
    getBrandsAndCampaignsCount: () => client.get('/account/amount-of-brands-and-campaigns'),
    updateAccountCompanyInfo: (data: T.UpdateAccount) => client.put('/account/update-company-info', data),
    updateAccount: (data: UpdateAccountPayload) => client.put('/account', data),

    getUsers: (page: number, query: string) => client.get(`/users?page=${page}&limit=50&${query}`),
    getUser: (id: number) => client.get(`/users/${id}`),
    updateUser: (id: number, data: UserUpdatePayloadType) => client.put(`/users/${id}`, data),
    deleteUser: (id: number) => client.delete(`/users/${id}`),
    getUserTags: () => client.get(`/user-tags`),
    receiveCampaignNotifications: (data: ReceiveCampaignNotificationsPayloadType) => client.post(`/users/receive-campaign-notifications`, data),

    getInvitations: (page: number, query: string) => client.get(`/invitations?page=${page}&limit=50&${query}`),
    createInvitation: (data: CreateInvitationPayloadType) => client.post('/invitations', data),
    resendInvitation: (id: number) => client.post(`/invitations/${id}/resend`),
    deleteInvitation: (id: number) => client.delete(`/invitations/${id}`),

    // BRANDS API
    createBrand: (data: T.CreateBrandData) => client.post('/account/brands', data),
    getBrand: (id: number | string) => client.get(`/account/brands/${id}`),
    getBrands: () => client.get('/account/brands-with-campaigns-list'),
    getAdminAccountBrands: (accountId: string) => client.get(`/admin/accounts/${accountId}/brands-with-campaigns-list`),
    updateBrand: (id: number, data: T.UpdateBrandData) => client.put(`/account/brands/${id}`, data),
    destroyBrand: (id: number) => client.delete(`/account/brands/${id}`),

    // BRANDS API
    getBrandEmails: (id: number, query: string) => client.get(`/brands/${id}/emails?${query}`),
    createBrandEmail: (id: number, data: T.CreateBrandEmailData) => client.post(`/brands/${id}/emails`, data),
    updateBrandEmail: (id: number, emailId: number, data: T.UpdateBrandEmailData) => client.put(`/brands/${id}/emails/${emailId}`, data),
    resendBrandEmailConformation: (id: number, emailId: number) => client.post(`/brands/${id}/emails/${emailId}/resend`),
    deleteBrandEmail: (id: number, emailId: number) => client.delete(`/brands/${id}/emails/${emailId}`),
    confirmBrandEmail: (data: T.ConfirmEmailData) => client.post(`/brand-emails/confirm`, data),
    getCampaignsWithEmail: (id: number, emailId: number) => client.get(`/brands/${id}/emails/${emailId}/campaigns`),

    adminChangeBrandAccount: (id: number, accountId: number) => client.post(`/admin/brands/${id}/change-brand-account/${accountId}`),
    adminGetAccountAndBrandUsedLimits: (id: number, accountId: number) => client.get(`/admin/brands/${id}/used-limits/${accountId}`),

    // GAMES API
    getGamesList: () => client.get('/account/games'),
    getAllGames: () => client.get('/account/games-for-single-plan'),
    getTriviaQuestionsExample: () => client.get(`/account/games/trivia-csv-example`, { responseType: 'blob'}),

    // UPLOAD API
    upload: (data: FormData, config?: AxiosRequestConfig) => client.post('/upload', data, {headers: {'Content-Type': 'multipart/form-data'}, ...(config || {})}),
    uploadFont: (data: FormData, config?: AxiosRequestConfig) => client.post('/upload-font', data, {headers: {'Content-Type': 'multipart/form-data'}, ...(config || {})}),
    uploadVideo: (data: T.VideoUploadData, config?: AxiosRequestConfig) => client.post('/upload-video', data, config),
    uploadEmbedScript: (data: FormData, config?: AxiosRequestConfig) => client.post('/upload-embedded-code', data, {headers: {'Content-Type': 'multipart/form-data'}, ...(config || {})}),
    resize: (data: T.ResizeImageData) => client.post('/resize', data),
    getVideoProcessingProgress: (videoId: number) => client.get(`/video-processing-progress/${videoId}`),
    getVideoPreSignUrl: () => client.get('/get-s3-pre-signed-url?type=video'),
    uploadVideoToS3: (url: string, file: File, config?: AxiosRequestConfig) => axios.put(url, file, config),
    checkVideoAvailability: (videoId: number) => axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}&color=%233261ec&loop=false&width=720&responsive=false&autoplay=false&controls=true`),
    uploadFilesHubSpot: (data: FormData, config?: AxiosRequestConfig) => client.post('account/hubspot-ticket/files', data, {headers: {'Content-Type': 'multipart/form-data'}, ...(config || {})}),

    // CAMPAIGNS API
    getCampaigns: (brandId: number, page: number) => client.get(`/account/campaigns?brandId=${brandId}&page=${page}`),
    getCampaignsLimits: (page: number, query: string) => client.get(`/account/active-and-paused-campaigns-analytic?page=${page}&limit=5&${query}`),
    getCampaign: (id: number) => client.get(`/account/campaigns/${id}`),
    searchCampaigns: (query: string) => client.get(`/account/campaigns/autocomplete?${query}`),
    duplicateCampaign: (id: number, data: T.DuplicateCampaign) => client.post(`/account/campaigns/${id}/duplicate`, data),
    deleteCampaignConnection: (id: number, connectedId: number) => client.delete(`/account/campaigns/${id}/connected/${connectedId}`),
    getConnectedCampaigns: (id: number) => client.get(`/account/campaigns/${id}/connected`),
    getAvailableForConnectCampaigns: (id: number) => client.get(`/account/campaigns/${id}/available-for-connection`),
    createCampaignConnection: (id: number, connectedId: number) => client.post(`/account/campaigns/${id}/connected/${connectedId}`),
    deleteCampaign: (id: number) => client.delete(`/account/campaigns/${id}`),
    createCampaign: (data: T.CreateCampaignData) => client.post(`/account/campaigns/`, data),
    updateCampaignFromScreenSetupWizard: (id: number, data: any) => client.post(`/account/campaigns/${id}/wizard-setup`, data),
    updateCampaign: (id: number, data: any, cancelToken?: CancelToken) => client.put(`/account/campaigns/${id}`, data, { cancelToken }),
    translateCampaign: (id: number, data: T.TranslateCampaign) => client.post(`/account/campaigns/${id}/translate`, data),
    getRelatedCampaigns: (id: number) => client.get(`/account/campaigns/${id}/list-to-connect-to-translations`),
    publishCampaignChanges: (id: number) => client.post(`/campaigns/${id}/update-cache`),
    getNewsPosts: (page: number) => client.get(`/account/news?page=${page}&limit=3`),
    updateNewsPostClicks: (id: number) => client.post(`/account/news/${id}/clicks`),
    getCampaignInputList: (id: number) => client.get(`/campaigns/${id}/input-list`),
    removeFileBackground: (data: FormData) => client.post('/account/add-file-to-remove-bg-service', data, { headers: {'Content-Type': 'multipart/form-data' }}),
    republishDuplicateCampaign: (id: number, data: T.RepublishAsNewCampaign) => client.post(`account/campaigns/${id}/publish-duplicate`, data),
    getCampaignUploadedContent: (id: number, page: number, query: string) => client.get(`/account/campaigns/${id}/uploaded-contents?page=${page}&limit=50&${query}`),
    updateCampaignUploadedContent: (id: number, contentId: string, data: any) => client.put(`/account/campaigns/${id}/uploaded-contents/${contentId}`, data),
    createCampaignUploadedContent: (id: number, data: any) => client.post(`/account/campaigns/${id}/uploaded-contents`, data),
    deleteCampaignUploadedContent: (id: number, contentId: string) => client.delete(`/account/campaigns/${id}/uploaded-contents/${contentId}`),
    getCampaignLandingPageAllowedValues: (id: number, page: number, query: string) => client.get(`/campaigns/${id}/allowed-values?page=${page}&limit=50&${query}`),
    createCampaignLandingPageAllowedValues: (id: number, data: T.CreateAllowedValuesData) => client.post(`/campaigns/${id}/allowed-values`, data),
    deleteCampaignLandingPageAllowedValues: (id: number, data: Record<string, any>) => client.delete(`/campaigns/${id}/allowed-values`, { data }),
    getCampaignAllowedDomains: (id: number) => client.get(`/account/campaigns/${id}/email-domains`),
    createCampaignAllowedDomains: (id: number, data: T.CreateCampaignAllowedDomains) => client.post(`/account/campaigns/${id}/email-domains`, data),
    updateCampaignAllowedDomains: (id: number, data: T.CreateCampaignAllowedDomains) => client.put(`/account/campaigns/${id}/email-domains`, data),
    deleteCampaignAllowedDomains: (id: number) => client.delete(`/account/campaigns/${id}/email-domains`),
    getSharedLeaderboardSettings: (id: number) => client.get(`/account/campaigns/${id}/shared-leaderboard-setting`),
    adminGetSharedLeaderboardSettings: (id: number) => client.get(`/admin/campaigns/${id}/shared-leaderboard-setting`),
    updateSharedLeaderboardSettings: (id: number, data: T.UpdateSharedLeaderboardList) => client.post(`/account/campaigns/${id}/shared-leaderboard-setting`, data),
    adminUpdateSharedLeaderboardSettings: (id: number, data: T.UpdateSharedLeaderboardList) => client.post(`/admin/campaigns/${id}/shared-leaderboard-setting`, data),
    deleteSharedLeaderboardSettings: (id: number) => client.delete(`/account/campaigns/${id}/shared-leaderboard-setting`),
    adminDeleteSharedLeaderboardSettings: (id: number,) => client.delete(`/admin/campaigns/${id}/shared-leaderboard-setting`),
    getListForLeaderboardConnection: (id: number) => client.get(`/campaigns/${id}/list-for-leaderboard`),
    lockDraftCampaign: (id: number) => client.post(`/campaigns/${id}/locked`),

    // CAMPAIGN STATUSES
    getCampaignStatuses: () => client.get('/campaigns/statuses'),

    // SCREENS API
    getScreenTypes: () => client.get('/screen-types'),
    getLandingPageElements: (brandId: any) => client.get(`/landing-page-elements?brandId=${brandId}`),
    getAdminLandingPageElements: () => client.get('/landing-page-elements'),

    // PRIZES API
    getCampaignPrizes: (id: string | number) => client.get(`/account/campaigns/${id}/prizes`),
    createCampaignPrize: (id: any, data: T.PrizeData) => client.post(`/account/campaigns/${id}/prizes`, data),
    updateCampaignPrize: (id: any, data: T.PrizeData, prizeId: string) => client.put(`/account/campaigns/${id}/prizes/${prizeId}`, data),
    getCampaignPrizeCustomCodes: (prizeId: string, page: number, query: string) => client.get(`/account/prizes/${prizeId}/prize-codes?page=${page}&limit=1000&${query}`),
    adminGetCampaignPrizeCustomCodes: (prizeId: string, page: number, query: string) => client.get(`/admin/prizes/${prizeId}/prize-codes?page=${page}&limit=1000&${query}`),
    removePrize: (id: any, prizeId: string) => client.delete(`/account/campaigns/${id}/prizes/${prizeId}`),
    rafflePrize: (id: any, prizeId: string, data: RafflePrizePayload) => client.post(`/campaigns/${id}/raffle/${prizeId}`, data ),
    rafflePrizeDetails: (id: any, historyId: string) => client.get(`/account/campaigns/${id}/raffling-histories/${historyId}`),
    getCampaignWinners: (id: any, page: number, search?:string) => client.get(`/account/campaigns/${id}/assigned-prizes?page=${page}&limit=50&${search ? search : ''}`),
    exportWinners: (id: any, type: string) => client.get(`/account/campaigns/${id}/assigned-prizes/export?exportingType=${type}`, { responseType: 'blob'}),
    removeWinner: (id: any, winnerId: string) => client.delete(`/account/campaigns/${id}/assigned-prizes/${winnerId}`),
    removeWinners: (id: any, data: T.RemoveWinners) => client.delete(`/account/campaigns/${id}/assigned-prizes/bulk-delete`, { data }),
    sendEmailToWinners: (id: any, assignedPrizesIds: string[]) => client.post(`/campaigns/${id}/assigned-prizes/resend-winner-emails`, { assignedPrizesIds }),
    getEmailRecipients: (id: number, query: string) => client.get(`/campaigns/${id}/email-recipients?${query}`),
    getPlayersForAssignment: (campaignId: number, query: string) => client.get(`/campaigns/${campaignId}/players-for-assignment?${query}`),
    assignPrizeToPlayers: (campaignId: number, id: string, data: AssignPrizeToPlayersDto) => client.post(`/campaigns/${campaignId}/prizes/${id}/assign`, data),

    // EMAILS API
    getEmails: (id: number) => client.get(`/campaigns/${id}/email-templates`),
    createEmailTemplate: (id: any, data: Partial<EmailTemplate>) => client.post(`/campaigns/${id}/email-templates`, data),
    updateEmail: (campaignId: number, id: string, data: Partial<EmailTemplate>) => client.put(`/campaigns/${campaignId}/email-templates/${id}`, data),
    sendTestEmail: (campaignId: any, templateId: string, data: T.SendTestEmail) => client.post(`/campaigns/${campaignId}/email-templates/${templateId}/send-test-email`, data),
    deleteEmail: (campaignId: any, templateId: string) => client.delete(`/campaigns/${campaignId}/email-templates/${templateId}`),
    getEmailDomains: () => client.get('/custom-email-domains'),
    createEmailDomain: (data: T.CreateEmailDomainPayload) => client.post('/custom-email-domains', data),
    verifyEmailDomain: (domainId: string) => client.post(`/custom-email-domains/${domainId}/verify`),
    deleteEmailDomain: (domainId: string) => client.delete(`/custom-email-domains/${domainId}`),

    // FONTS API
    getBrandFonts: (brandId: number) => client.get(`/account/brands/${brandId}/fonts`),
    removeBrandFont: (brandId: number, fontId: number) => client.delete(`/account/brands/${brandId}/fonts/${fontId}`,),
    createBrandFont: (brandId: number, data: T.CreateFontData) => client.post(`/account/brands/${brandId}/fonts`, data),

    // ANALYTICS API
    getCampaignsWithAnalytics: (brandId: number, page: number, limit: number, query: string) => client.get(`/account/campaigns-with-analytic?brandId=${brandId}&page=${page}&limit=${limit}&${query}`),
    getOneAnalytics: (id: string, query: string) => client.get(`/campaigns-with-analytic/${id}?${query}`),
    getCombinedDailyStatistics: (id: number, query: string) => client.get(`/campaigns/${id}/combined-single-daily-statistic?${query}`),
    getDailyStatistics: (id: number, query: string) => client.get(`/campaigns/${id}/daily-statistics?${query}`),
    exportDailyStatistics: (id: number, type: ExportFileType) => client.get(`/account/campaigns/${id}/daily-statistics/export?exportingType=${type}`, { responseType: 'blob' }),
    getOneDailyStatistics: (campaignId: number, id: string) => client.get(`/account/campaigns/${campaignId}/daily-statistics/${id}`),
    adminGetOneDailyStatistics: (campaignId: number, id: string) => client.get(`/admin/campaigns/${campaignId}/daily-statistics/${id}`),
    getTopPlayers: (id: number, query: string) => client.get(`/campaigns/${id}/top-players?${query}`),
    exportPlayers: (id: number, data: T.ExportPlayersData) => client.post(`/account/campaigns/${id}/players-export`, data),
    getPlayersActivity: (id: number, query: string) => client.get(`/campaigns/${id}/players-activity?${query}`),
    getGameplays: (id: number, page: number, search: string, sort?: string) => client.get(`/account/campaigns/${id}/gameplay?page=${page}&limit=10&${search}&${sort}`),
    exportGameplays: (id: number, data: T.ExportPlayersData) => client.post(`/campaigns/${id}/gameplay-export`, data),
    unmarkCheatedGameplays: (data: T.UnmarkCheatedGameplaysData) => client.post('/gameplay/unmark-cheated', data),
    getButtonsAndTraffics: (id: number, query: string) => client.get(`/campaigns/${id}/buttons-and-traffics?${query}`),
    deleteGameplay: (campaignId: number, gameplayId: string) => client.delete(`/account/campaigns/${campaignId}/gameplay/${gameplayId}`),
    anonymizePlayers: (id: number) => client.post(`/account/campaigns/${id}/anonymize`),
    resetCampaignStatistics: (id: number) => client.post(`/campaigns/${id}/reset-campaign`),
    campaignLeaderboardData: (id: number, query: string) => client.get(`/campaigns/${id}/leaderboard?${query}`),
    campaignPollStatisticsData: (id: number) => client.get(`/account/campaigns/${id}/poll-statistic`),
    adminCampaignPollStatisticsData: (id: number) => client.get(`/admin/campaigns/${id}/poll-statistic`),
    getEmailDailyStatistics: (id: number, query: string) => client.get(`/campaigns/${id}/email-daily-statistics?${query}`),
    getEmailStatistics: (id: number, query: string) => client.get(`/campaigns/${id}/email-statistic?${query}`),
    exportEmailDailyStatistics: (id: number, type: string) => client.get(`campaigns/${id}/email-daily-statistics/export?exportingType=${type}`, { responseType: 'blob' }),
    getTriviaGameAnalytics: (id: number) => client.get(`/campaigns/${id}/trivia-statistic`),
    getTriviaGameQuestionsAnalytics: (id: number, query: string) => client.get(`/campaigns/${id}/trivia-question-statistics?${query}`),
    getPlayersQuestionsAnalytics: (id: number, query: string) => client.get(`/campaigns/${id}/players-with-questions?${query}`),
    postPredictionGameScoreGeneration: (id: number, data: T.GeneratePredictionPlayerScore) => client.post(`/campaigns/${id}/generate-prediction-score`, data),

    // FOLDERS
    changeCampaignsFolder: (brandId: number, folderId: number, data: Record<string, any>) => client.post(`/brands/${brandId}/folders/${folderId}/add-campaigns`, data),
    getFolders: (brandId: number, query: string) => client.get(`/brands/${brandId}/folders?${query}`),
    createFolder: (brandId: number, data: Record<string, any>) => client.post(`/brands/${brandId}/folders`, data),
    updateFolder: (id: number, brandId: number, data: Record<string, any>) => client.put(`/brands/${brandId}/folders/${id}`, data),
    deleteFolder: (id: number, brandId: number) => client.delete(`/brands/${brandId}/folders/${id}`),

    // ADMIN API
    getAdminGames: () => client.get('/admin/games'),
    getAdminGame: (id: string) => client.get(`/admin/games/${id}`),
    updateAdminGame: (id: string, data: FormData) => client.put(`/admin/games/${id}`, data, {headers: {'Content-Type': 'multipart/form-data'}}),
    getAdminGameTags: () => client.get('/game-tags'),
    getAdminGameTag: (id: number) => client.get(`/game-tags/${id}`),
    updateAdminGameTag: (id: number, data: UpdateGameTagPayloadType) => client.put(`/game-tags/${id}`, data),
    deleteAdminGameTag: (id: number) => client.delete(`/game-tags/${id}`),
    createAdminGameTag: (data: CreateGameTagPayloadType) => client.post('/game-tags', data),
    getAdminCampaigns: (page: number, query: string) => client.get(`/admin/campaigns?page=${page}&limit=50&${query}`),
    getAdminCampaignsAutocomplete: (search: string) => client.get(`/admin/campaigns/autocomplete?search=${search}`),
    getAdminBrandsAutocomplete: (query: string) => client.get(`/admin/brands/autocomplete?${query}`),
    getAdminAccountsAutocomplete: (query: string) => client.get(`/admin/accounts/autocomplete?${query}`),
    getAdminPlayers: (page: number, query: string) => client.get(`/admin/players?page=${page}&limit=50&${query}`),
    deleteAdminPlayer: (id: string) => client.put(`/admin/players/${id}/clear-data`),
    getAdminBrands: (page: number, query: string) => client.get(`/admin/brands?page=${page}&limit=50&${query}`),
    getAdminBrand: (id: string) => client.get(`/admin/brands/${id}`),
    updateAdminBrand: (id: string, data: T.UpdateBrandData) => client.put(`/admin/brands/${id}`, data),
    deleteAdminBrand: (id: string) => client.delete(`/admin/brands/${id}`),
    getAdminAccounts: (page: number, query: string) => client.get(`/admin/accounts?page=${page}&limit=50&${query}`),
    createAdminAccount: (data: T.CreateAdminAccountData) => client.post('/admin/accounts', data),
    deleteAdminAccount: (id: number) => client.delete(`admin/accounts/${id}`),

    adminAccountTogglePayment: (id: string, data: T.DisablePaymentData) => client.patch(`/admin/accounts/${id}/toggle-payment-check`, data),
    updateAdminAccount: (id: number, data: T.UpdateAdminAccountData) => client.put(`/admin/accounts/${id}`, data),
    updateAccountLimits: (data: AccountLimitsUpdatePayload) => client.put('/admin/accounts/change-limits', data),
    getAdminAccount: (id: string | number) => client.get(`/admin/accounts/${id}`),
    createAdminBrand: (data: T.AdminCreateBrandData) => client.post('/admin/brands', data),
    updateAdminAccountBrand: (id: number, data: T.AdminCreateBrandData) => client.put(`/admin/brands/${id}`, data),
    resendAccountInvitation: (id: number) => client.post(`/admin/accounts/${id}/resend-invitation`),
    sendNewPassword: (id: number) => client.post(`/admin/accounts/${id}/send-new-password`),
    getAdminNewsPosts: (page: number, query: string) => client.get(`/admin/news?page=${page}&limit=50&${query}`),
    getAdminNewsPost: (id: string) => client.get(`/admin/news/${id}`),
    updateAdminNewsPost: (id: number | string, data: FormData) => client.put(`/admin/news/${id}`, data, {headers: {'Content-Type': 'multipart/form-data'}}),
    createAdminNewsPost: (data: FormData) => client.post('/admin/news', data, {headers: {'Content-Type': 'multipart/form-data'}}),
    deleteAdminNewsPost: (id: number) => client.delete(`/admin/news/${id}`),


    // ADMIN CUSTOMER SUCCESS
    getTopPerformingCampaigns: (page: number, query: string) => client.get(`admin/campaigns/top-performing?page=${page}&limit=50&${query}`),
    getCampaignsByAverageGameplay: (page: number, query: string) => client.get(`admin/campaigns/daily-statistics-average?page=${page}&limit=50&${query}`),
    getRecentlyPublishedCampaigns: (page: number, query: string) => client.get(`admin/campaigns/live?page=${page}&limit=50&${query}`),
    getEndedCampaigns: (page: number, query: string) => client.get(`admin/campaigns/ended?page=${page}&limit=50&${query}`),
    getAccountsCountries: () => client.get('/admin/accounts/counties'),
    getInactiveAccounts: (page: number, query: string) => client.get(`/admin/accounts/inactive?page=${page}&limit=50&${query}`),
    getExpiringAccounts: (page: number, query: string) => client.get(`/admin/accounts/expiring?page=${page}&limit=50&${query}`),
    getAccountsThatHaveNotPublished: (page: number, query: string) => client.get(`/admin/accounts/not-published?page=${page}&limit=50&${query}`),
    getJoinedAccounts: (query: string) => client.get(`/admin/accounts/joined?${query}`),
    getAccountStatistics: (accountId: number) => client.get(`/admin/accounts/${accountId}/statistics`),

    // ADMIN CAMPAIGN-TEMPLATES API
    getAdminCampaignTemplates: (page: number, query: string) => client.get(`/admin/campaign-templates?page=${page}&limit=50&${query}`),
    getOneAdminCampaignTemplate: (id: number) => client.get(`/admin/campaign-templates/${id}`),
    getDefaultAdminCampaignTemplate: () => client.get('/admin/campaign-templates-default'),
    createAdminCampaignTemplate: (data: FormData) => client.post('admin/campaign-templates', data, {headers: {'Content-Type': 'multipart/form-data'}}),
    updateAdminCampaignTemplate: (id: number, data: FormData) => client.put(`/admin/campaign-templates/${id}`, data, {headers: {'Content-Type': 'multipart/form-data'}}),
    deleteAdminCampaignTemplate: (id: number) => client.delete(`/admin/campaign-templates/${id}`),

    // BRAND CAMPAIGN-TEMPLATES API
    getBrandCampaignTemplates: (id: number) => client.get(`/account/brands/${id}/campaign-templates`),
    createBrandCampaignTemplate: (id: number, data: any) => client.post(`/account/brands/${id}/campaign-templates`, data),
    updateBrandCampaignTemplate: (brandId: number, templateId: number, data: any) => client.put(`/account/brands/${brandId}/campaign-templates/${templateId}`, data),
    deleteBrandCampaignTemplate: (brandId: number, templateId: number) => client.delete(`/account/brands/${brandId}/campaign-templates/${templateId}`),

    // BRAND SCREEN-TEMPLATES API
    getBrandScreenTemplates: (id: number) => client.get(`brands/${id}/screen-templates`),
    createBrandScreenTemplate: (id: number, data: any) => client.post(`brands/${id}/screen-templates`, data),
    deleteBrandScreenTemplate: (brandId: number, templateId: number) => client.delete(`brands/${brandId}/screen-templates/${templateId}`),

    // ADMIN CAMPAIGN API
    getAdminCampaign: (id: number) => client.get(`/admin/campaigns/${id}`),
    adminDuplicateCampaign: (id: number, data: DuplicateCampaignByAdmin) => client.post(`/admin/campaigns/${id}/duplicate`, data),
    deleteAdminCampaign: (id: number) => client.delete(`/admin/campaigns/${id}`),
    updateAdminCampaign: (id: number, data: any, cancelToken?: CancelToken) => client.put(`/admin/campaigns/${id}`, data, { cancelToken }),
    getAdminRelatedCampaigns: (id: number) => client.get(`/admin/campaigns/${id}/list-to-connect-to-translations`),
    adminPublishDuplicateCampaign: (id: number, data: T.RepublishAsNewCampaign) => client.post(`admin/campaigns/${id}/publish-duplicate`, data),
    adminChangeCampaignBrand: (id: number, brandId: number) => client.post(`/admin/campaigns/${id}/change-campaign-brand/${brandId}`),

    // ADMIN ANALYTICS API
    exportAdminPlayers: (id: any, data: T.ExportPlayersData) => client.post(`/admin/campaigns/${id}/players-export`, data),
    getAdminGameplays: (id: number, page: number, search: string, sort?: string) => client.get(`/admin/campaigns/${id}/gameplay?page=${page}&limit=10&${search}&${sort}`),
    deleteAdminGameplay: (campaignId: number, gameplayId: string) => client.delete(`/admin/campaigns/${campaignId}/gameplay/${gameplayId}`),
    anonymizeAdminPlayers: (id: number) => client.post(`/admin/campaigns/${id}/anonymize`),
    exportAdminDailyStatistics: (id: number, type: ExportFileType) => client.get(`/admin/campaigns/${id}/daily-statistics/export?exportingType=${type}`, { responseType: 'blob' }),

    // ADMIN BRAND FONTS API
    getAdminBrandFonts: (brandId: number) => client.get(`/admin/brands/${brandId}/fonts`),

    // ADMIN PRIZES API
    getAdminCampaignPrizes: (id: string | number) => client.get(`/admin/campaigns/${id}/prizes`),
    createAdminCampaignPrize: (id: any, data: T.PrizeData) => client.post(`/admin/campaigns/${id}/prizes`, data),
    updateAdminCampaignPrize: (id: any, data: T.PrizeData, prizeId: string) => client.put(`/admin/campaigns/${id}/prizes/${prizeId}`, data),
    removeAdminPrize: (id: any, prizeId: string) => client.delete(`/admin/campaigns/${id}/prizes/${prizeId}`),
    raffleAdminPrizeDetails: (id: any, historyId: string) => client.get(`/admin/campaigns/${id}/raffling-histories/${historyId}`),
    getAdminCampaignWinners: (id: any, page: number, search?:string) => client.get(`/admin/campaigns/${id}/assigned-prizes?page=${page}&limit=50&${search ? search : ''}`),
    exportAdminWinners: (id: any, type: string) => client.get(`/admin/campaigns/${id}/assigned-prizes/export?exportingType=${type}`, { responseType: 'blob'}),
    removeAdminWinner: (id: any, winnerId: string) => client.delete(`/admin/campaigns/${id}/assigned-prizes/${winnerId}`),
    removeAdminWinners: (id: any, data: T.RemoveWinners) => client.delete(`/admin/campaigns/${id}/assigned-prizes/bulk-delete`, { data }),

    // ADMIN GAMES API
    getAdminGamesList: () => client.get('/admin/games'),

    // CAMPAIGN S3
    getS3Campaign: (campaignId: string) =>  axios.get(`${process.env.REACT_APP_CAMPAIGN_S3_URL}/${campaignId}`, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    }),


    // INTEGRATION API
    getAccountIntegration: () => client.get('/account/integration'),
    createAccountIntegration: (data: T.CreateIntegrationData) => client.post('/account/integration', data),
    updateAccountIntegration: (data: T.CreateIntegrationData) => client.put('/account/integration', data),
    regenerateIntegrationKey: () => client.post('/account/integration/regenerate-api-key'),
    getIntegrationSegments: () => client.get('/account/integration/segments'),
    createIntegrationSegments: (data: T.CreateIntegrationSegment) => client.post('/account/integration/segments', data),
    updateIntegrationSegments: (segmentId: number, data: T.CreateIntegrationSegment) => client.put(`/account/integration/segments/${segmentId}`, data),
    deleteIntegrationSegments: (segmentId: number) => client.delete(`/account/integration/segments/${segmentId}`),
    getIntegrationWebhookEvents: (page: number) => client.get(`/account/integration/webhook-events?page=${page}&limit=10`),
    resendIntegrationWebhookEvent: (eventId: string) => client.post(`/account/integration/webhook-events/${eventId}/resend`),
    getIntegrationWebhookEvent: (eventId: string) => client.get(`/account/integration/webhook-events/${eventId}`),

    getCustomDomains: () => client.get('/account/custom-domains'),
    createCustomDomain: (data: CreateCustomDomain) => client.post('/account/custom-domains', data),
    updateCustomDomain: (data: UpdateCustomDomain) => client.patch(`/account/custom-domains`, data),
    deleteCustomDomain: () => client.delete('/account/custom-domains'),
    getBrandsTitles: () => client.get('/account/brand-titles'),
    getBrandSlugs: (brandId: string) => client.get(`/brands/${brandId}/campaign-slugs`),

    // HUBSPOT INTEGRATION API
    setBrandHubSpotConnection: (brandId: number, data: T.CreateHubSpotConnectionData) => client.post(`account/brands/${brandId}/hubspot`, data),
    confirmBrandHubSpotConnection: (data: T.UpdateHubSpotConnectionData) => client.put(`account/hubspot/confirm-connection`, data),
    deleteBrandHubSpotConnection: (brandId: number, id: number) => client.delete(`account/brands/${brandId}/hubspot/${id}`),
    getAccountBrandsHubSpotConnections: () => client.get('account/brands-hubspot-connections'),
    adminGetAccountBrandsHubSpotConnections: (accountId: number) => client.get(`account/brands-hubspot-connections/${accountId}`),
    testHubSpotSubmitContact: (id: number, brandId: number, data: T.TestHubSpotContactCreationData) => client.post(`account/brands/${brandId}/hubspot/${id}`, data),
    getBrandHubSpotClientsFields: (id: number, brandId: number) => client.get(`account/brands/${brandId}/hubspot/${id}`),
    adminGetBrandHubSpotClientsFields: (id: number) => client.get(`admin/brands/hubspot/${id}`),

    // MAILCHIMP INTEGRATION API
    createBrandMailChimpConnection: (brandId: number, data: T.CreateMailChimpConnectionData) => client.post(`account/brands/${brandId}/mailchimp`, data),
    confirmBrandMailChimpConnection: (id: number, data: T.UpdateMailChimpConnectionData) => client.put(`account/mailchimp/${id}`, data),
    deleteBrandMailChimpConnection: (brandId: number, id: number) => client.delete(`account/brands/${brandId}/mailchimp/${id}`),
    getAccountBrandsMailChimpConnections: () => client.get('account/brands-mailchimp-connections'),
    adminGetAccountBrandsMailChimpConnections: (accountId: number) => client.get(`account/brands-mailchimp-connections/${accountId}`),
    testMailChimpSubmitContact: (id: number, brandId: number, data: T.TestMailChimpContactCreationData) => client.post(`account/brands/${brandId}/mailchimp/${id}`, data),
    getBrandMailChimpAudiences: (id: number, brandId: number) => client.get(`account/brands/${brandId}/mailchimp/${id}`),
    adminGetBrandMailChimpAudiences: (id: number) => client.get(`admin/brands/mailchimp/${id}`),
    getBrandMailChimpAudiencesFields: (id: number, query: string, brandId: number) => client.get(`account/brands/${brandId}/mailchimp/${id}/fields?${query}`),
    adminGetBrandMailChimpAudiencesFields: (id: number, query: string) => client.get(`admin/brands/mailchimp/${id}/fields?${query}`),

    // SALESFORCE INTEGRATION API
    getSalesforceConnections: () => client.get('brands-salesforce-connections'),
    createSalesforceConnection: (brandId: number, data: T.CreateHubSpotConnectionData) => client.post(`accounts/brands/${brandId}/salesforce`, data),
    deleteSalesforceConnection: (brandId: number, id: number) => client.delete(`account/brands/${brandId}/salesforce/${id}`),
    testSalesforceConnection: (brandId: number, id: number, data: T.TestSalesForceConnectionData) => client.post(`account/brands/${brandId}/salesforce/${id}`, data),
    getSalesforceFields: (brandId: number, id: number) => client.get(`brands/${brandId}/salesforce/${id}`),

    // HUBSPOT ADACT PRIVATE APP API
    createHubSpotTicket: (data: T.HubSpotTicketData) => client.post('account/hubspot-ticket', data),

    // SYSTEM NOTIFICATIONS API
    getSystemNotificationsList: (page: number, limit: number, query: string) => client.get(`/system-notifications?offset=${(page - 1) * limit}&limit=${limit}&${query}`),
    getOneSystemNotification: (id: number) => client.get(`/system-notifications/${id}`),
    createSystemNotification: (data: SystemNotificationPayloadType) => client.post(`/system-notifications`, data),
    updateSystemNotification: (id: number, data: SystemNotificationPayloadType) => client.put(`/system-notifications/${id}`, data),
    deleteSystemNotification: (id: number) => client.delete(`/system-notifications/${id}`),
    userCloseSystemNotification: (id: number) => client.post(`/system-notifications/${id}/close`),


    // MEDIA FILES
    getMediaFilesList: (page: number, limit: number, query: string) => client.get(`/media-files?page=${page}&limit=${limit}&${query}`),
    createMediaFile: (data: FormData, config?: AxiosRequestConfig) => client.post(`/media-files`, data, {headers: {'Content-Type': 'multipart/form-data'}, ...(config || {})}),
    updateMediaFiles: (data: UpdateMediaFilePayloadType) => client.post(`/media-files/update-categories`, data),
    deleteMediaFile: (data: DeleteMediaFilePayloadType) => client.delete(`/media-files/bulk-delete`, { data }),

    //TOKENS
    unlockCampaignByTokens: (id: number, payload: CampaignUnlockByTokenPayload) => client.post(`campaigns/${id}/unlock-by-tokens`, payload),

    adminGetTokensList: () => client.get(`token-campaign-unlocking`),
    adminCreateToken: (payload: CreateCampaignUnlockByTokenPayloadType) => client.post(`token-campaign-unlocking`, payload),
    adminUpdateToken: (id: number, payload: CreateCampaignUnlockByTokenPayloadType) => client.put(`token-campaign-unlocking/${id}`, payload),
    adminDeleteToken: (id: number) => client.delete(`token-campaign-unlocking/${id}`),
    getTokenUsageHistory: (payload: PaginationPayload) => client.get(`token-usage-histories`, { data: payload }),

    tokenPurchaseList: () => client.get(`token-purchases`),
    adminCreateTokenPurchase: (payload: FormData) => client.post(`token-purchases`, payload, {headers: {'Content-Type': 'multipart/form-data'}}),
    adminUpdateTokenPurchase: (id: number, payload: FormData) => client.put(`token-purchases/${id}`, payload, {headers: {'Content-Type': 'multipart/form-data'}}),
    adminDeleteTokenPurchase: (id: number) => client.delete(`token-purchases/${id}`),
    getTokenPurchaseHistory: (payload: PaginationPayload) => client.get(`token-purchase-histories`, { data: payload }),

    //PAYMENTS
    generatePortalSessionUrl: (payload: PortalSessionPayload) => client.post('payments/generate-portal-session-url', payload),
    buyTokensUrl: (payload: BuyTokensType) => client.post('payments/buy-tokens', payload),
    generateSubscriptionCheckoutUrl: (payload: CheckoutSessionPayload) => client.post('payments/subscription-checkout-session', payload),
    setInvoiceCustomFields: (payload: InvoiceCustomFieldsPayload) => client.post('payments/invoice-custom-fields', payload),
    cancelSubscription: (payload: CancelSubscriptionPayload) => client.delete('payments/cancel-subscription', { data: payload }),

    //SUBSCRIPTION
    createSubscription: (payload: CreateSubscriptionPayload) => client.post('account/payments/create-subscription', payload),
    updateSubscription: (payload: UpdateSubscriptionPayload) => client.post('payments/update-subscription', payload),
    updateSubscriptionByAdmin: (payload: UpdateSubscriptionByAdminPayload) => client.post('admin/payments/update-subscription', payload),
    createEnterPriseSubscription: (payload: CreateEnterpriseSubscriptionPayload) => client.post('payments/create-enterprise-subscription', payload),
    getPlans: () => client.get('/tier-plans'),
  }
};

export default API;
