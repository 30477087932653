import useApi from '../useApi';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  SystemNotification,
  SystemNotificationMessage,
  SystemNotificationSettingsType,
  UserSystemNotification
} from '../../utils/type';
import moment from 'moment-timezone';
import { updateUser } from '../../store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../store/auth/selectors';
import { userIsAdmin } from '../../utils/helpers/isAdmin';
import { CommonStoreEnum } from '../../store/common/types';
import { commonSelector } from '../../store/common/selectors';
import { setCommonField } from '../../store/common';

type SystemNotifications = {
  notificationsToShow: SystemNotificationMessage[];
  onCloseNotification: (id: number) => Promise<void>;
}

const useUserSystemNotifications = ():SystemNotifications => {
  const api = useApi();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [ shownNotifications, setShownNotifications ] = useState<SystemNotificationMessage[]>([]);
  const userSystemNotifications: UserSystemNotification[] | undefined = useMemo(() => user.userSystemNotifications, [user.userSystemNotifications]);
  const intervalRef = useRef<any>();
  const isAdmin = userIsAdmin(user.role);

  const systemNotificationPreview: SystemNotification | null = useSelector(commonSelector(CommonStoreEnum.SYSTEM_NOTIFICATION_PREVIEW)) as SystemNotification | null;

  const setActiveNotifications = () => {
    const newSystemNotificationMessages: SystemNotificationMessage[] = [];
    const currenSystemNotifications: number[] = shownNotifications.map((item: SystemNotificationMessage) => item.id);

    userSystemNotifications
      .filter((item: UserSystemNotification) => !currenSystemNotifications.includes(item.systemNotificationId))
      .forEach((item: UserSystemNotification) => {
        if (
          !item.systemNotification.active ||
          (item.systemNotification.active_from && moment(item.systemNotification.active_from).isAfter(moment().utc())) ||
          (item.systemNotification.active_to && moment(item.systemNotification.active_to).isBefore(moment().utc()))
        ) {
          return;
        }

        let intervalPassed = false;
        if (item.last_shown_date && item.showing_interval) {
          if (moment().subtract(item.showing_interval, item.showing_interval_type).isAfter(moment(item.last_shown_date))) {
            intervalPassed = true;
          }
        }

        if (!item.last_shown_date || (item.show_count > item.shown_count && intervalPassed)) {
          const settings: SystemNotificationSettingsType = item.systemNotification.settings;
          newSystemNotificationMessages.push({
            id: item.systemNotification.id,
            text: item.systemNotification.text,
            image: item.systemNotification.image,
            type: item.systemNotification.type,
            status: item.systemNotification.status,
            link: settings.link,
            buttonText: settings.buttonText,
          });
        }
      });

    if (newSystemNotificationMessages.length) {
      setShownNotifications((prev) => [ ...prev, ...newSystemNotificationMessages ]);
    }
  };

  useEffect(() => {
    if (userSystemNotifications?.length && !isAdmin && userSystemNotifications?.length !== shownNotifications.length) {
      setActiveNotifications();
      intervalRef.current = setInterval(() => setActiveNotifications(), 60000);

      return () => {
        if (intervalRef.current) clearInterval(intervalRef.current)
      };
    }
  },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSystemNotifications?.length, shownNotifications.length]);

  useEffect(() => {
    if (!user.id) {
      setShownNotifications([]);
    }
  }, [user]);

  const onCloseNotification = async (id: number) => {
    if (isAdmin) {
      dispatch(setCommonField(null, CommonStoreEnum.SYSTEM_NOTIFICATION_PREVIEW));
      return;
    }

    if (!user.id || !id) return;
    try {
      if (intervalRef.current) clearInterval(intervalRef.current);

      setShownNotifications((prev: SystemNotificationMessage[]) => prev.filter((item) => item.id !== id));

      dispatch(updateUser({
        ...user,
        userSystemNotifications: user.userSystemNotifications.map((item: UserSystemNotification) => {
          if (item.systemNotificationId === id) {
            item.shown_count = item.shown_count + 1;
            item.last_shown_date = moment().toISOString();
          }
          return item;
        })
      }));

      await api.userCloseSystemNotification(id);
    } catch (e) {
      console.log(e);
    }
  }

  const notificationsToShow = [
    ...shownNotifications,
    ...(systemNotificationPreview ? [{
      id: systemNotificationPreview.id,
      text: systemNotificationPreview.text,
      image: systemNotificationPreview.image,
      type: systemNotificationPreview.type,
      status: systemNotificationPreview.status,
      link: systemNotificationPreview.settings.link,
      buttonText: systemNotificationPreview.settings.buttonText,
    }] : []),
  ];

  return {
    notificationsToShow,
    onCloseNotification,
  };
};

export default useUserSystemNotifications;
