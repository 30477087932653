import React, { Fragment } from 'react';
import addyConfirm from '../../confirm';
import Typo from '../../typo';
import styles from './upload.module.css';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { message } from 'antd';
import { NativeTypes } from 'react-dnd-html5-backend';

type Props = {
  type: string;
  setFile: (file: File) => void;
  accept: string;
}
const useFileSelect = (props: Props) => {
  const { type, setFile, accept } = props;

  const fileTypesSizeMapping: Record<string, number> = {
    gameBackground: 1024 * 1024 * 5,
    gameSettings: 1024 * 1024 * 1.5,
    audio: 1024 * 1024 * 5,
    favicon: 1024 * 100,
  };

  const onSelectFile = (file: File) => {
    if (file.size > fileTypesSizeMapping[type]) {
      addyConfirm({
        title: 'Hmm...',
        content: (
          <Fragment>
            <Typo type="p" bold className={styles.modalConfirmText}>{`Uploaded file size is higher than ${fileTypesSizeMapping[type] / 1024}KB`}</Typo>
            <Typo type="p" className={styles.modalConfirmText}>Unfortunately this file can not be uploaded</Typo>
          </Fragment>
        ),
      });
      return;
    }

    setFile(file);
  };

  const onChangeInput = (event: any) => {
    const file = event.target.files[0];
    onSelectFile(file);
  };

  const handleFileDrop = (monitor: DropTargetMonitor) => {
    if (monitor) {
      const file = monitor.getItem().files[0];
      const [tail] = file.name.split('.').reverse();
      if (accept.includes(tail.toLowerCase())) {
        onSelectFile(file);
      } else {
        message.error('File is unsupported');
      }
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFileDrop(monitor);
    },
    canDrop(item, monitor) {
      const files = monitor.getItem().files;
      return files?.length < 2;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return {
    onChangeInput,
    canDrop,
    isOver,
    drop,
    onSelectFile,
  }
}

export default useFileSelect;
