import { useCallback, useEffect, useState } from 'react';
import useCampaign from '../../../domain/useCampaign';
import { cloneDeep, isEqual, set } from 'lodash';
import { useConfigHelper } from '../useConfigHelper';
import { FlattenedPath, LandingPageScreenElement } from '../../type';
import urlToHttpsUrl from '../../urlToHttps';

type Props<T> = {
  element: LandingPageScreenElement;
  adapter?: (data: T) => T;
  linkFields?: FlattenedPath<T>[];
}

export const useLandingPageElementConfig = <T, >(props: Props<T>) => {
  const { adapter, element, linkFields } = props;

  const { update, campaign } = useCampaign();
  const { setFieldValues } = useConfigHelper();
  const initialConfig = JSON.parse(element.element_params);

  const [ configs, setConfigs ] = useState<T>(JSON.parse(element.element_params));
  const [ dirty, setDirty ] = useState<boolean>(false);

  useEffect(() => {
    setConfigs(JSON.parse(element.element_params));
    setDirty(false);
  }, [element.element_params]);

  const updateLinkField = (value: string) => urlToHttpsUrl(value);

  const updateConfigField = useCallback((data: Record<string, any>) => {
    const newItem: T = cloneDeep(configs);
    Object.entries(data).forEach(([key, value]: [string, any]) => {
      set(newItem as Record<string, any>, key, value);
    });
    setConfigs(newItem);
    setDirty(true);
  }, [configs]);

  const updateConfigs = () => {
    let newConfig = {
      ...initialConfig,
      ...configs,
    };

    if (adapter) {
      newConfig = adapter(newConfig);
    }

    if (linkFields) {
      newConfig = setFieldValues(newConfig, linkFields as unknown as string[], updateLinkField);
    }

    if (!isEqual(initialConfig, newConfig)) {
      update(campaign.id, {
        landingPageScreenElements: campaign.landingPageScreenElements.map((item: LandingPageScreenElement) => (
          item.id === element.id ? { ...item, element_params: JSON.stringify(newConfig) } : item
        ))
      });
    }
  };

  useEffect(() => {
    if (dirty) {
      updateConfigs();
    }
  },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(configs), dirty]);


  return {
    configs,
    updateConfigField,
  }
}
