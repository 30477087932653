import React, { FunctionComponent, ReactElement } from 'react';
import cx from 'classnames';
import Typo from '../../typo';
import styles from './field.module.css';

type Props = {
  children: ReactElement | ReactElement[];
  label?: string;
  className?: string;
  disabled?: boolean;
  withoutBorder?: boolean;
  errors?: string[];
  newForm?: boolean;
}

const Field:FunctionComponent<Props> = ({ className, label, withoutBorder, children, disabled, errors, newForm}) => {
  return (
    <div className={cx(className, styles.wrapper, { [styles.withoutBorder]: withoutBorder })}>
      <div className={cx(styles.inputWrapper, { [styles.newFormWrapper]: newForm })}>
        {label ? <Typo type="p" className={cx(styles.label, { [styles.disabled]: disabled })}>{label}</Typo> : null}
        {children}
      </div>
      {errors
        ?
        <div className={cx(styles.errors, { [styles.newForm]: newForm })}>
          {errors.map((error: string, idx: number) => <Typo type="p" key={idx} className={styles.error}>{error}</Typo>)}
        </div>
        : null
      }
    </div>
  );
};

export default Field;
