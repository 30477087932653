import { brandFontAdapter, brandFontsAdapter, fontsAdapter, uiStoreAdapter } from './adapters';
import { BrandFont } from './types';
import {
  addBrandFont,
  decreaseSpinner,
  hideRestrictionModal,
  hideSidebarAction,
  increaseSpinner,
  maximizeSidebarAction,
  minimizeSidebarAction,
  removeBrandFont,
  setBrandsFonts,
  setDebugState,
  setDisableBodyScroll,
  setDisabledSidebar,
  setFonts,
  setFullPreviewFalse,
  setFullPreviewTrue,
  setRestrictionModalText,
  setUIField,
  showRestrictionModal,
  showSidebarAction,
  updateAdactTour
} from './actions';
import { Action } from '../common';

const initialStore = uiStoreAdapter();

const reducer = (state = initialStore, action: Action) => {
  switch(action.type) {
    case setFonts.type: {
      return state.set('fonts', fontsAdapter(action.payload));
    }
    case showSidebarAction.type: {
      return state.set('sidebar', true);
    }
    case hideSidebarAction.type: {
      return state.set('sidebar', false);
    }
    case maximizeSidebarAction.type: {
      return state.set('sidebarType', 'full');
    }
    case setFullPreviewTrue.type: {
      return state.set('fullPreview', true);
    }
    case setFullPreviewFalse.type: {
      return state.set('fullPreview', false);
    }
    case minimizeSidebarAction.type: {
      return state.set('sidebarType', 'minimize');
    }
    case setDisabledSidebar.type: {
      return state.set('disableSidebar', action.payload);
    }

    case increaseSpinner.type: {
      return state.set('spinner', state.spinner + 1);
    }

    case setDebugState.type: {
      return state.set('debugState', action.payload);
    }

    case decreaseSpinner.type: {
      return state.set('spinner', state.spinner - 1);
    }

    case setBrandsFonts.type: {
      return state.set('brandFonts', brandFontsAdapter(action.payload));
    }

    case showRestrictionModal.type: {
      return state.set('restrictionModalShow', true);
    }

    case hideRestrictionModal.type: {
      return state.set('restrictionModalShow', false);
    }

    case setRestrictionModalText.type: {
      return state.set('restrictionModalText', action.payload);
    }

    case addBrandFont.type: {
      //@ts-ignore
      return state.update('brandFonts', brandFonts => brandFonts.concat([brandFontAdapter(action.payload)]));
    }

    case removeBrandFont.type: {
      return state.update('brandFonts', brandFonts => brandFonts.filter((font: BrandFont) => font.id !== action.payload));
    }

    case updateAdactTour.type: {
      return state.update('adactTour', adactTour => ({ ...adactTour, ...action.payload}));
    }

    case setDisableBodyScroll.type: {
      return state.set('disableBodyScroll', action.payload);
    }

    case setUIField.type: {
      return state.set(action.path, action.payload);
    }

    default:
      return state;
  }
};

export default reducer;
