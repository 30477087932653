import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Editor } from '@tinymce/tinymce-react';
import { BrandFont } from '../../../store/ui/types';
import useFonts from '../../../domain/useFonts';
import styles from './rte.module.css';
import InputType from '../../../utils/type/input.type';
import RTEShortCodes from './shortCodes';
import { debounce } from 'lodash';

type Props = InputType & {
  initialValue: string;
  value: string;
  className?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  defaultStyles?: DefaultFontStyle[];
  disabled?: boolean;
  height?: number;
  shortCodes?: string[];
}

type DefaultFontStyle = {
  type: string;
  color: string;
  font: string;
  fontSize: number;
}

const WhiteRTE: FunctionComponent<Props> = ({initialValue, className, defaultStyles, onChange, onBlur, value, disabled, name, height = 500, changeConfigField, shortCodes }) => {
  const { rteFonts, brandFonts, rteFontSizes } = useFonts();
  const editorRef = useRef<any>(null);
  const [ rteValue, setRteValue ] = useState<string>(initialValue);
  const [ dirty, setDirty ] = useState<boolean>(false);

  useEffect(() => {
    if (value !== rteValue) setRteValue(value);
  }, [value]);

  const brandFontsCss = useMemo(() => brandFonts.map((font: BrandFont) => `
    @font-face {
       font-family: "${font.title}";
       src: url("${font.url}");
      }
    `).join('\n')
  , [brandFonts]);

  const defaultFontStyles = useMemo(() => defaultStyles?.map((text: DefaultFontStyle) => `
    ${text.type} {
      font-family: ${text.font};
      font-size: ${text.fontSize}px;
      color: ${text.color};
    }
  `).join('\n'), [defaultStyles]);

  const css = useMemo(() => [brandFontsCss, defaultFontStyles].join('\n'), [brandFontsCss, defaultFontStyles]);

  const inValidElements = useMemo(() =>
    '@[onclick|ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove|onmouseout'+
    '|onmouseover|onmouseup], script'
  , []);

  const changeValue = (value: string) => {
    setDirty(true);
    if (onChange) onChange(value);
    setRteValue(value);
  }

  const debounceUpdate = debounce(() => {
    if (changeConfigField && dirty) {
      changeConfigField({ [name]: rteValue });
    }
  }, 500);

  useEffect(() => {
      debounceUpdate();
      return () => {
        debounceUpdate.cancel();
      }
    },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [rteValue]);

  return (
    <div cypress-id={`${name}-component`} className={cx(styles.rte, className, {[styles.shortCodes]: shortCodes?.length })}>
      <Editor
        initialValue={initialValue}
        value={rteValue}
        onBlur={onBlur}
        apiKey="0j3udvdhmlzkj9ivr9179crpq03s3i2t75ea06dxobe7ingn"
        onEditorChange={changeValue}
        disabled={disabled}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          fontsize_formats: rteFontSizes,
          menubar: false,
          font_formats: rteFonts,
          invalid_elements: inValidElements,
          body_class: styles.rte,
          height,
          resize: false,
          content_style: css,
          plugins: 'textcolor link code',
          toolbar_mode: 'wrap',
          toolbar: 'fontselect | bold italic | forecolor | backcolor | formatselect | fontsizeselect | lineheight | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
          link_title: false,
          default_link_target: '_blank',
          link_assume_external_targets: 'https'
        }}
      />
      { shortCodes && Boolean(shortCodes.length) && <RTEShortCodes shortCodes={shortCodes} editorRef={editorRef}/> }
    </div>
  );
};

export default WhiteRTE;
