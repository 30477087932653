import { createSelector } from 'reselect';
import { Store } from '../types';
import { AuthState } from './types';

export const authSelector = (store: Store) => store.auth;
export const userSelector = createSelector(authSelector, (auth: AuthState) => auth.user.asMutable({deep: true}));
export const tokenSelector = createSelector(authSelector, (auth: AuthState) => auth.token);
export const accountSelector = createSelector(authSelector, (auth: AuthState) => auth.user.account.asMutable({deep: true}));
export const activeBrandIdSelector = createSelector(authSelector, (auth: AuthState) => Number(auth.activeBrandId));
export const activeSettingsBrandIdSelector = createSelector(authSelector, (auth: AuthState) => Number(auth.activeSettingsBrandId));
export const activeBrandSelector = createSelector(authSelector, (auth: AuthState) => auth.activeBrand.asMutable({ deep: true }));
export const accountTierSettingsSelector = createSelector(authSelector, (auth: AuthState) => auth.user.account.tierSettings.asMutable({ deep: true }));
export const brandsForFilterSelector = createSelector(authSelector, (auth: AuthState) => auth.user.brandsForFilter.asMutable({ deep: true }));
export const brandsAccountSelector = createSelector(authSelector, (auth: AuthState) => auth.brands.asMutable({ deep: true }));
export const invitationsSelector = createSelector(authSelector, (auth: AuthState) => auth.invitations.asMutable({ deep: true }));
export const teamMembersSelector = createSelector(authSelector, (auth: AuthState) => auth.teamMembers.asMutable({ deep: true }));
export const campaignTemplatesSelector = createSelector(authSelector, (auth: AuthState) => auth.campaignTemplates.asMutable({ deep: true }));
export const activeSettingsBrandSelector = createSelector(authSelector, (auth: AuthState) => auth.activeSettingsBrand?.asMutable({ deep: true }));
export const brandEmailsSelector = createSelector(authSelector, (auth: AuthState) => auth.brandEmails.asMutable({ deep: true }));
export const completedAccountSetup = createSelector(authSelector, (auth: AuthState) => auth.completedAccountSetup);
