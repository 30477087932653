import { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import WebFont from 'webfontloader';
import { addBrandFont, removeBrandFont, setBrandsFonts, setFonts } from '../store/ui/actions';
import { BrandFont, Font } from '../store/ui/types';
import { brandFontsSelector, fontsSelector } from '../store/ui/selectors';
import {
  activeBrandIdSelector,
  activeBrandSelector,
  activeSettingsBrandSelector,
  userSelector
} from '../store/auth/selectors';
import useApi from './useApi';
import * as T from "./requestTypes";
import { notification } from "antd";
import { campaignSelector } from '../store/campaign/selectors';
import { uniq } from 'lodash';

type iUseFonts = {
  fonts: {
    google: Font[];
    brand: Font[];
  };
  googleFonts: Font[];
  brandFonts: BrandFont[];
  fontSizes: Array<{label: string; value: number}>
  getFonts: () => void;
  rteFonts: string;
  rteFontSizes: string;
  busy: boolean;
  removeFont: (id: number) => void;
  createFont: (data: BrandFont) => void;
  newRemoveFont: (brandId: number, id: number) => void;
  newCreateFont: (brandId: number, data: T.CreateFontData, cb: (newFont: any) => void) => void;
  getBrandFonts: (id?: number) => void;
  allFonts: Font[];
}

const useFonts = (settings?: boolean):iUseFonts => {
  const dispatch = useDispatch();
  const fonts = useSelector(fontsSelector);
  const brandId = useSelector(activeBrandIdSelector);
  const activeBrand = useSelector(activeBrandSelector);
  const settingsBrand = useSelector(activeSettingsBrandSelector);
  const user = useSelector(userSelector);
  const brandFonts = useSelector(brandFontsSelector);
  const api = useApi();
  const [ busy, onChangeBusy ] = useState<boolean>(false);
  const isAdmin = user.role === 'admin' || user.role === 'super_admin';

  const brand = settings ? settingsBrand : activeBrand;
  const campaign = useSelector(campaignSelector);

  useEffect(() => {
    brandFonts.forEach((font: BrandFont) => {
      //@ts-ignore
      const loadFunction = new window.FontFace(font.title, `url(${font.url})`);
      loadFunction.load().then((loaded_face: any) => {
        //@ts-ignore
        document?.fonts.add(loaded_face);
      });
    })
  }, [brandFonts]);

  const brandGoogleFonts = useMemo(() => uniq([ ...brand.fonts, ...(campaign.brand.fonts || [])]), [brand.fonts, campaign.brand.fonts]);

  useEffect(() => {
    const fonts = uniq(brandGoogleFonts);
    if (fonts.length > 0) {
      WebFont.load({
        google: {
          families: fonts
        }
      });
    }
  }, [brandGoogleFonts]);

  useEffect(() => {
    if (fonts.length < 1) getFonts();
  }, [fonts]);

  const fontSizes = Array.from({ length: 67 }).map((_item: any, idx: number) => ({
    value: idx + 6,
    label: `${idx + 6}px`
  }));

  const rteFontSizes = Array.from({ length: 67 }).map((_item: any, idx: number) => `${idx + 6}px`).join(' ');

  const getBrandFonts = useCallback((id?: number ) => {
    const func = isAdmin ? api.getAdminBrandFonts : api.getBrandFonts;
    if (id) {
      func(id)
        .then((response: AxiosResponse) => {
          dispatch(setBrandsFonts(response.data));
        });
      return;
    }
    if (brandId) {
      func(brandId)
        .then((response: AxiosResponse) => {
          dispatch(setBrandsFonts(response.data));
        });
      return;
    }
  }, [brandId]);

  const getFonts = () => {
    if (fonts.length > 0) return;
    axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
      .then((response: AxiosResponse) => {
        dispatch(setFonts(response.data.items));
      });
  };

  const fontsList = {
    brand: brandFonts.map((font: BrandFont) => ({ label: font.title, value: font.title })),
    google: brandGoogleFonts.map((font: string) => ({ label: font, value: font }))
  };


  const createFont = useCallback((data: BrandFont) => {
    api.createBrandFont(brandId, {
      ...data,
      title: `${data.title}_custom`
    })
      .then((response: AxiosResponse) => {
        dispatch(addBrandFont(response.data));
      })
  }, [brandId]);

  const removeFont = (id: number) => {
    api.removeBrandFont(brandId, id)
      .then(() => {
        dispatch(removeBrandFont(id));
      });
  };


  const newCreateFont = useCallback((brandId: number, data: T.CreateFontData, cb: (newFont: any) => void) => {
    onChangeBusy(true);
    api.createBrandFont(brandId, {
      ...data,
      title: `${data.title}_custom`
    })
      .then((response: AxiosResponse) => {
        onChangeBusy(false);
        cb(response.data);
        dispatch(addBrandFont(response.data));
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 400) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message
          });
        }
      });
  }, [brandId]);

  const newRemoveFont = (brandId: number, id: number) => {
    api.removeBrandFont(brandId, id)
      .then(() => {
        dispatch(removeBrandFont(id));
      });
  };

  const defaultRTEFonts = "Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;";
  const rteGoogleFonts = brandGoogleFonts.map((font: string) => `${font}=${font}`).join(';');
  const rteBrandFonts = brandFonts.map((item: BrandFont) => `${item.title.replace(/_custom/g, '')}=${item.title}`).join(';');
  const rteFonts = [rteBrandFonts, rteGoogleFonts, defaultRTEFonts].join(';');
  const allFonts = [ ...fontsList.brand, ...fontsList.google ];

  return {
    fonts: fontsList,
    getBrandFonts,
    createFont,
    rteFonts,
    rteFontSizes,
    googleFonts: fonts,
    removeFont,
    brandFonts,
    busy,
    newCreateFont,
    newRemoveFont,
    fontSizes,
    getFonts,
    allFonts,
  }
};

export default useFonts;
