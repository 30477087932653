import { ImmutableObject } from 'seamless-immutable';
import {
  CampaignTemplate,
  User,
  BrandInList,
  BrandEmail,
  Brand,
  UserInvitation
} from '../../utils/type';
import { Pagination } from '../types';

export type Action = {
  type: string;
  payload: any;
  path?: AuthField;
}

export enum AuthField {
  COMPLETED_ACCOUNT_SETUP = 'completedAccountSetup',
}

export type AuthState = ImmutableObject<{
  token: string | null;
  activeBrandId: string | null;
  activeSettingsBrandId: string | null;
  refresh_token: string | null;
  refresh_token_expired_at: string | null;
  user: User;
  invoices: any;
  teamMembers: User[];
  brands: BrandInList[];
  brandEmails: BrandEmail[];
  invitations: Pagination<UserInvitation>;
  activeBrand: Brand;
  activeSettingsBrand: Brand;
  campaignTemplates: CampaignTemplate[];
  authorized: boolean;
  [AuthField.COMPLETED_ACCOUNT_SETUP]: boolean;
}>
