import React, { Fragment, FunctionComponent, useCallback, useMemo, useState } from 'react';
import Icon from '../../icon';
import cx from 'classnames';
import styles from './upload.module.css';
import InputType from '../../../utils/type/input.type';
import ModalBody from './modalBody';
import OldUploadModal from './oldUploadModal';
import useMediaFilesCategories from '../../../utils/hooks/useMediaFilesCategories';

export type Props = InputType & {
  className?: string;
  value?: string;
  type: 'gameBackground' | 'gameSettings' | 'favicon' | 'newsPost' | 'gamePreview';
  refProp?: any;
  big?: any;
  disabled?: boolean;
  accept?: string;
  maxWidth?: number;
  maxHeight?: number;
  removable?: boolean;
  disableRemove?: boolean;
  onRemoveKeepValue?: boolean;
  onRemove?: () => void;
  onChange?: (value: any) => void;
  onChangeUrl?: (value: any) => void;
  tourId?: string;
  mockUpload?: boolean;
  phaser?: boolean;
  categories?: string[];
  multiUpload?: boolean;
  old?: boolean;
}

const WhiteUpload:FunctionComponent<Props> = (props) => {
  const { name, big, onRemove, disabled, removable, onRemoveKeepValue, disableRemove, value, onChange, className, tourId, changeConfigField, old } = props;
  const [ showModal, setShowModal ] = useState<boolean>(false);
  const currentCategories = useMediaFilesCategories(props.categories || []);

  const onRemoveImage = useCallback(() => {
    if (disableRemove) return;

    if (!onRemoveKeepValue) {
      if (onChange) onChange('');
      if (changeConfigField) changeConfigField({ [name]: '' });
    }
    if (onRemove) onRemove();
  }, [disableRemove, onRemoveKeepValue, onRemove, onChange, changeConfigField, name]);

  const removableImage = useMemo(() => {
    const change = (
      <button className={cx(styles.editableBtn, {[styles.small]: !big})} onClick={() => setShowModal(true)} cypress-id={`${name}-component-update`} name={`${name}-component-update`}>
        <Icon icon="image_uploader" className={styles.editableIcon}/>
      </button>
    );
    const remove = (
      <button className={cx(styles.editableBtn, {[styles.small]: !big})} disabled={disableRemove} cypress-id={`${name}-component-remove`} onClick={onRemoveImage} name={`${name}-component-remove`}>
        <Icon icon="trash" className={styles.editableIcon}/>
      </button>
    );

    const bothActions = <>{change}{remove}</>;

    return (
      <label className={cx(styles.uploader, styles.removableWrapper, className, { [styles.disabled]: disabled, [styles.both]: removable, [styles.big]: big })} cypress-id={`${name}-component-wrapper`}>
        {value
          ? <Fragment>
            <img className={styles.img} src={value} alt={name} draggable={false}/>
            <div className={styles.buttonsImageWrapper}>
              {removable ? bothActions : change}
            </div>
          </Fragment>
          : <Icon icon="blue_plus"/>
        }
      </label>
    );
  }, [big, name, disableRemove, onRemoveImage, className, disabled, removable, value]);

  const plainImage = (
    <label className={cx(styles.uploader, className, { [styles.empty]: !value, [styles.disabled]: disabled, [styles.big]: big })} cypress-id={`${name}-component-wrapper`} onClick={() => setShowModal(true)} >
      {value
        ? <img className={styles.img} src={value} alt={name} draggable={false}/>
        : <div className={styles.uploaderButtonBody}><Icon icon="blue_plus"/><div>Upload</div></div>}
    </label>
  );

  return (
    <Fragment>
      {!tourId ? (value ? removableImage : plainImage) : null}
      {
        old
          ? <OldUploadModal { ...props } show={showModal} setShowModal={setShowModal}/>
          : <ModalBody { ...props } categories={currentCategories} show={showModal} setShowModal={setShowModal}/>
      }
    </Fragment>
  );
};

export default WhiteUpload;
