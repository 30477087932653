import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import red from './red.svg';
import yellow from './yellow.svg';
import blue from './blue.svg';
import close from './close.svg';
import styles from './message.module.css';
import { SystemNotificationStatusTypeEnum } from '../../utils/enums';
import { SystemNotificationMessage } from '../../utils/type';

type MessageProps = {
  item: SystemNotificationMessage;
  onClose: (id: number) => void;
}

const SystemNotificationMessageElement:FunctionComponent<MessageProps> = ({ item, onClose }) => {
  const [ closing, onChangeClosing] = useState<boolean>(false);
  const debouncingClose = useMemo(() => debounce((id: number) => onClose(id), 200), [onClose]);

  const handleClose = useCallback((id: number) => {
    onChangeClosing(true);
    debouncingClose(id);
  }, [debouncingClose]);

  const colorMapping: Record<SystemNotificationStatusTypeEnum, string> = {
    [SystemNotificationStatusTypeEnum.DANGER]: 'red',
    [SystemNotificationStatusTypeEnum.WARN]: 'yellow',
    [SystemNotificationStatusTypeEnum.INFO]: 'blue',
  }

  const iconMapping: Record<SystemNotificationStatusTypeEnum, any> = {
    [SystemNotificationStatusTypeEnum.DANGER]: red,
    [SystemNotificationStatusTypeEnum.WARN]: yellow,
    [SystemNotificationStatusTypeEnum.INFO]: blue,
  }

  return (
    <div
      className={cx(styles.wrapper, styles[colorMapping[item.status]], {[styles.closing]: closing})}>
      <img src={iconMapping[item.status]} className={styles.addy} alt="info" draggable={false}/>
      { item.element ? item.element : <div className={styles.textWrapper} dangerouslySetInnerHTML={{ __html: item.text }}/> }
      <button onClick={() => handleClose(item.id)} className={styles.close}>
        <img src={close} alt="close" draggable={false}/>
      </button>
    </div>
  );
}

export default SystemNotificationMessageElement;
