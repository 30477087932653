import React, { FunctionComponent, ReactElement } from 'react';
import cx from 'classnames';
import Field from '../field';
import styles from './input.module.css';

type Option = {
  label: string | ReactElement;
  activeLabel?: string | ReactElement;
  value: string | number;
}

interface Props {
  className?: string;
  label?: string;
  name: string;
  options: Option[];
  refProp?: any;
  onChange: (value: any) => void;
  value: any;
  defaultOption?: any;
  withoutBorder?: boolean;
  removable?: boolean;
  disabled?: boolean;
  texts?: boolean;
  errors?: string[];
}

const RadioGroup:FunctionComponent<Props> = (props) => {
  const { className, label, errors, value, options, texts, onChange, disabled, withoutBorder, removable, defaultOption, name } = props;
  const onClick = (input: string | number) => {
    if (disabled) return;
    if (value === input && removable) {
      onChange(defaultOption);
      return;
    }
    onChange(input);
  }

  return (
    <Field className={className} label={label} errors={errors} withoutBorder={withoutBorder}>
      <div className={styles.radioWrapper}>
        {options.map((item: Option, idx: number) => (
          <button disabled={disabled} className={cx(styles.radio, {[styles.active]: value === item.value, [styles.textRadio]: texts})} onClick={() => onClick(item.value)} key={idx} cypress-id={`${name}-component-${idx}`}>
            {value === item.value && item.activeLabel ? item.activeLabel : item.label}
          </button>
        ))}
      </div>
    </Field>
  );
};

export default RadioGroup;
