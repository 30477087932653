import { useCallback, useEffect, useMemo } from 'react';
import { ParsedQuery } from 'query-string';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';
import useQuery, { queryToString } from '../../query';
import useCampaign from '../../../domain/useCampaign';

const useWordSearch = (
  setGameConfigs: (config: Record<string, any>) => void,
  gameConfigs: Record<string, any>,
  gameSettings: Record<string, any>,
) => {
  const query:ParsedQuery = useQuery();
  const location = useLocation();
  const history = useHistory();
  const { update, campaign } = useCampaign();

  const onWordSearchWordDrag = useCallback((words: Record<string, any>) => {
    setGameConfigs({ ...gameConfigs, questions: [{ ...gameConfigs.questions[0], words }]});
  }, [gameConfigs, setGameConfigs]);

  const onWordSearchWordDragEnd = useCallback(() => {
    update(campaign.id, { game_config: JSON.stringify(gameConfigs) });
    const newLocation = location.pathname + '?' + queryToString(Object.assign(query, { wordSearchIdx: undefined }));
    history.push(newLocation);
  }, [campaign.id, gameConfigs, history, location.pathname, query, update]);

  useEffect(() => {
    setGameConfigs(gameSettings);
  }, [gameSettings, setGameConfigs]);

  const activeWordIdx = useMemo(() => !isUndefined(query?.wordSearchIdx) ? Number(query?.wordSearchIdx) : undefined, [query.wordSearchIdx]);

  const setActiveWordIdx = useCallback((newIdx: number) => {
    const newLocation = location.pathname + '?' + queryToString(Object.assign(query, { wordSearchIdx: newIdx }));
    history.push(newLocation);
  }, [history, location.pathname, query]);

  const debugWords = useMemo(() => query.tab !== 'visuals', [query.tab]);

  return debugWords ? {
    setActiveWordIdx,
    onWordSearchWordDragEnd,
    onWordSearchWordDrag,
    debugWords,
    activeWordIdx,
  } : {}
}

export default useWordSearch;
