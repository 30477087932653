import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import Switch, { SwitchProps } from 'antd/lib/switch';
import styles from './toggler.module.css';
import Field from '../field';

interface Props extends SwitchProps {
  className?: string;
  name: string;
  onBlurCallback?: () => void;
  onBlur?: () => void;
  badGood?: boolean;
  label?: string;
  value?: boolean;
  bold?: boolean;
  refProp?: any;
}

const Toggler:FunctionComponent<Props> = ({ name, bold, refProp, label, badGood, onBlur, onBlurCallback, className, value, ...other}) => {
  return (
    <Field className={cx(className, styles.wrapper, {[styles.bad]: badGood && value, [styles.good]: badGood && !value})} label={label}>
      {/*//@ts-ignore*/}
      <Switch checked={value} ref={refProp} name={name} {...other} onBlur={onBlurCallback ? onBlurCallback : onBlur} cypress-id={`${name}-component`} />
    </Field>
  );
};

export default Toggler;
