type Action<T, C> = {
  type: string;
  payload?: T;
  path?: C;
  compareField?: string;
};

function makeActionDefault<T, C>(payload?: T, path?: C, compareField?: string) {
  return {
    payload,
    path,
    compareField,
  };
}

function makeActionCreator<T, C>(type: string, makeAction: (payload?: T, path?: C) => {}) {
  const actionCreator = (arg?: T, path?: C, compareField?: string): Action<T, C> => ({ ...makeAction(arg), path, compareField, type });
  return Object.assign(actionCreator, { type });
}

export function createAction<T, C>(
  base: string,
  makeAction: (payload?: T, path?: C, compareField?: string) => {} = makeActionDefault,
) {
  return makeActionCreator(base, makeAction);
}
