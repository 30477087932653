import { get, set } from 'lodash';

export const useConfigHelper = () => {

  const generateProcessedFields = (
      config: Record<string, unknown>,
      field: string,
      existingProcessedFiles: string[],
      processNonExistedFields = true,
  ): void => {
    const fieldValueFromConfig: any = get(config, field);

    if (processNonExistedFields) {
      if (Array.isArray(fieldValueFromConfig)) {
        for (let idx = 0; idx < fieldValueFromConfig.length; idx++) {
          existingProcessedFiles.push(`${field}[${idx}]`);
        }
      } else {
        existingProcessedFiles.push(`${field}`);
      }
    } else {
      if (Array.isArray(fieldValueFromConfig)) {
        if (fieldValueFromConfig.length) {
          for (let idx = 0; idx < fieldValueFromConfig.length; idx++) {
            existingProcessedFiles.push(`${field}[${idx}]`);
          }
        } else {
          existingProcessedFiles.push(`${field}`);
        }
      } else if (fieldValueFromConfig !== undefined) {
        existingProcessedFiles.push(`${field}`);
      }
    }
  }


  const generateArrayFields = (config: Record<string, unknown>, field: string, processNonExistedFields = true): string[] => {
    const fieldParts: string[] = field.split('.');
    let processedFields: string[] = [];

    for (let currentPart of fieldParts) {
      const isArrayFieldPart: boolean = currentPart.includes('[]');

      if (isArrayFieldPart) {
        currentPart = currentPart.replace('[]', '');
      }

      if (processedFields.length) {
        const currentProcessedFields: string[] = [];
        processedFields.forEach((field) => {
          const generatedField: string = [field, currentPart].join('.');
          generateProcessedFields(config, generatedField, currentProcessedFields, processNonExistedFields);
        });

        if (currentProcessedFields.length) {
          processedFields = currentProcessedFields;
        }
      } else {
        generateProcessedFields(config, currentPart, processedFields, processNonExistedFields);
      }
    }

    return processedFields;
  }

  const getFieldValues = (config: Record<string, any>, fileFields: string[]) => {
    const values: any[] = [];
    fileFields.forEach((currentField) => {
      let arrayFields: string[] = [currentField];
      const hasArray: boolean = currentField.includes('[]');

      if (hasArray) {
        arrayFields = generateArrayFields(config, currentField);
      }

      arrayFields.forEach((currentField) => {
        const currentFieldValue: any = get(config, currentField);
        values.push(currentFieldValue);
      });
    });

    return values;
  }

  const setFieldValues = (config: Record<string, any>, fileFields: string[], valueCb: (value: string) => string) => {
    fileFields.forEach((currentField) => {
      let arrayFields: string[] = [currentField];
      const hasArray: boolean = currentField.includes('[]');

      if (hasArray) {
        arrayFields = generateArrayFields(config, currentField);
      }

      arrayFields.forEach((currentField) => {
        const fieldValue = valueCb(get(config, currentField));
        set(config, currentField, fieldValue);
      });
    });

    return config;
  }

  return {
    generateArrayFields,
    getFieldValues,
    setFieldValues,
  }
}
