import { useCallback, useContext } from 'react';
import { cloneDeep, omit } from 'lodash';
import AdventCalendarContext from '../pages/campaign/show/game/components/adventcalendar/context';
import { ScreenTypes } from './enums';
import { Campaign, LandingPageScreenElement } from './type';

export type Screen = {
  key: 'classic' | 'before_game' | 'after_game' | 'only_game' | 'after_only_game' | 'before_and_after_game' | string;
  label: string;
  description: string;
  disabled: boolean;
  list: Partial<ScreenTypes>[];
}

export type ConnectedCampaign = {
  id: number;
  title: string;
}

interface UseAdventCalendarHelper {
  landingPageElementsHelper: (elements: LandingPageScreenElement[]) => NormalizedElements;
}

export type NormalizedElements = {
  elements: LandingPageScreenElement[];
  forceUpdate: boolean;
}

const useAdventCalendarHelper = (campaign: Campaign):UseAdventCalendarHelper => {
  const { activeDays, originDay } = useContext(AdventCalendarContext);

  const landingPageElementsHelper = useCallback((landingPageScreenElements: LandingPageScreenElement[]) => {
    let forceUpdate = false;
    if (!originDay || !activeDays.length) {
      return {
        elements: landingPageScreenElements,
        forceUpdate,
      };
    }

    if (campaign.landingPageScreenElements.length - 1 === landingPageScreenElements.length) {
      const elementsInNewConfig = landingPageScreenElements.map((item) => item.id);
      const deletedElement = campaign.landingPageScreenElements.find((item) => !elementsInNewConfig.includes(item.id));
      if (deletedElement) {
        deletedElement.adventCalendarDays = deletedElement.adventCalendarDays?.filter((item) => item !== originDay);
        landingPageScreenElements.push(deletedElement);
      }
    }

    const elementsToDelete: number[] = [];
    const elementsToAdd: LandingPageScreenElement[] = [];

    const elementsToChange: LandingPageScreenElement[] = [];
    const elementsToKeep: LandingPageScreenElement[] = [];
    landingPageScreenElements.forEach((element: LandingPageScreenElement) => {
      const hasSameDay = element.adventCalendarDays?.find((item) => activeDays.includes(item));
      if (hasSameDay && element.id) {
        elementsToChange.push(element);
      } else {
        if (!element.id) {
          element.adventCalendarDays = activeDays;
        }
        elementsToKeep.push(element);
      }
    });

    elementsToChange.forEach((element: LandingPageScreenElement) => {
      const ids = cloneDeep(element.adventCalendarDays)?.filter((item) => !activeDays.includes(item));
      if (!element.adventCalendarDays?.includes(originDay)) {
        if (!ids?.length) {
          elementsToDelete.push(element.id);
        } else {
          element.adventCalendarDays = ids;
        }
        return;
      } else {
        if (ids?.length) {
          const previousElement = campaign.landingPageScreenElements.find((item) => item.id === element.id);
          if (previousElement) {
            elementsToAdd.push(omit({
              ...previousElement,
              adventCalendarDays: ids,
            }, 'id') as LandingPageScreenElement);
          }
        } else if (element.id && !element.adventCalendarDays?.includes(originDay)) {
          elementsToDelete.push(element.id);
          return;
        }

        element.adventCalendarDays = activeDays;
      }
    });

    if (elementsToAdd.length) {
      forceUpdate = true;
    }

    return {
      forceUpdate,
      elements: [
        ...elementsToKeep,
        ...elementsToAdd,
        ...elementsToChange,
      ]?.filter((item: LandingPageScreenElement) => !elementsToDelete.includes(item.id)),
    };
  }, [activeDays, originDay, campaign]);


  return {
    landingPageElementsHelper,
  }
};

export default useAdventCalendarHelper;
