import React, { FunctionComponent, ReactNode, ReactText, useMemo } from 'react';
import cx from 'classnames';
import Typo from '../typo';
import { WhiteButton } from '../form';
import styles from './empty.module.css';

type Props = {
  title?: string;
  height?: number;
  white?: boolean;
  subtitle?: string | ReactNode;
  className?: string;
  actionText?: string | ReactText;
  onClick?: () => void;
}

const Empty:FunctionComponent<Props> = ({ title, height, actionText, onClick, white, subtitle, className }) => {
  const onlyTitle = useMemo(() => !subtitle && !actionText, [subtitle, actionText]);

  return (
    <div style={{ height }} className={cx(styles.wrapper, className, {[styles.white]: white, [styles.onlyTitle]: onlyTitle})}>
      <Typo type="h5" white={white} bold className={styles.title}>{title}</Typo>
      {subtitle ? <Typo type="h5" className={styles.subtitle}><span>{subtitle}</span></Typo> : null}
      {actionText ? <div className={styles.action}>
        <WhiteButton size="md" onClick={onClick} htmlType="button">{actionText}</WhiteButton>
      </div> : null}
    </div>
  )
};

export default Empty;
