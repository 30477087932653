import React, { Fragment, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { message } from 'antd';
import Modal from '../../modal';
import cx from 'classnames';
import { InputProps } from 'antd/lib/input';
import Typo from '../../typo';
import Button from '../whiteButton';
import styles from './upload.module.css';
import { PlusOutlined } from '@ant-design/icons/lib';

// @ts-ignore
interface Props extends InputProps {
  className?: string;
  name: string;
  value?: File;
  placeholder?: string;
  refProp?: any;
  disabled?: boolean;
  accept: string;
  onChange: (value: File) => void;
  btnType?: 'primary' | 'ghost';
  size?: 'small' | 'large';
  fileUrl: string | ArrayBuffer | null;
  image?: boolean;
  label?: string;
}

const BinaryUpload:FunctionComponent<Props> = ({ name, size, value, accept, disabled, refProp, onChange, placeholder, className, fileUrl, image, label }) => {
  const [ showModal, onChangeShowModal ] = useState<boolean>(false);
  const [ file, setFile ] = useState<File | undefined>(value);
  const [ url, onChangeUrl ] = useState<string | ArrayBuffer | null>(fileUrl);

  const onSelectFile = useCallback((file: File) => {
    setFile(file);
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => onChangeUrl(reader.result);
    }
  }, [setFile]);

  const onChangeInput = useCallback((event: any) => {
    const file = event.target.files[0];
    onSelectFile(file);
  }, [onSelectFile]);

  const handleFileDrop = useCallback(
    (monitor: DropTargetMonitor) => {
      if (monitor) {
        const file = monitor.getItem().files[0];
        const [tail] = file.name.split('.').reverse();
        if (accept.includes(tail.toLowerCase())) {
          onSelectFile(file);
        } else {
          message.error('File is unsupported');
        }
      }
    }, [onSelectFile]);

  const onCloseModal = useCallback(() => {
    onChangeShowModal(false);
  }, [onChangeShowModal]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFileDrop(monitor);
    },
    canDrop(item, monitor) {
      const files = monitor.getItem().files;
      return files?.length < 2;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const onUpload = useCallback(() => {
    if (file) {
      onChange(file);
      onChangeShowModal(false);
    }
  }, [file]);

  const body = useMemo(() => (
    <div className={cx(styles.dndArea, { [styles.canDrop]: canDrop || isOver })} ref={drop}>
      {file
        ?
        <Fragment>
          <div className={styles.iconFileWrapper}>
            <FontAwesomeIcon icon={faFileAlt}/>
          </div>
          <div className={styles.fileName}>{file?.name}</div>
          <Button className={styles.inputFileBtn} cypress-id={`${name}-component-update`}>
            <span>Choose another</span>
            <input className={styles.inputFile} type="file" accept={accept} onChange={onChangeInput}/>
          </Button>
        </Fragment>
        :
        <Fragment>
          <div className={styles.iconWrapper}>
            <FontAwesomeIcon icon={faCloudUploadAlt}/>
          </div>
          <Typo white type="p">Drag file to upload</Typo>
          <Typo white type="p">or</Typo>
          <Button className={styles.inputFileBtn} cypress-id={`${name}-component-browsefiles`}>
            <span>Browse files</span>
            <input className={styles.inputFile} type="file" accept={accept} onChange={onChangeInput}/>
          </Button>
        </Fragment>
      }
    </div>
  ), [file, canDrop, isOver, drop, accept])

  return (
    <Fragment>
      {
        image ?
          <label className={cx(styles.uploader, className, { [styles.empty]: !url, [styles.disabled]: disabled })} cypress-id={`${name}-component-show`} onClick={() => onChangeShowModal(true)}>
            { url
              ? <img className={styles.img}
                //@ts-ignore
                     src={url} alt={name} draggable={false}/>
              : <div className={styles.uploaderButtonBody}><PlusOutlined className={styles.uploaderButtonIcon} /> <div>Upload</div></div>}
          </label>
          :
          <Button className={cx(className, { [styles.disabled]: disabled })} onClick={() => onChangeShowModal(true)} >
            { label }
          </Button>

      }
      <Modal
        visible={showModal}
        title="File Upload"
        onCancel={onCloseModal}
      >
        <div className={styles.modalWrapper}>
          {body}
          <div className={styles.modalFooter}>
            <Button size="lg" type="ghost" onClick={onCloseModal}>Cancel</Button>
            <Button size="lg" onClick={onUpload} disabled={!file}>Confirm</Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default BinaryUpload;
