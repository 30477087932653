import { useCallback, useState } from 'react';
import { cloneDeep } from 'lodash';

export type ImageSize = {
  width: number;
  height: number;
  xy: number;
  yx: number;
}

type Props = {
  maxWidth?: number;
  maxHeight?: number;
  files: File[];
}

const defaultSizes = {
  width: 0,
  height: 0,
  xy: 1,
  yx: 1
};

const useImageSize = (props: Props) => {
  const { maxWidth, maxHeight, files } = props;

  const [ sizes, setSize ] = useState<ImageSize[]>(files.map(() => defaultSizes));

  const onInitializeImageSizes = useCallback((imageElement: any) => {
    let width = imageElement.naturalWidth;
    let height = imageElement.naturalHeight;
    if (maxWidth && maxHeight) {
      width = imageElement.naturalWidth > maxWidth ? maxWidth : imageElement.naturalWidth;
      height = imageElement.naturalHeight > maxHeight ? maxHeight : imageElement.naturalHeight;
    }

    return {
      height,
      width,
      xy: (imageElement.naturalHeight / imageElement.naturalWidth) || 1,
      yx: (imageElement.naturalWidth / imageElement.naturalHeight) || 1
    };
  }, [maxHeight, maxWidth]);

  const changeImageSize = (event: any, field: string, idx: number) => {
    let value = event.target.value;
    const anotherField = field === 'width' ? 'height' : 'width';
    let currentMaxSize = value;
    if (maxWidth && maxHeight) {
      currentMaxSize = field === 'width' ? maxWidth : maxHeight;
      value = value > currentMaxSize ? currentMaxSize : value;
    }
    const ratio = field === 'width' ? sizes[idx].xy : sizes[idx].yx;
    const anotherValue = Math.ceil(value * ratio);
    const newSizes = cloneDeep(sizes);
    newSizes[idx] = {
      ...newSizes[idx],
      [field]: Number(value),
      [anotherField]: Number(anotherValue)
    };

    setSize(newSizes);
  };


  return {
    sizes,
    onInitializeImageSizes,
    changeImageSize,
    setSize,
  }
}

export default useImageSize;
