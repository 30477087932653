import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import styles from './upload.module.css';
import { Button } from '../index';
import cx from 'classnames';
import useUpload from '../../../domain/useUpload';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../../spinner';
import { safari } from '../../../utils/device';
import { message } from 'antd';
import WhiteButton from '../whiteButton';

type Props = {
  fileUrl: string | ArrayBuffer | null;
  originalFile?: string | ArrayBuffer | null;
  sizes: any;
  onSubmit: (values: string, file: File, sizes: any) => void;
  maxSize: number;
}

const QUALITY = 0.8;

const BackgroundEditor:FunctionComponent<Props> = ({ fileUrl, originalFile, onSubmit, sizes, maxSize }) => {
  const { removeBackground, removedBgImage, removedBgReady } = useUpload();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [ spinner, onChangeSpinner ] = useState<boolean>(false);
  const [ image, onChangeImage ] = useState<HTMLImageElement | null>(null);
  const [ originalImage, onChangeOriginalImage ] = useState<HTMLImageElement | null>(null);
  const [ isDrawing, onChangeIsDrawing ] = useState<boolean>(false);
  const [ lastPoint, onChangeLastpoint ] = useState<any>();
  const [ action, onChangeAction ] = useState<'erase' | 'restore'>('erase');
  const [ editableFileUrl, onChangeEditableFileUrl ] = useState<string | ArrayBuffer | null>(fileUrl);

  useEffect(() => {
    onChangeEditableFileUrl(removedBgImage);
  }, [removedBgImage]);

  useEffect(() => {
    const imageElement = new Image();
    //@ts-ignore
    imageElement.src = editableFileUrl;
    imageElement.onload = () => {
      onChangeImage(imageElement);
    }
  }, [editableFileUrl]);

  useEffect(() => {
    const original = new Image();
    //@ts-ignore
    original.src = originalFile;
    original.onload = () => {
      onChangeOriginalImage(original);
    }
  }, [originalFile]);

  useEffect(() => {
    if (image && canvasRef.current) {
      image.width = canvasRef.current.width;
      image.height = canvasRef.current.height;
    }
  }, [canvasRef.current, image]);

  const imageSizes = useCallback((canvas: any) => {
    if (!image) return;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const x = canvas.width * scaleX;
    const y = canvas.height * scaleY;
    return { scaleX, x, y, scaleY }
  }, [image]);

  const renderCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas && image) {

      let ctx = canvas.getContext('2d');
      if (!ctx) return;

      ctx.globalCompositeOperation = 'destination-over';
      ctx.save();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.restore();

      const sizes = imageSizes(canvas);
      if (!sizes) return;

      ctx.drawImage(
        image,
        0,
        0,
        canvas.width * sizes.scaleX,
        canvas.height * sizes.scaleY,
        0,
        0,
        canvas.width * sizes.scaleX,
        canvas.height * sizes.scaleY
      );
    }

  }, [image, canvasRef.current, imageSizes]);

  useEffect(() => {
    renderCanvas();
  }, [canvasRef.current, image]);

  const handleMouseDown = useCallback((event: any) => {
    onChangeIsDrawing(true);
    onChangeLastpoint(getMouse(event));
    const ctx = event.target.getContext('2d');
    if (action === 'erase') {
      ctx.globalCompositeOperation = 'destination-out';
    } else {
      ctx.globalCompositeOperation = 'destination-over';
    }
  }, [action]);

  const distanceBetween = useCallback((point1: any, point2: any) => {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
  }, []);

  const handleMouseUp = useCallback(() => {
    onChangeIsDrawing(false);
  }, []);

  const angleBetween = useCallback((point1: any, point2: any) => {
    return Math.atan2( point2.x - point1.x, point2.y - point1.y );
  }, []);

  const getMouse = useCallback((event: any) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return { x, y };
  }, []);

  const handleMouseMove = useCallback((event: any) => {
    if (!isDrawing || !image) return;
    event.preventDefault();

    const currentPoint = getMouse(event);
    const dist = distanceBetween(lastPoint, currentPoint);
    const angle = angleBetween(lastPoint, currentPoint);

    const ctx = event.target.getContext('2d');
    ctx.save();
    ctx.beginPath();
    ctx.translate(0,0);

    const sizes = imageSizes(event.target);
    if (!sizes) return;

    for (let i = 0; i < dist; i++) {
      const x = lastPoint.x + (Math.sin(angle) * i);
      const y = lastPoint.y + (Math.cos(angle) * i);
      const arcScaleX = event.target.width / event.target.offsetWidth;
      const arcScaleY = event.target.height / event.target.offsetHeight;
      ctx.arc(
        x * arcScaleX,
        y * arcScaleY,
        Math.max(event.target.width, event.target.height) / 50,
        0,
        Math.PI * 2,
        false
      );
    }
    ctx.closePath();
    ctx.clip();
    if (action === 'restore') {
      const imageWidth = event.target.width * sizes.scaleX;
      const imageHeight = event.target.height * sizes.scaleY;
      ctx.drawImage(image, 0, 0, imageWidth, imageHeight, 0, 0, imageWidth, imageHeight);
    } else if (action === 'erase') {
      ctx.clearRect(0,0, event.target.width, event.target.height);
    }
    ctx.restore();

    onChangeLastpoint(currentPoint);
  }, [isDrawing, getMouse, distanceBetween, angleBetween, lastPoint, imageSizes, originalImage, image]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.addEventListener('mousedown', handleMouseDown, false);
    canvas.addEventListener('mousemove', handleMouseMove, false);
    canvas.addEventListener('mouseup', handleMouseUp, false);
    canvas.addEventListener('mouseout', handleMouseUp, false);
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown, false);
      canvas.removeEventListener('mousemove', handleMouseMove, false);
      canvas.removeEventListener('mouseup', handleMouseUp, false);
      canvas.removeEventListener('mouseout', handleMouseUp, false);
    }
  }, [canvasRef.current, handleMouseDown, handleMouseMove, handleMouseUp]);

  const submit = useCallback(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    onChangeSpinner(true);
    const format = safari ? 'png' : 'webp';
    // todo canvas to file => webWorker
    const dataUrl = canvas.toDataURL(`image/${format}`, QUALITY);
    canvas.toBlob((blob) => {
      onChangeSpinner(false);
      const file = new File([blob as BlobPart], `fileName.${format}`, { type: `image/${format}` });
      onSubmit(dataUrl, file, null);
    }, `image/${format}`, QUALITY);
  }, [canvasRef.current]);

  const onClickRemoveBackground = useCallback(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    canvas.toBlob((blob) => {
      const file = new File([blob as BlobPart], 'fileName.png', { type: 'image/png' });
      if (file.size > maxSize) {
        message.error(`File size is higher than ${maxSize / 1024}KB`);
        return;
      }
      removeBackground(file);
    }, 'image/png');
  }, [canvasRef.current, removeBackground, maxSize]);

  return (
    <div className={styles.sidesWrapper}>
      <div className={styles.leftSide}>
        { !removedBgReady || spinner ?
          <Spinner/>
          :
          image ?
            <div>
              <canvas
                key={image.src}
                className={styles.editorCanvas}
                style={{
                  width: image?.width >= image?.height ? 500 : image?.width / image?.height * 600,
                  height: image?.height > image?.width ? 600 : image?.height / image?.width * 500
                }}
                width={image.naturalWidth}
                height={image.naturalHeight}
                draggable={false}
                ref={canvasRef}
              />
            </div>
            : null
        }
      </div>
      <div className={styles.rightSide}>
        <div className={styles.removeBgActions}>
          <div className={styles.actionsWrapper}>
            <Button className={cx(styles.action, {[styles.active]: action === 'erase'})} onClick={() => onChangeAction('erase')}>Erase</Button>
            <Button className={cx(styles.action, {[styles.active]: action === 'restore'})} onClick={() => onChangeAction('restore')}>Restore</Button>
          </div>
          <Button className={styles.removeBgBtn} onClick={onClickRemoveBackground}>
            Remove background
          </Button>
        </div>
        <div className={styles.btnsWrapper}>
          <Button className={styles.btnArrow} onClick={renderCanvas}>
            <FontAwesomeIcon icon={faArrowLeft} className={styles.arrow}/>
            Original
          </Button>
          <WhiteButton className={styles.applyBtn} onClick={submit}>Apply</WhiteButton>
        </div>
      </div>
    </div>
  );
};

export default BackgroundEditor;
