import { combineReducers, createStore } from 'redux';
import auth from './auth';
import campaigns from './campaigns';
import campaign from './campaign';
import ui from './ui';
import admin from './admin';
import integration from './integration';
import common from './common';
import tokens from './tokens';
import tierPlans from './tierPlans';

const reducers = combineReducers({
  auth,
  campaigns,
  campaign,
  ui,
  integration,
  admin,
  common,
  tokens,
  tierPlans,
});

//@ts-ignore
const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
