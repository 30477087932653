import React, { Fragment } from 'react';
import addyConfirm from '../../../confirm';
import Typo from '../../../typo';
import styles from '../upload.module.css';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { message } from 'antd';
import { NativeTypes } from 'react-dnd-html5-backend';
import FILE_TYPES_SIZE_MAPPING from '../../../../utils/const/file-types-size-mapping';
import UNREZIZABLE_MIME_TYPES from '../../../../utils/const/unresizable-mime-types';

type Props = {
  type: string;
  setFiles: (file: File[]) => void;
  accept: string;
  resizable?: boolean;
}
const useFileSelect = (props: Props) => {
  const { type, setFiles, accept } = props;

  const onSelectFiles = (files: File[]) => {
    setFiles(files);
  };

  const compressFile = async (file: File): Promise<File | undefined> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (!ctx) return;
          ctx.fillStyle = '#ffffff';
          ctx.fillRect(0, 0, img.width, img.height);
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob: Blob | null) => {
            const compressedFile = new File([blob as Blob], `${Date.now()}.jpeg`, { type: 'image/jpeg' });
            if (compressedFile.size > FILE_TYPES_SIZE_MAPPING[type]) {
              message.error(`Failed to compress image to ${FILE_TYPES_SIZE_MAPPING[type] / 1024}KB`);
              resolve(undefined);
              return;
            }

            resolve(compressedFile)
          }, 'image/jpeg', 0.5);
        }
      };
      reader.onerror = error => {
        resolve(undefined);
        message.error(error)
      };
    });
  };

  const checkFileSizes = (files: File[]) => {
    const filesToAdd: File[] = [];
    const filesToCompress: File[] = [];

    let showUnResizable = false;
    const unresizableTypes = [];
    let showResizable = false;
    for (const file of files) {
      if (file.size > FILE_TYPES_SIZE_MAPPING[type]) {
        if (UNREZIZABLE_MIME_TYPES.includes(file?.type)) {
          showUnResizable = true;
          unresizableTypes.push(file?.type);
          continue;
        }
        showResizable = true;
        filesToCompress.push(file);
        continue;
      }

      filesToAdd.push(file);
    }

    if (showUnResizable) {
      addyConfirm({
        title: 'Hmm...',
        content: (
          <Fragment>
            <Typo type="p" bold className={styles.modalConfirmText}>{`Uploaded file size is higher than ${FILE_TYPES_SIZE_MAPPING[type] / 1024}KB`}</Typo>
            <Typo type="p" className={styles.modalConfirmText}>Unfortunately we can not compress a { unresizableTypes.includes('image/gif') ? 'GIF' : 'SVG'} file</Typo>
          </Fragment>
        ),
      });
    }

    if (showResizable) {
      addyConfirm({
        title: 'Hmm...',
        content: (
          <Fragment>
            <Typo type="p" bold className={styles.modalConfirmText}>{`Uploaded file size is higher than ${FILE_TYPES_SIZE_MAPPING[type] / 1024}KB`}</Typo>
            <Typo type="p" className={styles.modalConfirmText}>We can attempt to compress this image for you.</Typo>
            <Typo type="p" className={styles.modalConfirmText}>Compressing a image will result in image's transparency loss.</Typo>
          </Fragment>
        ),
        onOk: async () => {
          const promises: Promise<(File | undefined)>[] = [];
          for (const file of filesToCompress) {
            promises.push(compressFile(file));
          }

          const compressedFiles: File[] = await Promise.all(promises) as unknown as File[];

          compressedFiles.forEach((file: File | null) => {
            if (file) filesToAdd.push(file);
          });

          onSelectFiles(filesToAdd);
        }
      });
      return;
    }

    onSelectFiles(showUnResizable || showResizable ? filesToAdd : files);
  };


  const onChangeInput = (event: any) => {
    checkFileSizes([ ...event.target.files ]);
  };

  const handleFileDrop = (monitor: DropTargetMonitor) => {
    if (monitor) {
      const files = monitor.getItem().files;
      const filesToAdd: File[] = [];
      for (const file of files) {
        const [tail] = file.name.split('.').reverse();
        if (accept.includes(tail.toLowerCase())) {
          filesToAdd.push(file);
        } else {
          message.error(`File ${file.name} is unsupported`);
        }
      }

      checkFileSizes(filesToAdd);
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFileDrop(monitor);
    },
    canDrop(item, monitor) {
      return monitor.getItem().files;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return {
    onChangeInput,
    canDrop,
    isOver,
    drop,
    onSelectFiles,
  }
}

export default useFileSelect;
