import React, { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Input from '../input';
import Button from '../button';
import styles from './tags.module.css';

type Tag = {
  value: string | number;
  label: string;
}

type Props = {
  tags: Tag[];
  onChange: (tags: Tag[]) => void;
  name: string;
  className?: string;
  label?: string;
  errors?: string[]
}

const TagsInput:FunctionComponent<Props> = ({ name, errors, label, className, tags, onChange }) => {
  const [ stateTags, onChangeStateTags ] = useState<Tag[]>(tags);
  const [ tag, onChangeTag ] = useState<string>('');

  const onAddTag = () => {
    const newTags = [
      ...stateTags,
      {
        label: tag,
        value: tag
      }
    ];
    onChangeTag('');
    onChange(newTags);
  };

  useEffect(() => {
    if (stateTags.length !== tags.length) onChangeStateTags(tags);
  }, [tags]);

  const onRemove = (idx: number) => {
    const newTags = stateTags.filter((tag: Tag, index: number) => index !== idx);
    onChange(newTags);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputs}>
        <Input errors={errors} name={name} label={label} className={styles.input} value={tag} onChange={(event) => onChangeTag(event?.target?.value)}/>
        <Button type="primary" htmlType="button" className={styles.addBtn} onClick={onAddTag} name={`${name}-component-confirm`}>Add</Button>
      </div>
      <div className={styles.tags}>
        {stateTags.map((tag: Tag, idx: number) => (
          <span className={styles.tag} key={idx}>
            {tag.label}
            <button className={styles.removeTag} type="button" name={`${name}-component-remove-${idx}`} onClick={() => onRemove(idx)}>
              <FontAwesomeIcon icon={faTimes}/>
            </button>
          </span>
        ))}
      </div>
    </div>
  )
};

export default TagsInput;
