import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import Modal from '../../../modal';
import Icon from '../../../icon';
import addyConfirm from '../../../confirm';
import { message } from 'antd';
import cx from 'classnames';
import Typo from '../../../typo';
import WhiteButton from '../../whiteButton';
import Input from '../../whiteInput';
import useUpload from '../../../../domain/useUpload';
import styles from '../upload.module.css';
import cloud from '../cloud.svg';
import BackgroundEditor from '../editModal/tabs/backgroundedit';
import CropBox from '../editModal/tabs/cropper';
import { Props } from '../index';
enum Modals {
  UPLOAD,
  PREVIEW,
  BACKGROUND,
  CROP
}

const OldUploadModal:FunctionComponent<Props & { show: boolean, setShowModal: (value: boolean) => void }> = ({ setShowModal, name, show, type, disabled, maxWidth, maxHeight, value, onChange, onChangeUrl, tourId, accept = '.svg, .png, .jpg, .jpe, .gif, .jpeg, .webp, .avif, .jfif', phaser, changeConfigField }) => {
  const unresizableMimeTypes = useMemo(() => ['image/gif', 'image/svg+xml', 'image/avif'], []);
  const formatMimeTypeMapping: Record<string, string> = useMemo(() => ({
    gif: 'image/gif',
    svg: 'image/svg+xml',
    avif: 'image/avif',
  }), []);

  const defaultSizes = useMemo(() => ({
    width: 0,
    height: 0,
    xy: 1,
    yx: 1
  }), []);

  const acceptableFormats = useMemo(() => phaser ? '.svg, .png, .jpg, .jpe, .jpeg, .webp, .avif, .jfif' : accept, [accept, phaser]);

  const fileTypesSizeMapping: Record<string, number> = useMemo(() => ({
    gameBackground: 1024 * 1024 * 5,
    gameSettings: 1024 * 1024 * 1.5,
    favicon: 1024 * 100,
  }), []);

  const { upload, url, progress, cancelUpload } = useUpload();
  const [ showModal, onChangeShowModal ] = useState<Modals | null>(null);
  const [ file, setFile ] = useState<File | null>(null);
  const [ sizes, onChangeSize ] = useState(defaultSizes);
  const [ fileUrl, onChangeFileUrl ] = useState<string | ArrayBuffer | null>(null);
  const [ originalFile, setOriginalFile ] = useState<File | null>(null);
  const [ originalFileUrl, onChangeOriginalFileUrl ] = useState<string | ArrayBuffer | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const isUploading = useMemo(() => progress > 0, [progress]);

  useEffect(() => {
    if (show) {
      onOpenModal();
    }
  }, [show]);

  const onSelectFile = useCallback((file: File) => {
    setFile(file);
    setOriginalFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onChangeShowModal(Modals.PREVIEW);
      onChangeFileUrl(reader.result);
      onChangeOriginalFileUrl(reader.result);
      const preloadImage = new Image();
      //@ts-ignore
      preloadImage.src = reader.result;
      preloadImage.onload = () => {
        onInitializeImageSizes(preloadImage);
      }
    };
    reader.onerror = error => message.error(error);
  }, [setFile, onChangeFileUrl]);

  const compressFile = useCallback((file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, img.width, img.height);
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(function (blob: Blob | null) {
          const compressedFile = new File([blob as Blob], `${Date.now()}.jpeg`, { type: 'image/jpeg' });
          if (compressedFile.size > fileTypesSizeMapping[type]) {
            message.error(`Failed to compress image to ${fileTypesSizeMapping[type] / 1024}KB`);
            return;
          }
          onSelectFile(compressedFile);
        }, 'image/jpeg', 0.5);
      }
    };
    reader.onerror = error => message.error(error);
  }, [fileTypesSizeMapping, type]);

  const checkFileSize = useCallback((file: File) => {
    if (file.size > fileTypesSizeMapping[type]) {
      if (unresizableMimeTypes.includes(file?.type)) {
        addyConfirm({
          title: 'Hmm...',
          content: (
            <Fragment>
              <Typo type="p" bold className={styles.modalConfirmText}>{`Uploaded file size is higher than ${fileTypesSizeMapping[type] / 1024}KB`}</Typo>
              <Typo type="p" className={styles.modalConfirmText}>Unfortunately we can not compress a { file?.type === 'image/gif' ? 'GIF' : 'SVG'} file</Typo>
            </Fragment>
          ),
        });
        return;
      }
      addyConfirm({
        title: 'Hmm...',
        content: (
          <Fragment>
            <Typo type="p" bold className={styles.modalConfirmText}>{`Uploaded file size is higher than ${fileTypesSizeMapping[type] / 1024}KB`}</Typo>
            <Typo type="p" className={styles.modalConfirmText}>We can attempt to compress this image for you.</Typo>
            <Typo type="p" className={styles.modalConfirmText}>Compressing a image will result in image's transparency loss.</Typo>
          </Fragment>
        ),
        onOk: () => compressFile(file)
      });
      return;
    }

    onSelectFile(file);
  }, [fileTypesSizeMapping, type, compressFile]);

  const onChangeInput = useCallback(async (event: any) => {
    const file = event.target.files[0];
    event.target.value = null;
    checkFileSize(file);
  }, [onSelectFile, type, fileTypesSizeMapping, compressFile]);

  const handleFileDrop = useCallback(
    (monitor: DropTargetMonitor) => {
      if (monitor) {
        const file = monitor.getItem().files[0];
        const [tail] = file.name.split('.').reverse();
        if (acceptableFormats.includes(tail.toLowerCase())) {
          checkFileSize(file);
        } else {
          message.error('File is unsupported');
        }
      }
    }, [acceptableFormats, checkFileSize]);

  const onCloseModal = useCallback(() => {
    if (isUploading) {
      addyConfirm({
        title: 'Oops...',
        content: 'Upload in progress. Do you want cancel this operation?',
        onOk: () => {
          cancelUpload();
          onChangeShowModal(null);
          setShowModal(false);
        }
      });
      return;
    }
    setShowModal(false);
    onChangeShowModal(null);
  }, [isUploading, cancelUpload]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      handleFileDrop(monitor);
    },
    canDrop(item, monitor) {
      const files = monitor.getItem().files;
      return files?.length < 2;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (url) {
      if (onChange) onChange(url);
      if (changeConfigField) changeConfigField({ [name]: url });
      if (onChangeUrl) onChangeUrl(url);
      onChangeSize(defaultSizes);
      setShowModal(false);
      onChangeShowModal(null);
      setFile(null);
      onChangeFileUrl(null);
    }
  }, [url, onChangeSize, onChangeShowModal, setFile, onChangeFileUrl]);

  useEffect(() => {
    if (tourId) {
      onOpenModal();
    }
  }, [tourId]);

  const resetImage = useCallback(() => {
    onChangeFileUrl(originalFileUrl);
    setFile(originalFile);
    const preloadImage = new Image();
    //@ts-ignore
    preloadImage.src = originalFileUrl;
    preloadImage.onload = () => {
      onInitializeImageSizes(preloadImage);
    }
  }, [originalFileUrl, imageRef.current, originalFile]);

  const onInitializeImageSizes = useCallback((imageElement: any) => {
    let width = imageElement.naturalWidth;
    let height = imageElement.naturalHeight;
    if (maxWidth && maxHeight) {
      width = imageElement.naturalWidth > maxWidth ? maxWidth : imageElement.naturalWidth;
      height = imageElement.naturalHeight > maxHeight ? maxHeight : imageElement.naturalHeight;
    }
    onChangeSize({
      height,
      width,
      xy: (imageElement.naturalHeight / imageElement.naturalWidth) || 1,
      yx: (imageElement.naturalWidth / imageElement.naturalHeight) || 1
    });
  }, []);

  const onChangeImageSize = useCallback((event: any, field: string) => {
    let value = event.target.value;
    const anotherField = field === 'width' ? 'height' : 'width';
    let currentMaxSize = value;
    if (maxWidth && maxHeight) {
      currentMaxSize = field === 'width' ? maxWidth : maxHeight;
      value = value > currentMaxSize ? currentMaxSize : value;
    }
    const ratio = field === 'width' ? sizes.xy : sizes.yx;
    const anotherValue = Math.ceil(value * ratio);
    onChangeSize({
      ...sizes,
      [field]: value,
      [anotherField]: anotherValue
    });
  }, [maxHeight, maxWidth, sizes]);

  const onOpenModal = useCallback((event?: any) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (disabled) return;

    if (value) {
      const preloadImage = new Image();
      preloadImage.src = value;
      preloadImage.onload = () => {
        onInitializeImageSizes(preloadImage);
      }

      const request = new XMLHttpRequest();
      request.open('GET', value, true);
      request.responseType = 'blob';
      request.onload = () => {
        const array = value.split('.');
        const existingFile = new File([request.response], `${new Date().getTime()}.${array[array.length - 1]}`, { type: formatMimeTypeMapping[array[array.length - 1]] || 'image/png' });
        onSelectFile(existingFile);
      };
      request.send();
    }

    onChangeShowModal(value ? Modals.PREVIEW : Modals.UPLOAD);
  }, [disabled, value, onInitializeImageSizes, onSelectFile, formatMimeTypeMapping]);

  const onUpload = useCallback(() => {
    if (file) {
      upload(file, type, sizes.width, sizes.height);
    }
  }, [file, type, sizes]);

  const footer = useMemo(() => {
    const disableUpload = !file || isUploading;
    return (
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <WhiteButton className={styles.footerBtn} size="lg" type="ghost" onClick={onCloseModal} name={`${name}-component-cancel`}>Cancel</WhiteButton>
          <WhiteButton className={styles.footerBtn} size="lg" onClick={onUpload} disabled={disableUpload} name={`${name}-component-upload`}>Upload</WhiteButton>
        </div>
      </div>
    )
  }, [file, isUploading, onCloseModal, name, onUpload]);

  const onSubmit = useCallback((url: string | ArrayBuffer | null, file: File, newSizes: any) => {
    if (file.size > fileTypesSizeMapping[type]) {
      message.error(`Generated file size is higher than ${fileTypesSizeMapping[type] / 1024}KB`);
      return;
    }
    onChangeFileUrl(url);
    setFile(file);
    if (newSizes) onChangeSize(newSizes);
    onChangeShowModal(Modals.PREVIEW);
  }, []);

  const onChangeModalBody = useCallback((type: Modals) => {
    if (type !== Modals.UPLOAD && !file) return;
    onChangeShowModal(type);
  }, [file, imageRef.current]);

  useEffect(() => {
    if (imageRef.current) {
      onInitializeImageSizes(imageRef.current);
    }
  }, [showModal, imageRef.current]);

  const onGoToCrop = useCallback(() => {
    onChangeModalBody(Modals.CROP);
  }, [onChangeModalBody])

  const tabs = useMemo(() => (
    <div className={styles.tabsWrapper}>
      <div className={cx(styles.tab, {[styles.active]: showModal === Modals.UPLOAD})} typeof="button" onClick={() => onChangeModalBody(Modals.UPLOAD)}>
        <span className={styles.tabIcon}>
          <Icon icon="upload" active={showModal === Modals.UPLOAD}/>
        </span>
        <Typo type='p' white className={styles.label}>Image upload</Typo>
      </div>
      <div className={cx(styles.tab, {[styles.active]: showModal === Modals.PREVIEW}, {[styles.disabled]: !file})} typeof="button" onClick={() => onChangeModalBody(Modals.PREVIEW)}>
        <span className={styles.tabIcon}>
          <Icon icon="img" active={showModal === Modals.PREVIEW}/>
        </span>
        <Typo type='p' white className={styles.label}>Preview Image</Typo>
      </div>
      {
        file && !unresizableMimeTypes.includes(file?.type) ?
          <Fragment>
            <div className={cx(styles.tab, {[styles.active]: showModal === Modals.CROP}, {[styles.disabled]: !file})} typeof="button" onClick={onGoToCrop}>
              <span className={styles.tabIcon}>
                <Icon icon="crop" active={showModal === Modals.CROP}/>
              </span>
              <Typo type='p' white className={styles.label}>Crop Image</Typo>
            </div>
            <div className={cx(styles.tab, {[styles.active]: showModal === Modals.BACKGROUND}, {[styles.disabled]: !file})} typeof="button" onClick={() => onChangeModalBody(Modals.BACKGROUND)}>
              <span className={styles.tabIcon}>
                <Icon icon="bg" active={showModal === Modals.BACKGROUND}/>
              </span>
              <Typo type='p' white className={styles.label}>Remove Background</Typo>
            </div>
          </Fragment>
          : null
      }
    </div>
  ), [showModal, file, unresizableMimeTypes, onGoToCrop, onChangeModalBody]);

  const sizesWrapper = useMemo(() => (
    <div className={styles.imageSizesWrapper}>
      <Input label="Width" labelWidth={44} inline tiny disabled={showModal === Modals.CROP} name={`${name}-component-width`} type="number" value={sizes.width} onChange={(event: any) => onChangeImageSize(event, 'width')}/>
      <span className={styles.chainIcon}>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.47186 7.42224L2.31457 6.57953C2.70707 6.18703 3.34343 6.18703 3.73593 6.57953C4.10039 6.94399 4.12643 7.51872 3.81403 7.91322L3.73593 8.00089L2.89322 8.8436C1.71573 10.0211 1.71573 11.9302 2.89322 13.1077C4.02866 14.2431 5.84437 14.2837 7.0284 13.2293L7.15729 13.1077L8 12.265C8.3925 11.8725 9.02886 11.8725 9.42136 12.265C9.78582 12.6294 9.81185 13.2041 9.49946 13.5986L9.42136 13.6863L8.57864 14.529C6.61616 16.4915 3.43435 16.4915 1.47186 14.529C-0.438977 12.6182 -0.489262 9.55134 1.32101 7.57966L1.47186 7.42224ZM7.42224 1.47186C9.38472 -0.490621 12.5665 -0.490621 14.529 1.47186C16.4915 3.43435 16.4915 6.61616 14.529 8.57864L13.6863 9.42136C13.2938 9.81385 12.6575 9.81385 12.265 9.42136C11.8725 9.02886 11.8725 8.3925 12.265 8L13.1077 7.15729C14.2852 5.9798 14.2852 4.07071 13.1077 2.89322C11.9302 1.71573 10.0211 1.71573 8.8436 2.89322L8.00089 3.73593C7.60839 4.12843 6.97203 4.12843 6.57953 3.73593C6.18703 3.34343 6.18703 2.70707 6.57953 2.31457L7.42224 1.47186ZM8.42172 6.15765C8.81422 5.76516 9.45058 5.76516 9.84308 6.15765C10.2356 6.55015 10.2356 7.18651 9.84308 7.57901L7.57901 9.84308C7.18651 10.2356 6.55015 10.2356 6.15765 9.84308C5.76516 9.45058 5.76516 8.81422 6.15765 8.42172L8.42172 6.15765Z"/>
        </svg>
      </span>
      <Input label="Height" labelWidth={44} inline tiny disabled={showModal === Modals.CROP} name={`${name}-component-height`} type="number" value={sizes.height} onChange={(event: any) => onChangeImageSize(event, 'height')}/>
    </div>
  ), [sizes, showModal, onChangeImageSize]);

  const previewBody = useMemo(() => (
    <div className={styles.sidesWrapper}>
      <div className={styles.leftSide}>
        <div className={styles.imageWrapper}>
          <img
            className={styles.imagePreview}
            ref={imageRef}
            draggable={false}
            //@ts-ignore
            src={fileUrl} alt="preview"
          />
        </div>
      </div>
      {
        file && !unresizableMimeTypes.includes(file?.type) ?
          <div className={styles.rightSide}>
            <div>
              { sizesWrapper }
              <div className={styles.btnsWrapper}>
                <WhiteButton className={styles.btn} onClick={resetImage}>Reset all changes</WhiteButton>
              </div>
            </div>
          </div>
          : null
      }
    </div>
  ), [imageRef, fileUrl, sizesWrapper, resetImage]);

  const uploaderBody = useMemo(() => (
    <div className={cx(styles.dndArea, { [styles.canDrop]: canDrop || isOver })} ref={drop}>
      <div className={styles.iconWrapper}>
        <img src={cloud} alt="cloud" draggable={false}/>
      </div>
      <Typo type="p" white>Drag file to upload</Typo>
      <Typo type="p" white>or</Typo>
      <WhiteButton className={styles.inputFileBtn}>
        <span>Browse files</span>
        <input className={styles.inputFile} type="file" accept={acceptableFormats} onChange={onChangeInput} cypress-id={`${name}-component-browsefile`}/>
      </WhiteButton>
    </div>
  ), [canDrop, isOver, drop, acceptableFormats]);

  const bodyTypes = useMemo(() => ({
    [Modals.UPLOAD]: uploaderBody,
    [Modals.PREVIEW]: previewBody,
    [Modals.BACKGROUND]: <BackgroundEditor maxSize={fileTypesSizeMapping[type]}
                                           fileUrl={fileUrl} originalFile={originalFileUrl} onSubmit={onSubmit} onCloseModal={onCloseModal}/>,
    [Modals.CROP]: <CropBox fileUrl={fileUrl} type={file?.type} onSubmit={onSubmit} onChangeSizes={onChangeSize}
                            onCloseModal={onCloseModal} sizes={sizes}/>,
  }), [fileUrl, onSubmit, onChangeSize, onCloseModal, sizesWrapper, previewBody, uploaderBody, sizes, file]);

  const body = useMemo(() => (
    <Fragment>
      { tabs }
      <div>
        { showModal !== null ? bodyTypes[showModal] : null }
        { showModal === Modals.PREVIEW ? footer : null }
      </div>
    </Fragment>
  ), [showModal, bodyTypes, tabs]);

  return (
    <Fragment>
      <Modal
        visible={showModal !== null}
        onCancel={onCloseModal}
        wide title="Image Upload"
      >
        <div className={styles.modalWrapper}>
          { isUploading
            ?
            <div className={styles.busyWrapper}>
              <div className={styles.addyThink}/>
              <Typo className={styles.hint} type="p">Upload in progress. Refresh in a few moments to see your new image...</Typo>
            </div>
            : body
          }
        </div>
      </Modal>
    </Fragment>
  );
};

export default OldUploadModal;
