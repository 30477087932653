import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import AntButton, { ButtonProps } from 'antd/lib/button';
import styles from './button.module.css';

interface Props extends ButtonProps {
  className?: string;
  newForm?: boolean;
  small?: boolean;
  withoutShadow?: boolean;
  name?: string;
}

const Button:FunctionComponent<Props> = ({ children, withoutShadow, small, className, danger, type = 'primary', newForm, name, ...other }) => {
  return (
    <AntButton {...other} className={cx(styles.button, className, styles[type], {
      [styles.danger]: danger,
      [styles.withoutShadow]: withoutShadow,
      [styles.newForm]: newForm,
      [styles.small]: small
    })} type={type} cypress-id={`${name}-component`}>{children}</AntButton>
  );
};

export default Button;
