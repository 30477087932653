import React, { FunctionComponent, ReactElement } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import cx from 'classnames';
import styles from './link.module.css';

interface Props extends LinkProps {
  to: string;
  name?: string;
  className?: string;
  children: ReactElement | string;
}

const Link:FunctionComponent<Props> = ({ to, children, className, name }) => (
  <RouterLink to={to} className={cx(styles.link, className)} cypress-id={name}>{children}</RouterLink>
);

export default Link;
