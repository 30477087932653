import I, { ImmutableObject } from 'seamless-immutable';
import { brandAdapter } from '../auth/adapters';
import {
  AccountBrand,
  AdminAccount,
  AdminAccountCountry,
  AdminAccountJoined,
  AdminAccountOwner,
  AdminAccountTierSettings,
  AdminAccountWeeklyStatistics,
  AdminBrand,
  AdminBrandAccount,
  AdminBrandAccountOwner,
  AdminCampaignTemplate,
  AdminCoupon,
  AdminCoupons,
  AdminGame,
  AdminListAccount,
  AdminListAccountUser,
  AdminNewsPost,
  AdminPlayer,
  AdminState,
  BrandWeeklyStatistics,
} from './types';

export const clearList = () => {
  return {
    meta: {
      currentPage: 1,
      itemCount: 50,
      itemsPerPage: 50,
      totalItems: 0,
      totalPages: 1
    },
    items: []
  }
}

export const AdminAccountTierSettingsAdapter = (data?: AdminAccountTierSettings): AdminAccountTierSettings => {
  if (data) {
    return {
      ...data,
      gamesAllowed: data.gamesAllowed || [],
      unlimitedCampaigns: Boolean(data.unlimitedCampaigns),
    };
  }

  return {
    _id: '',
    accountId: 0,
    gamesAllowed: [],
    brandsCountAllowed: 0,
    campaignsCountAllowed: 0,
    gameplayCountAllowed: 0,
    imageBgRemovalAllowed: false,
    teamMemberCountAllowed: 0,
    uniqueLeadsCountAllowed: 0,
    unlimitedGameplay: false,
    unlimitedUniqueLeads: false,
    uniqueVisitorCountAllowed: 0,
    unlimitedCampaigns: false,
    unlimitedUniqueVisitors: false,
    createdAt: '',
    expiresAt: ''
  }
}

export const adminAccountOwner = (data?: AdminAccountOwner) => {
  if (data) {
    return data;
  }
  return I({
    id: null,
    email: '',
    full_name: '',
    phone_number: '',
    role: '',
    accountId: null,
    created_at: '',
  })
}

export const adminPlayerAdapter = (data: AdminPlayer) => {
  return ({
    ...data,
    extra: data?.extra || '{}',
  });
}

export const adminAccountBrandAdapter = (data: AccountBrand) => {
  return I(data);
}

export const adminAccountListAdapter = (data?: AdminListAccount) => {
  if (data) {
    return I({
      ...data,
      payment_expires_at: data.payment_expires_at || '',
      created_campaign_at: data.created_campaign_at || '',
      users: data.users.map(adminAccountListUserAdapter),
    });
  }
}
export const adminAccountListUserAdapter = (data: AdminListAccountUser) => {
  return I(data);
}

export const adminAccountAdapter = (data?: AdminAccount) => {
  if (data) {
    return I({
      ...data,
      tierSettings: AdminAccountTierSettingsAdapter(data.tierSettings),
      company_name: data?.company_name || '',
      phone_number: data?.phone_number || '',
      country_code: data?.country_code || '',
      city: data?.city || '',
      type: data?.type || 'standard',
      permissions: data?.permissions && typeof data?.permissions === 'string' ? JSON.parse(data?.permissions) : {
        publishCampaigns: true,
      },
      country: data?.country || '',
      address: data?.address || '',
      zip_code: data?.zip_code || '',
      vat_number: data?.vat_number || '',
      has_vat: Boolean(data?.has_vat),
      brands: data.brands ? data.brands.map(adminAccountBrandAdapter) : [],
      users: data.users ? data.users.map(adminAccountOwner) : [],
      payment_expires_at: data?.payment_expires_at || '',
      payment_customer_id: data?.payment_customer_id || '',
      last_invoice_id: data?.last_invoice_id || '',
      last_invoice_status: null,
      payment_subscription_id: data?.payment_subscription_id || null,
      has_trial_period: Boolean(data?.has_trial_period),
      is_trial: Boolean(data?.is_trial),
      disabled_payment: Boolean(data?.disabled_payment),
      use_new_design: Boolean(data?.use_new_design),
      api_key_for_zapier: data?.api_key_for_zapier || '',
      payments: data.payments,
    });
  }
  return I({
    created_at: '',
    id: null,
    tierSettings: AdminAccountTierSettingsAdapter(),
    brandsCount: 0,
    campaignsCount: 0,
    disabled_payment: false,
    use_new_design: false,
    title: '',
    users: []
  });
}

export const adminNewsPostAdapter = (data?: AdminNewsPost) => {
  if (data) {
    return I({
      created_at: data.created_at,
      id: data.id,
      title: data.title,
      description: data.description,
      image: data.image,
      link: data.link,
      active: data.active,
      click_count: data.click_count
    });
  }
  return I({
    created_at: '',
    id: null,
    title: '',
    description: '',
    image: '',
    link: '',
    active: false,
    click_count: 0
  });
}

const AdminBrandAccountOwnerAdapter = (data?: AdminBrandAccountOwner | null) => {
  if (data) {
    return I(data)
  }
  return I({
    accountId: null,
    created_at: '',
    email: '',
    full_name: '',
    id: null,
    phone_number: '',
    role: '',
  })
}

const AdminBrandAccountAdapter = (data?: AdminBrandAccount) => {
  return I({
    id: data?.id || null,
    company_name: data?.company_name || '',
    owner: AdminBrandAccountOwnerAdapter(data?.owner)
  })
}

export const AdminBrandAdapter = (data?: AdminBrand) => {
  if (data) {
    return I({
      ...data,
      account: AdminBrandAccountAdapter(data.account),
    });
  }
  return I({
    account: AdminBrandAccountAdapter(),
    campaignsCount: 0,
    created_at: '',
    id: null,
    title: '',
    uniqueLeadCount: 0,
  })
}

export const adminGameAdapter = (data?: AdminGame) => {
  return I({
    id: data?.id || null,
    title: String(data?.title),
    description: String(data?.description),
    image: String(data?.image),
    categories: data?.categories || [],
    gameType: data?.gameType || null,
    active: Boolean(data?.active),
    preview_image: data?.preview_image || '',
    gameTagsGame: data?.gameTagsGame || [],
    guide_file: data?.guide_file || '',
  })
}

export const campaignTemplateAdapter = (data?: AdminCampaignTemplate) => {
  return I({
    id: data?.id || null,
    campaignId: data?.campaignId || null,
    title: data?.title || '',
    description: data?.description || '',
    image: data?.image || '',
    active: Boolean(data?.active),
    screens_setup: data?.screens_setup || '',
    background_config: data?.background_config || "{}",
    animated_background_config: data?.animated_background_config || "{}",
    terms_and_conditions_config: data?.terms_and_conditions_config || "{}",
    privacy_policy_config: data?.privacy_policy_config || "{}",
    embed_options_config: data?.embed_options_config || "{}",
    errors_config: data?.errors_config || "{}",
    leaderboard_config: data?.leaderboard_config || "{}",
    content_config: data?.content_config || "{}",
    side_bar_config: data?.side_bar_config || '{}',
    game_area_config: data?.game_area_config || "{}",
    landingPageScreenElements: data?.landingPageScreenElements || [],
    brand: AdminBrandAdapter(data?.brand) || null,
    brandId: data?.brandId || null,
    created_at: String(data?.created_at),
  })
}


export const adminCouponAdapter = (data?: AdminCoupon) => {
  return I({
    id: data?.id ?? '',
    object: data?.object ?? '',
    amount_off: data?.amount_off ?? null,
    created: data?.created ?? 0,
    currency: data?.currency ?? 'eur',
    duration: data?.duration ?? 1,
    duration_in_months: data?.duration_in_months ?? 1,
    livemode: data?.livemode ?? false,
    max_redemptions: data?.max_redemptions ?? 1,
    metadata: data?.metadata ?? {},
    name: data?.name ?? '',
    percent_off: data?.percent_off ?? null,
    redeem_by: data?.redeem_by ?? 0,
    times_redeemed: data?.times_redeemed ?? 0,
    valid: Boolean(data?.valid)
  });
}

export const adminCouponsAdapter = (value: AdminCoupons) => {
  return I({
    has_more: Boolean(value?.has_more),
    data: value.data.map(adminCouponAdapter)
  })
}

export const adminAccountCountryAdapter = (value: AdminAccountCountry): ImmutableObject<AdminAccountCountry> => {
  return I({
    count: value?.count || 0,
    account_country_code: value.account_country_code,
  })
}

export const adminAccountJoinedAdapter = (value: AdminAccountJoined): ImmutableObject<AdminAccountJoined> => {
  return I({
    count: value?.count || 0,
    date: value?.date,
  })
}

export const brandStatisticsAdapter = (data: BrandWeeklyStatistics): BrandWeeklyStatistics => {
  return {
    ...data,
    gameDurationTotal: data.gameDurationTotal || 0,
    gameEndedCount: data.gameEndedCount || 0,
    gameStartedCount: data.gameStartedCount || 0,
    gameplayCount: data.gameplayCount || 0,
    gameplayFinishedCount: data.gameplayFinishedCount || 0,
    uniqueLeadCount: data.uniqueLeadCount || 0,
    uniqueVisitorCount: data.uniqueVisitorCount || 0,
    visitorCount: data.visitorCount || 0,
    wellPerformingCampaignsCount: data.wellPerformingCampaignsCount || 0,
  }
}

export const adminAccountStatisticsAdapter = (data: AdminAccountWeeklyStatistics): AdminAccountWeeklyStatistics => {
  return {
    date: data.date,
    data: data.data.map(brandStatisticsAdapter),
  }
}

export const adminStoreAdapter = ():AdminState => {
  //@ts-ignore
  return I({
    campaigns: clearList(),
    brands: clearList(),
    newsPosts: clearList(),
    newsPost: adminNewsPostAdapter(),
    accounts: clearList(),
    adminUser: adminAccountOwner(),
    adminUsers: clearList(),
    players: clearList(),
    coupon: adminCouponAdapter(),
    account: adminAccountAdapter(),
    activeBrand: brandAdapter(),
    coupons: {
      has_more: false,
      data: []
    },
    adminInvitations: clearList(),
    games: [],
    game: adminGameAdapter(),
    campaignTemplates: clearList(),
    campaignTemplate: campaignTemplateAdapter(),
    accountsCountries: [],
    accountsJoined: [],
    accountStatistics: [],
    campaignsStatistics: clearList(),
  });
}
