import React, { FunctionComponent, useState } from 'react';
import styles from './categoriesModal.module.css';
import useCategories from '../useCategories';
import { WhiteAutocomplete, WhiteButton } from '../../../index';

type Props = {
  categories: string[] | undefined;
  onCancel: () => void;
  onSubmit: (categories: string[]) => void;
};

const CategoriesModal:FunctionComponent<Props> = (props) => {
  const {
    categories = [],
    onCancel,
    onSubmit,
  } = props;

  const { categoryValues, subCategoryMapping, subCategoryValues, gameCategoryValues, categoryOptions } = useCategories();

  const [ topCategory, setTopCategory ] = useState<string>(categories.find((item) => categoryValues.includes(item)) || '');
  const [ subCategory, setSubCategory ] = useState<string>(categories.find((item) => [ ...subCategoryValues, ...gameCategoryValues ].includes(item)) || '');

  const changeCategories = (category: string, sub: boolean = false) => {
    const method = sub ? setSubCategory : setTopCategory;
    method(category);
  }

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.filtersWrapper}>
        <WhiteAutocomplete
          name="category"
          clearable
          overflow
          label="Category"
          focusOnClear={false}
          value={topCategory}
          className={styles.topCategory}
          options={categoryOptions}
          onChange={changeCategories as any}
        />
        {
          subCategoryMapping[topCategory] && (
            <WhiteAutocomplete
              clearable
              overflow
              label="Subcategory"
              focusOnClear={false}
              className={styles.subCategory}
              name="sub-category"
              value={subCategory}
              options={subCategoryMapping[topCategory]}
              onChange={(value: any) => changeCategories(value, true)}
            />
          )
        }
      </div>
      <div className={styles.buttonsWrapper}>
        <WhiteButton type="ghost" onClick={() => onCancel()}>Cancel</WhiteButton>
        <WhiteButton onClick={() => onSubmit([topCategory, subCategory])}>Save</WhiteButton>
      </div>
    </div>
  );
};

export default CategoriesModal;
