export enum QuestionTypes {
  STANDARD = 'standard',
  PREDICT_NUMBER = 'predictNumber',
  PREDICT_SCORE = 'predictScore',
  PREDICT_ORDER = 'predictOrder',
  PAIRS = 'pairs',
  TEXT_AREA = 'textarea',
  RANGE = 'range',
  BUTTON_RANGE = 'buttonRange',
  SWIPE = 'swipe',
  PICTURE_REVEAL = 'pictureReveal',
}
