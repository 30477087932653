import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import Typo from '../../components/typo';
import { Button } from '../../components/form';
import { restrictionModalShowSelector, restrictionModalTextSelector } from '../../store/ui/selectors';
import { hideRestrictionModal } from '../../store/ui/actions';
import styles from './restriction.module.css';


const RestrictionModal:FunctionComponent = () => {
  const dispatch = useDispatch();
  const show = useSelector(restrictionModalShowSelector);
  const text = useSelector(restrictionModalTextSelector);

  const onClose = () => {
    dispatch(hideRestrictionModal());
  }

  const footer = [
    <Button onClick={onClose} type="ghost" key="restriction-modal-ok" name="restriction-modal-ok">Ok</Button>,
    <a href="mailto:support@adact.me" key="restriction-modal-link" cypress-id="restriction-modal-link" target="_blank" className={styles.link} onClick={onClose}>
      <Button>Send Email</Button>
    </a>,
  ];

  return (
    <Modal visible={show} onCancel={onClose} destroyOnClose footer={footer} width={350}>
      <div className={styles.body}>
        <Typo className={styles.text} type="p">{text}</Typo>
        <Typo type="p">Please reach us via email (support@adact.me) to update your plan in the system.</Typo>
      </div>
    </Modal>
  );
};

export default RestrictionModal;
