import { GameSettingTypesEnum } from '../../../../utils/enums';

const useCategories = () => {
  const categoryOptions = [
    { label: 'Game', value: 'game' },
    { label: 'Landing page', value: 'landing-page' },
    { label: 'Settings', value: 'settings' },
    { label: 'Prizes', value: 'prizes' },
  ];

  const landingPageCategoryOptions = [
    { label: 'Extras', value: 'extras' },
    { label: 'Background', value: 'background' },
    { label: 'Content', value: 'content' },
  ];

  const gameCategoryOptions = Object.values(GameSettingTypesEnum).map((item) => {
    const words = item.split(/(?=[A-Z])/);
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return {
      value: item,
      label: capitalizedWords.join(' '),
    }
  });

  const subCategoryMapping: Record<string, any> = {
    'game': gameCategoryOptions,
    'landing-page': landingPageCategoryOptions,
  }

  const categoryValues = categoryOptions.map((item) => item.value)
  const subCategoryValues = landingPageCategoryOptions.map((item) => item.value)
  const gameCategoryValues = gameCategoryOptions.map((item) => item.value)


  return {
    subCategoryMapping,
    categoryValues,
    subCategoryValues,
    gameCategoryValues,
    categoryOptions,
  }
}

export default useCategories;
