import styles from './inlineborderradius.module.css'
import React, { FunctionComponent } from 'react';
import useOptions from '../../../domain/useOptions';
import { WhiteRadioGroup, WhiteSelect } from '../index';
import InputType from '../../../utils/type/input.type';

type Props = InputType & {
  value: number;
  onChange?: (value: number) => void;
  name: string;
  disabled?: boolean;
}

const WhiteInlineBorderRadius:FunctionComponent<Props> = (props) => {
  const { borderWidthOptions, newBorderRadiusOptions } = useOptions();

  return (
    <div className={styles.shortField}>
      <div className={styles.elementWrapper}>
        <WhiteRadioGroup disabled={props.disabled} inline tiny label="Radius" options={newBorderRadiusOptions} { ...props }/>
      </div>
      <div className={styles.elementWrapper}>
        <WhiteSelect disabled={props.disabled} inline tiny options={borderWidthOptions} { ...props } />
      </div>
    </div>
  )
};

export default WhiteInlineBorderRadius;
