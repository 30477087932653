import { useState } from 'react';
import { AxiosError } from 'axios';
import { notification } from 'antd';
import errorParser from '../../errors';

export type ErrorsType = Record<string, string[]>;

export const useErrors = () => {
  const [errors, setErrors] = useState<ErrorsType>({});

  const parseErrors = (error: AxiosError, fallMessage?: string) => {
    if (error.response?.data.statusCode === 422) {
      const errors = errorParser(error.response?.data);
      setErrors(errors);
      return;
    }

    if (fallMessage) {
      notification.error({
        message: fallMessage,
        description: error.response?.data?.message
      });
      return;
    }

    if ([401, 400, 403].includes(Number(error.response?.status))) {
      notification.error({
        message: 'Error',
        description: error.response?.data?.message
      });
      return;
    }
    notification.error({
      message: 'Error',
      description: 'Something went wrong'
    });
  };

  const clearErrors = () => {
    setErrors({});
  }

  return {
    parseErrors,
    errors,
    clearErrors,
    setErrors,
  };
}
