import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon from '../../../icon';
import { message } from 'antd';
import cx from 'classnames';
import Typo from '../../../typo';
import styles from './editModal.module.css';
import CropBox from './tabs/cropper';
import BackgroundEditor from './tabs/backgroundedit';
import UNREZIZABLE_MIME_TYPES from '../../../../utils/const/unresizable-mime-types';
import FILE_TYPES_SIZE_MAPPING from '../../../../utils/const/file-types-size-mapping';
import { Props } from '../index';
import useImageSize, { ImageSize } from './useImageSize';
import FilePreview from './tabs/filePreview';

enum Tabs {
  PREVIEW,
  BACKGROUND,
  CROP
}

type ModalsProps = Pick<Props, 'maxWidth' | 'maxHeight' | 'type'> & {
  show: boolean;
  file: File;
  onCloseModal: () => void;
  submitFileUpload: (file: File, fileSizes: ImageSize) => Promise<boolean>;
};

const EditModal:FunctionComponent<ModalsProps> = (props) => {
  const { type, maxWidth, maxHeight, file: initFile, onCloseModal, submitFileUpload } = props;

  const [ showModal, onChangeShowModal ] = useState<Tabs | null>(null);
  const [ file, setFile ] = useState<File>(initFile);
  const [ fileUrl, onChangeFileUrl ] = useState<string | ArrayBuffer | null>(null);
  const [ originalFile, setOriginalFile ] = useState<File>(initFile);
  const [ originalFileUrl, onChangeOriginalFileUrl ] = useState<string | ArrayBuffer | null>(null);

  const imageRef = useRef<HTMLImageElement | null>(null);

  const { onInitializeImageSizes, sizes, setSize, changeImageSize } = useImageSize({ maxHeight, maxWidth, files: [file] });

  useEffect(() => {
    setFile(initFile);
    setOriginalFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(initFile);
    onChangeShowModal(Tabs.PREVIEW);
    reader.onload = () => {
      onChangeFileUrl(reader.result);
      onChangeOriginalFileUrl(reader.result);
      const preloadImage = new Image();
      preloadImage.src = reader.result as any;
      preloadImage.onload = () => setSize([onInitializeImageSizes(preloadImage)]);
    };
    reader.onerror = error => message.error(error);
  }, [initFile]);

  const resetImage = () => {
    onChangeFileUrl(originalFileUrl);
    setFile(originalFile);
    const preloadImage = new Image();
    preloadImage.src = originalFileUrl as any;
    preloadImage.onload = () => setSize([onInitializeImageSizes(preloadImage)]);
  };

  const onSubmit = useCallback((url: string | ArrayBuffer | null, file: File, newSizes: any) => {
    if (file.size > FILE_TYPES_SIZE_MAPPING[type]) {
      message.error(`Generated file size is higher than ${FILE_TYPES_SIZE_MAPPING[type] / 1024}KB`);
      return;
    }
    onChangeFileUrl(url);
    setFile(file);
    if (newSizes) setSize([newSizes]);
    onChangeShowModal(Tabs.PREVIEW);
  }, [setSize, type]);

  const onChangeModalBody = useCallback((type: Tabs) => {
    if (!file) return;
    onChangeShowModal(type);
  }, [file]);

  useEffect(() => {
    if (imageRef.current) {
      onInitializeImageSizes(imageRef.current);
    }
  }, [showModal, imageRef.current]);

  const disableTabs = !file || UNREZIZABLE_MIME_TYPES.includes(file?.type);

  const tabs = [
    { label: 'Preview Image', value: Tabs.PREVIEW, icon: 'img' },
    { label: 'Crop Image', value: Tabs.CROP, icon: 'crop' },
    { label: 'Remove Background', value: Tabs.BACKGROUND, icon: 'bg' },
  ];

  const onSubmitForm = () => {
    if (file) submitFileUpload(file, sizes[0]);
  };

  const onChangeSize = (event: any, type: string) => {
    changeImageSize(event, type, 0);
  };

  const bodyTypes = useMemo(() => ({
    [Tabs.PREVIEW]: (
      <FilePreview
        file={file}
        fileUrl={fileUrl as string}
        sizes={sizes[0]}
        changeImageSize={onChangeSize}
        resetImage={resetImage}
        imageRef={imageRef}
        onSubmit={onSubmitForm}
        onCloseModal={onCloseModal}
      />
    ),
    [Tabs.BACKGROUND]: (
      <BackgroundEditor
        maxSize={FILE_TYPES_SIZE_MAPPING[type]}
        fileUrl={fileUrl}
        originalFile={originalFileUrl}
        onSubmit={onSubmit}
        onCloseModal={onCloseModal}
      />
    ),
    [Tabs.CROP]: (
      <CropBox
        fileUrl={fileUrl}
        type={file?.type}
        onSubmit={onSubmit}
        onChangeSizes={setSize}
        onCloseModal={onCloseModal}
        sizes={sizes[0]}
      />
    ),
  }), [file, sizes, changeImageSize, type, fileUrl, originalFileUrl, onSubmit, onCloseModal, setSize]);

  return (
    <div className={styles.modalWrapper}>
      {
        !disableTabs && (
          <div className={styles.tabsWrapper}>
            { tabs.map((item) => (
              <div className={cx(styles.tab, {[styles.active]: showModal === item.value}, {[styles.disabled]: !file})} typeof="button" onClick={() => onChangeModalBody(item.value)}>
                <span className={styles.tabIcon}>
                  <Icon icon={item.icon as any} active={showModal === item.value}/>
                </span>
                <Typo type='p' white className={styles.label}>{ item.label }</Typo>
              </div>
            ))}
          </div>
        )
      }
      { showModal !== null ? bodyTypes[showModal] : null }
    </div>
  );
};

export default EditModal;
