import React, { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import styles from '../../../upload.module.css';
import { message } from 'antd';
import { times } from 'lodash';
import { WhiteButton, WhiteInput } from '../../../../index';
import CropPoint from './croppoint';

type Props = {
  type?: string;
  fileUrl: string | ArrayBuffer | null;
  onSubmit: (values: string | ArrayBuffer | null, file: File, sizes: any) => void;
  onChangeSizes: (sizes: any) => void;
  onCloseModal: () => void;
  sizes: Record<string, any>;
  isMultiAndEditing?: boolean;
}

let dragStartWrapper: any = null;

const CropBox:FunctionComponent<Props> = ({ fileUrl, onSubmit, onCloseModal, onChangeSizes, type, isMultiAndEditing = false, sizes }) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const cropWrapperRef = useRef<HTMLDivElement | null>(null);
  const cropAreaRef = useRef<HTMLDivElement | null>(null);
  const [dragging, onDragging] = useState<boolean>(false);
  const [ cropSizes, onChangeAreaPosition ] = useState<any>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const image = imageRef.current;
    if (image) {
      onChangeAreaPosition({
        top: image.offsetHeight * 0.2,
        left: image.offsetWidth * 0.2,
        width: image.offsetWidth * 0.6,
        height: image.offsetHeight * 0.6,
      });
      onSizesChange(image.offsetWidth * 0.6, image.offsetHeight * 0.6);
    }
  }, [imageRef.current]);

  const startDrag = (e: any) => {
    e.stopPropagation();
    onDragging(true);
    dragStartWrapper = ({
      x: e.clientX,
      y: e.clientY,
    })
  }

  const endDrag = (e: any) => {
    e.stopPropagation();
    onDragging(false);
    dragStartWrapper = null;
  }

  useEffect(() => {
    const cropArea = cropAreaRef.current;
    const wrapper = cropWrapperRef.current;
    const containerRef = container.current;
    if (!cropArea || !wrapper || !containerRef) return;
    cropArea.addEventListener('pointerdown', startDrag);
    wrapper.addEventListener('pointerup', endDrag);
    wrapper.addEventListener('pointermove', dragArea);
    containerRef.addEventListener('pointerout', endDrag);
    return () => {
      cropArea.removeEventListener('pointerdown', startDrag);
      wrapper.removeEventListener('pointerup', endDrag);
      wrapper.removeEventListener('pointermove', dragArea);
      containerRef.removeEventListener('pointerout', endDrag);
    };
  }, [cropAreaRef.current, cropWrapperRef.current, cropSizes, dragging, container.current]);

  const dragArea = (event: any) => {
    if (!dragging || !dragStartWrapper || !cropAreaRef?.current || !cropWrapperRef.current) return;
    event.stopPropagation();
    const newX = dragStartWrapper.x - event.clientX;
    const newY = dragStartWrapper.y - event.clientY;
    dragStartWrapper = ({ x: event.clientX, y: event.clientY });
    let left = cropAreaRef.current.offsetLeft - newX;
    let top = cropAreaRef.current.offsetTop - newY;

    if (left < 0 ) {
      left = 0;
    }

    if (top < 0 ) {
      top = 0;
    }

    if (left + cropSizes.width >= cropWrapperRef.current.offsetWidth) {
      left = cropWrapperRef.current.offsetWidth - cropSizes.width;
    }

    if (top + cropSizes.height >= cropWrapperRef.current.offsetHeight) {
      top = cropWrapperRef.current.offsetHeight - cropSizes.height;
    }

    if (
      left < 0 ||
      top < 0 ||
      left + cropSizes.width > cropWrapperRef.current.offsetWidth ||
      top + cropSizes.height > cropWrapperRef.current.offsetHeight
    ) {
      return;
    }

    onChangeAreaPosition({ ...cropSizes, left, top });
  }

  const onSizesChange = useCallback((width: number, height: number) => {
    const image = imageRef.current;

    if (image) {
      const normalizedWidth = Math.round(image.naturalWidth / image.offsetWidth * width);
      const normalizedHeight = Math.round(image.naturalHeight / image.offsetHeight * height);

      onChangeSizes([{
        width: normalizedWidth,
        height: normalizedHeight,
        xy: normalizedHeight / normalizedWidth,
        yx: normalizedWidth / normalizedHeight
      }]);
    }
  }, [imageRef.current]);

  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const image = imageRef.current;
    const canvas = previewCanvasRef.current;
    if (!image || !canvas || !cropSizes) return;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropSizes.width * scaleX;
    canvas.height = cropSizes.height * scaleY;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.drawImage(
      image,
      cropSizes.left * scaleX,
      cropSizes.top * scaleY,
      cropSizes.width * scaleX,
      cropSizes.height * scaleY,
      0,
      0,
      cropSizes.width * scaleX,
      cropSizes.height * scaleY
    );

  }, [cropSizes]);

  const submit = useCallback(() => {
    const canvas = previewCanvasRef.current;

    if (!canvas) return;

    const newImageSizes = {
      width: canvas.width,
      height: canvas.height,
      xy: canvas.height / canvas.width,
      yx: canvas.width / canvas.height
    };

    canvas.toBlob((blob ) => {
      const file = new File([blob as BlobPart], `${Date.now()}`, { type: type || 'image/png' });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => onSubmit(reader.result, file, newImageSizes);
      reader.onerror = error => message.error(error);
    }, (type || 'image/png'));
  }, [previewCanvasRef.current, type]);

  return (
    <Fragment>
      <div className={styles.sidesWrapper}>
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            width: Math.round(cropSizes?.width ?? 0),
            height: Math.round(cropSizes?.height ?? 0)
          }}
        />
        <div className={styles.leftSide} ref={container}>
          <div className={styles.cropWrapper}>
            <div className={styles.cropImageWrapper} ref={cropWrapperRef}>
              <img
                ref={imageRef}
                //@ts-ignore
                src={fileUrl}
                className={styles.imagePreview}
                alt="crop"
              />
              <div className={styles.cropBox} ref={cropAreaRef} style={cropSizes}>
                {
                  times(8, (i: number) => <CropPoint key={i} position={i} cropSizes={cropSizes} cropWrapperRef={cropWrapperRef} containerRef={container} onChangeCropPoint={(value) => onChangeAreaPosition(value)} onDragEnd={onSizesChange}/>)
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div>
            <div className={styles.imageSizesWrapper}>
              <WhiteInput label="Width" labelWidth={44} inline tiny disabled name="mock-component-width" type="number" value={sizes?.width}/>
              <span className={styles.chainIcon}>
                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M1.47186 7.42224L2.31457 6.57953C2.70707 6.18703 3.34343 6.18703 3.73593 6.57953C4.10039 6.94399 4.12643 7.51872 3.81403 7.91322L3.73593 8.00089L2.89322 8.8436C1.71573 10.0211 1.71573 11.9302 2.89322 13.1077C4.02866 14.2431 5.84437 14.2837 7.0284 13.2293L7.15729 13.1077L8 12.265C8.3925 11.8725 9.02886 11.8725 9.42136 12.265C9.78582 12.6294 9.81185 13.2041 9.49946 13.5986L9.42136 13.6863L8.57864 14.529C6.61616 16.4915 3.43435 16.4915 1.47186 14.529C-0.438977 12.6182 -0.489262 9.55134 1.32101 7.57966L1.47186 7.42224ZM7.42224 1.47186C9.38472 -0.490621 12.5665 -0.490621 14.529 1.47186C16.4915 3.43435 16.4915 6.61616 14.529 8.57864L13.6863 9.42136C13.2938 9.81385 12.6575 9.81385 12.265 9.42136C11.8725 9.02886 11.8725 8.3925 12.265 8L13.1077 7.15729C14.2852 5.9798 14.2852 4.07071 13.1077 2.89322C11.9302 1.71573 10.0211 1.71573 8.8436 2.89322L8.00089 3.73593C7.60839 4.12843 6.97203 4.12843 6.57953 3.73593C6.18703 3.34343 6.18703 2.70707 6.57953 2.31457L7.42224 1.47186ZM8.42172 6.15765C8.81422 5.76516 9.45058 5.76516 9.84308 6.15765C10.2356 6.55015 10.2356 7.18651 9.84308 7.57901L7.57901 9.84308C7.18651 10.2356 6.55015 10.2356 6.15765 9.84308C5.76516 9.45058 5.76516 8.81422 6.15765 8.42172L8.42172 6.15765Z"/>
                </svg>
              </span>
              <WhiteInput label="Height" labelWidth={44} inline tiny disabled name="mock-component-height" type="number" value={sizes?.height}/>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <WhiteButton size="lg" className={styles.footerBtn} type="ghost" onClick={onCloseModal}>{ isMultiAndEditing ? 'Back' : 'Cancel'}</WhiteButton>
          <WhiteButton size="lg" className={styles.footerBtn} onClick={submit}>Apply</WhiteButton>
        </div>
      </div>
    </Fragment>
  );
};

export default CropBox;
