import { ImmutableObject } from 'seamless-immutable';
import { TierPlan } from '../../utils/type/tierPlans/tierPlan.type';

export enum TierPlansStoreEnum {
  TIER_PLANS = 'tier_plans',
}

export type TierPlansState = ImmutableObject<{
  [TierPlansStoreEnum.TIER_PLANS]: TierPlan[];
}>;
