import { ImmutableObject } from 'seamless-immutable';
import { Pagination } from '../types';
import { TokenCampaignUnlocking } from '../../utils/type/tokens/tokenCampaignUnlocking.type';
import { TokenPurchaseHistory } from '../../utils/type/tokens/tokenPurchaseHistory.type';
import { TokenPurchase } from '../../utils/type/tokens/tokenPurchase.type';
import { TokenUsageHistory } from '../../utils/type/tokens/tokenUsageHistory.type';

export enum TokensStoreEnum {
  CAMPAIGN_UNLOCK_BY_TOKEN = 'campaign_unlock_by_token',
  TOKEN_USAGE_HISTORY = 'token_usage_history',
  TOKEN_PURCHASE = 'token_purchase',
  TOKEN_PURCHASE_HISTORY = 'token_purchase_history',
}

export type TokensState = ImmutableObject<{
  [TokensStoreEnum.CAMPAIGN_UNLOCK_BY_TOKEN]: TokenCampaignUnlocking[];
  [TokensStoreEnum.TOKEN_PURCHASE_HISTORY]: Pagination<TokenPurchaseHistory>;
  [TokensStoreEnum.TOKEN_USAGE_HISTORY]: Pagination<TokenUsageHistory>,
  [TokensStoreEnum.TOKEN_PURCHASE]: TokenPurchase[];
}>;
