import I from 'seamless-immutable';
import { BrandFont, Font, UIStateType, UIStoreEnum } from './types';

export function fontAdapter(data: {family: string}) {
  return {
    label: data.family,
    value: data.family,
  };
}

export function fontsAdapter(data?: any[]): Font[] {
  if (!data) return [];
  return data.map(fontAdapter)
}

export function brandFontAdapter(data: BrandFont) {
  return {
    title: data.title,
    id: data.id,
    url: data.url
  };
}

export function brandFontsAdapter(data: BrandFont[]) {
  return data.map(brandFontAdapter);
}

export function uiStoreAdapter() {
  return I({
    fonts: fontsAdapter(),
    disableSidebar: false,
    fullPreview: false,
    spinner: 0,
    sidebar: false,
    sidebarType: 'full',
    restrictionModalText: '',
    restrictionModalShow: false,
    brandFonts: [],
    disableBodyScroll: false,
    debugState: 'game',
    adactTour: {
      index: 0,
      currentSelector: '',
      tourEndMenu: true
    },
    [UIStoreEnum.CAMPAIGN_LIST_IMAGE]: 1,
    [UIStoreEnum.DEBUG_ADVENT_DAY]: 0,
    [UIStoreEnum.LOADING_OVERLAY]: false,
    [UIStoreEnum.SHOW_ACTIVE_BRAND_EDIT_MODAL]: false,
  } as UIStateType);
}
