import React, { FunctionComponent, useEffect, useState } from 'react';
import AntInput, { InputProps } from 'antd/lib/input';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './colorInput.module.css';
import Field from '../field';

interface Props extends InputProps {
  className?: string;
  label?: string;
  onBlurCallback?: () => void;
  name: string;
  refProp?: any;
  disabled?: boolean;
  transparent?: boolean;
  errors?: string[]
  withoutBorder?: boolean;
}

const ColorInput:FunctionComponent<Props> = (props) => {
  const { className, label, refProp, errors, value, name, transparent, disabled, withoutBorder, onBlurCallback, onBlur, ...other } = props;
  const [ color, onChangeColor ] = useState(value);

  useEffect(() => {
    onChangeColor(props.value);
  }, [props.value]);

  const onChange = (event: any) => {
    const value = event.target.value;
    if (value[0] !== '#') return;
    onChangeColor(value);
    if (props.onChange) props.onChange(event);
  };

  const clear = () => {
    onChangeColor('transparent');
    //@ts-ignore
    if (props.onChange) props.onChange('transparent');
  };

  return (
    <Field className={className} label={label} errors={errors} withoutBorder={withoutBorder} disabled={disabled}>
      <label htmlFor="" className={styles.inputWrapper}>
        <input className={styles.valueInput} onChange={onChange} disabled={disabled} type="text" maxLength={7} value={color === 'transparent' ? '' : color} onBlur={onBlurCallback ? onBlurCallback : onBlur} cypress-id={`${name}-component-input`}/>
        <label className={cx(styles.color, {[styles.transparent]: color === 'transparent'})} style={{
          //@ts-ignore
          background: color
        }}>
          <AntInput type="color" className={styles.colorInput} value={value === 'transparent' ? '' : value} disabled={disabled} {...other} name={name} onBlur={onBlurCallback ? onBlurCallback : onBlur} ref={refProp} cypress-id={`${name}-component-colorpicker`}/>
        </label>
        {transparent && color !== 'transparent' ? <button type="button" onClick={clear} cypress-id={`${name}-component-clear`} className={styles.clearBtn}><FontAwesomeIcon icon={faTimes}/></button> : null}
      </label>
    </Field>
  );
};

export default ColorInput;
