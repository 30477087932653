import React, { FunctionComponent } from 'react';
import WhiteButton from '../../../whiteButton';
import Input from '../../../whiteInput';
import styles from '../../upload.module.css';
import UNREZIZABLE_MIME_TYPES from '../../../../../utils/const/unresizable-mime-types';

type Props = {
  imageRef: any;
  file: File | null;
  fileUrl: string;
  sizes: any;
  resetImage: () => void;
  changeImageSize: (event: any, type: string) => void;
  onSubmit: () => void;
  onCloseModal: () => void;
};

const FilePreview:FunctionComponent<Props> = (props) => {
  const { imageRef, file, sizes, resetImage, changeImageSize, fileUrl, onSubmit, onCloseModal } = props;

  return (
    <>
      <div className={styles.sidesWrapper}>
        <div className={styles.leftSide}>
          <div className={styles.imageWrapper}>
            <img
              className={styles.imagePreview}
              ref={imageRef}
              draggable={false}
              src={fileUrl}
              alt="preview"
            />
          </div>
        </div>
        {
          file && !UNREZIZABLE_MIME_TYPES.includes(file?.type) ?
            <div className={styles.rightSide}>
              <div>
                <div className={styles.imageSizesWrapper}>
                  <Input label="Width" labelWidth={44} inline tiny name="image-width" type="number" value={sizes?.width}
                         onChange={(event) => changeImageSize(event, 'width')}/>
                  <span className={styles.chainIcon}>
                  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.47186 7.42224L2.31457 6.57953C2.70707 6.18703 3.34343 6.18703 3.73593 6.57953C4.10039 6.94399 4.12643 7.51872 3.81403 7.91322L3.73593 8.00089L2.89322 8.8436C1.71573 10.0211 1.71573 11.9302 2.89322 13.1077C4.02866 14.2431 5.84437 14.2837 7.0284 13.2293L7.15729 13.1077L8 12.265C8.3925 11.8725 9.02886 11.8725 9.42136 12.265C9.78582 12.6294 9.81185 13.2041 9.49946 13.5986L9.42136 13.6863L8.57864 14.529C6.61616 16.4915 3.43435 16.4915 1.47186 14.529C-0.438977 12.6182 -0.489262 9.55134 1.32101 7.57966L1.47186 7.42224ZM7.42224 1.47186C9.38472 -0.490621 12.5665 -0.490621 14.529 1.47186C16.4915 3.43435 16.4915 6.61616 14.529 8.57864L13.6863 9.42136C13.2938 9.81385 12.6575 9.81385 12.265 9.42136C11.8725 9.02886 11.8725 8.3925 12.265 8L13.1077 7.15729C14.2852 5.9798 14.2852 4.07071 13.1077 2.89322C11.9302 1.71573 10.0211 1.71573 8.8436 2.89322L8.00089 3.73593C7.60839 4.12843 6.97203 4.12843 6.57953 3.73593C6.18703 3.34343 6.18703 2.70707 6.57953 2.31457L7.42224 1.47186ZM8.42172 6.15765C8.81422 5.76516 9.45058 5.76516 9.84308 6.15765C10.2356 6.55015 10.2356 7.18651 9.84308 7.57901L7.57901 9.84308C7.18651 10.2356 6.55015 10.2356 6.15765 9.84308C5.76516 9.45058 5.76516 8.81422 6.15765 8.42172L8.42172 6.15765Z"/>
                  </svg>
                </span>
                  <Input label="Height" labelWidth={44} inline tiny name="image-width" type="number" value={sizes?.height}
                         onChange={(event) => changeImageSize(event, 'height')}/>
                </div>
                <div className={styles.btnsWrapper}>
                  <WhiteButton className={styles.btn} onClick={resetImage}>Reset all changes</WhiteButton>
                </div>
              </div>
            </div>
            : null
        }
      </div>
      <div className={styles.footerWrapper}>
        <div className={styles.footer}>
          <WhiteButton size="lg" className={styles.footerBtn} type="ghost" onClick={onCloseModal}>Cancel</WhiteButton>
          <WhiteButton size="lg" className={styles.footerBtn} onClick={onSubmit}>Upload</WhiteButton>
        </div>
      </div>
    </>
  );
};

export default FilePreview;
