import { take } from 'lodash';
import { createAction } from '../../utils/actions';
import { commonStoreAdapter, domainsAdapter, gameAdapter, screenTemplateAdapter } from './adapters';
import { CommonStoreEnum } from './types';
import { ImmutableObject } from 'seamless-immutable';
import { landingScreenElementAdapter } from '../campaign/adapters';
import { licenseAdapter } from './account-licenses/adapters';

export const setCommonField = createAction('common/set_field');
export const updateCommonArrayField = createAction('common/update_array_field');
export const addItemInPagination = createAction('common/add_item_to_pagination');
export const removeItemInPagination = createAction('common/remove_item_in_pagination');

export const arrayFields: any[] = [
  CommonStoreEnum.BRAND_TITLES,
  CommonStoreEnum.SCREEN_TEMPLATES,
  CommonStoreEnum.EMAIL_RECIPIENTS,
  CommonStoreEnum.FOLDERS,
  CommonStoreEnum.LICENSES,
  CommonStoreEnum.GAMES,
  CommonStoreEnum.GAME_QUESTIONS_ANALYTICS,
  CommonStoreEnum.PLAYERS_WITH_QUESTIONS_ANALYTICS,
  CommonStoreEnum.MEDIA_FILES,
];

export const nullableFields: any[] = [
  CommonStoreEnum.COPY_ELEMENT,
  CommonStoreEnum.DOMAIN,
  CommonStoreEnum.ACTIVE_FOLDER,
  CommonStoreEnum.SHOW_TICKET,
  CommonStoreEnum.SYSTEM_NOTIFICATION_PREVIEW,
];

export const commonStoreAdapterMapping: Partial<Record<any, (data: any) => ImmutableObject<any>>> = {
  [CommonStoreEnum.DOMAIN]: domainsAdapter,
  [CommonStoreEnum.SCREEN_TEMPLATES]: screenTemplateAdapter,
  [CommonStoreEnum.BRAND_TITLES]: screenTemplateAdapter,
  [CommonStoreEnum.COPY_ELEMENT]: landingScreenElementAdapter,
  [CommonStoreEnum.LICENSES]: licenseAdapter,
  [CommonStoreEnum.GAMES]: gameAdapter,
};

export type Action = {
  type: string;
  payload: any;
  path: any;
  compareField?: string;
}

const reducer = (state = commonStoreAdapter(), action: Action) => {
  const adapter: any = commonStoreAdapterMapping[action.path];

  switch (action.type) {
    case setCommonField.type: {
      if (adapter) {
        if (!action.payload && nullableFields.includes(action.path)) {
          return state.set(action.path, null);
        }

        const data = arrayFields.includes(action.path) ? action.payload.map(adapter) : adapter(action.payload);
        return state.set(action.path, data);
      }

      return state.set(action.path, action.payload);
    }

    case addItemInPagination.type: {
      const itemsPerPage = (state as Record<string, any>)[action.path as any]?.meta?.itemsPerPage;

      return state
        .update(action.path, (pagination: any) => pagination.update('items', (items: any) => {
          const newItems = [action.payload, ...(items as unknown as any[])];

          if (itemsPerPage) {
            return take(newItems, itemsPerPage);
          }

          return newItems;
        }));
    }

    case removeItemInPagination.type: {
      return state
        .update(action.path, (pagination: any) => pagination.update('items', (items: any) => (items as unknown as any[]).filter((item) => {
          if (Array.isArray(action.payload)) {
            return !action.payload.includes(item.id);
          }

          return item.id !== action.payload;
        })));
    }

    case updateCommonArrayField.type: {
      return state.set(action.path, [ ...action.payload, ...(state as any)[action.path]]);
    }

    default:
      return state;
  }

};

export default reducer;
