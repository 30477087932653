import React, { Fragment, FunctionComponent } from 'react';
import cx from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '../icon';
import styles from './spinner.module.css';
import Typo from '../typo';

type Props = {
  className?: string;
  text?: string;
  white?: boolean;
}

const Spinner:FunctionComponent<Props> = ({ className, text, white }) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      {white
        ?
          <Fragment>
            <div className={styles.overlay}/>
            <Icon icon="spinner" className={styles.white}/>
            {text ? <Typo className={styles.text} white type="h4">{text}</Typo> : null}
          </Fragment>
        : <LoadingOutlined />
      }
    </div>
  );
};

export default Spinner;
