import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import { message, notification } from 'antd';
import * as T from './requestTypes';
import errorParser, { Errors } from '../utils/errors';
import addyConfirm from '../components/confirm';
import makeSlug from '../utils/slug';
import {
  addBrand,
  removeBrand,
  setActiveBrand,
  setActiveBrandId,
  setActiveSettingsBrand,
  setActiveSettingsBrandId,
  setBrands,
  updateBrand,
  updateBrandInAccount,
} from '../store/auth/actions';
import {
  activeBrandIdSelector,
  activeBrandSelector,
  activeSettingsBrandIdSelector,
  activeSettingsBrandSelector,
  brandsAccountSelector,
  brandsForFilterSelector,
} from '../store/auth/selectors';
import {
  decreaseSpinner,
  increaseSpinner,
  setRestrictionModalText, setUIField,
  showRestrictionModal
} from '../store/ui/actions';
import useApi from './useApi';
import { updateLandingPageElementsParams } from '../store/campaign/actions';
import { clearCampaigns } from '../store/campaigns/actions';
import { hasFullAccess } from '../utils/helpers/hasFullAccess';
import { Brand, BrandForFilter, BrandInList } from '../utils/type';
import useCurrentUser from '../utils/hooks/useCurrentUser';
import { useBrandLimitNotification } from '../utils/hooks/useBrandLimitNotification';
import { FormConfigs } from '@kalevkarpuk/all-new-adact-shareable-components/dist/elements/form';
import { ButtonConfigs } from '@kalevkarpuk/all-new-adact-shareable-components/dist/elements/button';
import { ImageConfigs } from '@kalevkarpuk/all-new-adact-shareable-components/dist/elements/image';
import { UIStoreEnum } from '../store/ui/types';

export type Visual = {
  default: {
    image: boolean;
    button: boolean;
    registration_form: boolean;
  };
  image: ImageConfigs;
  texts: any[];
  button: ButtonConfigs;
  registration_form: FormConfigs;
}

type BrandForHeader = {
  value: number;
  label: string;
  to?: string;
  divider?: boolean;
}

interface iBrands {
  create: (data: T.CreateBrandData, cb?: () => void) => void;
  onSelectBrand: (value: number | string) => void;
  activeBrandId: number;
  onSelectSettingsBrand: (value: number | string) => void;
  activeSettingsBrandId: number;
  busy: boolean;
  brandHasCampaigns: boolean;
  brandsForHeader: BrandForHeader[];
  update: (id: number, data: T.UpdateBrandData, silent?: boolean, setActiveBrand?: boolean) => void;
  updateInList: (id: number, data: T.UpdateBrandData, cb?: () => void) => void;
  deleteBrand: (id: number) => void;
  newBrandDestroy: (id: number, cb?: () => void) => void;
  destroy: (id: number) => void;
  getBrandById: (id: number | string) => void;
  getAdminAccountBrands: (id: string) => void;
  makeSlug: (title: string) => string;
  errors: Errors;
  brands: BrandInList[];
  getBrands: () => void;
  getBrand: (id: number) => void;
  brand: Brand;
  activeSettingsBrand: Brand | undefined;
  updateVisual: (visual_settings: string, silent?: boolean, updateCampaignDefaultVisuals?: boolean, cb?: () => void) => void;
  newUpdateVisual: (id: number, brand: Partial<Brand>, silent?: boolean, updateCampaignDefaultVisuals?: boolean) => void;
  visualSettings: Visual;
  settingBrandVisualSettings: Visual;
}

const useBrand = ():iBrands => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activeBrand = useSelector(activeBrandSelector);
  const activeBrandId = useSelector(activeBrandIdSelector);
  const activeSettingsBrandId = useSelector(activeSettingsBrandIdSelector);
  const activeSettingsBrand = useSelector(activeSettingsBrandSelector);
  const brands = useSelector(brandsAccountSelector);
  const brandsForFilter = useSelector(brandsForFilterSelector);
  const { user, account } = useCurrentUser();
  const visualSettings = JSON.parse(activeBrand.visual_settings);
  const settingBrandVisualSettings = JSON.parse(activeSettingsBrand?.visual_settings || "{}");
  const showBrandLimit = useBrandLimitNotification();

  const api = useApi();
  const [ errors, onChangeErrors ] = useState({});
  const [ busy, onChangeBusy ] = useState<boolean>(false);

  const create = (data: T.CreateBrandData, cb?: () => void) => {
    clearErrors();
    onChangeBusy(true);
    api.createBrand(data)
      .then((response: AxiosResponse) => {
        dispatch(addBrand(response.data));
        if (cb) cb();
        onChangeBusy(false);
        dispatch(setActiveBrandId(response.data.id));
        dispatch(setActiveBrand(response.data));
        moment.tz.setDefault(response.data.timezone);
        history.push('/campaigns');
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
        if (error.response?.status === 409) {
          dispatch(showRestrictionModal());
          dispatch(setRestrictionModalText(error.response.data?.message || ''));
          return;
        }
        if (error.response?.status === 400) {
          message.error(error.response?.data.message);
        }
        if (error.response?.status === 403) {
          notification.error({
            message: 'Error',
            description: error.response?.data.message
          });
        }
      });
  };

  const updateInList = (id: number, data: T.UpdateBrandData, cb?: () => void) => {
    clearErrors();
    onChangeBusy(true);
    api.updateBrand(id, data)
      .then((response: AxiosResponse) => {
        notification.success({
          message: 'Info',
          description: 'Brand was successfully updated'
        });
        if (cb) cb();
        dispatch(setActiveSettingsBrand(response.data));
        dispatch(updateBrandInAccount(response.data));
        if (activeBrand.id === id) {
          dispatch(updateBrand(response.data));
        }
        onChangeBusy(false);
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
      });
  };

  const update = (id: number, data: T.UpdateBrandData, silent?: boolean, setActiveBrand?: boolean) => {
    clearErrors();
    onChangeBusy(true);
    api.updateBrand(id, data)
      .then((response: AxiosResponse) => {
        if (!silent) {
          notification.success({
            message: 'Info',
            description: 'Brand was successfully updated'
          });
        }
        onChangeBusy(false);
        moment.tz.setDefault(response.data.timezone);
        dispatch(updateBrand(response.data));
        if (setActiveBrand) {
          dispatch(setActiveSettingsBrand(response.data));
        }
      })
      .catch((error: AxiosError) => {
        onChangeBusy(false);
        if (error.response?.data.statusCode === 422) {
          const errors = errorParser(error.response?.data);
          onChangeErrors(errors);
        }
      });
  };

  const destroy = (id: number) => {
    api.destroyBrand(id);
  };

  const newBrandDestroy = (id: number, cb?: () => void) => {
    addyConfirm({
      title: 'Hmm...',
      content: 'Are you sure you want delete this brand?',
      okText: 'Yep, delete it',
      onOk: () => api.destroyBrand(id)
        .then(() => {
          dispatch(removeBrand(id));
          const availableBrands = brandsForFilter.filter((brand: BrandForFilter) => brand.id !== id);
          if (availableBrands.length === 0) {
            dispatch(setActiveBrandId(0));
            dispatch(setActiveBrand());
          } else {
            if (activeSettingsBrandId === id) {
              onSelectSettingsBrand(availableBrands[0].id);
            }
            dispatch(setActiveBrandId(availableBrands[0].id));
            dispatch(setActiveBrand(availableBrands[0]));
          }
          if (cb) cb();
          message.success('Brand was successfully deleted');
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 400) {
            message.error(error.response?.data.message);
          }
          if (error.response?.status === 403) {
            notification.error({
              message: 'Error',
              description: error.response?.data.message
            });
          }
        })
    });
  };

  const deleteBrand = (id: number) => {
    dispatch(increaseSpinner());
    api.destroyBrand(id)
      .then(() => {
        dispatch(decreaseSpinner());
        dispatch(removeBrand(id));

        const availableBrands = brands.filter((brand: BrandInList) => brand.id !== id);
        if (availableBrands.length === 0) {
          dispatch(setActiveBrandId(0));
          dispatch(setActiveBrand());
        } else {
          dispatch(setActiveBrandId(availableBrands[0].id));
          dispatch(setActiveBrand(availableBrands[0]));
        }

        history.push('/campaigns');
        message.success('Brand was successfully deleted');
      })
      .catch((error: AxiosError) => {
        dispatch(decreaseSpinner());
        if (error.response?.status === 400) {
          message.error(error.response?.data.message);
        }
        if (error.response?.status === 403) {
          notification.error({
            message: 'Error',
            description: error.response?.data.message
          });
        }
      });
  };


  const clearErrors = useCallback(() => onChangeErrors({}), []);

  const getBrandById = (id: number | string) => {
    dispatch(increaseSpinner());
    api.getBrand(id)
      .then((response: AxiosResponse) => {
        dispatch(setActiveBrand(response.data));
        dispatch(decreaseSpinner());
      })
      .catch(() => {
        dispatch(decreaseSpinner());
      });
  }

  const updateVisual = (visual_settings: string, silent?: boolean, updateCampaignDefaultVisuals?: boolean, cb?: () => void) => {
    api.updateBrand(activeBrandId, {
      visual_settings
    }).then((response: AxiosResponse) => {
      dispatch(updateBrand(response.data));
      if (!silent) {
        message.success('Brand visual styles was successfully updated');
      }
      if (updateCampaignDefaultVisuals) {
        dispatch(updateLandingPageElementsParams(JSON.parse(visual_settings)));
      }
      if (cb) cb();
    })
  }

  const newUpdateVisual = (id: number, brand: Partial<Brand>, silent?: boolean, updateCampaignDefaultVisuals?: boolean) => {
    api.updateBrand(id, brand).then((response: AxiosResponse) => {
      dispatch(setActiveSettingsBrand(response.data));
      const visual_settings: string = brand.visual_settings as string;
      if (!silent) {
        message.success('Brand visual styles was successfully updated');
      }
      if (updateCampaignDefaultVisuals) {
        dispatch(updateLandingPageElementsParams(JSON.parse(visual_settings)));
      }
    })
  }

  const getBrands = () => {
    api.getBrands()
      .then((response: AxiosResponse) => {
        dispatch(setBrands(response.data))
      });
  }

  const getAdminAccountBrands = (accountId: string) => {
    api.getAdminAccountBrands(accountId)
      .then((response: AxiosResponse) => {
        dispatch(setBrands(response.data))
      })
  }

  const brandList = useMemo(() => (hasFullAccess(user) ? [...account.brands] : [...user.brandsForFilter]), [user, account]);
  const brandsDisabled = useMemo(() => account.tierSettings?.brandsCountAllowed <= account.brands.length, [account]);
  const brandsForHeader = useMemo(() => (brandList as Pick<Brand, 'title' | 'id'>[]).map((brand: Pick<Brand, 'title' | 'id'>) => ({
    label: `${brand.title}`,
    value: brand.id
  })).concat(
    hasFullAccess(user)
      ? [{
        value: 'edit-brand',
        label: 'Edit brand',
        //@ts-ignore
        onClick: () => {
          dispatch(setUIField(true, UIStoreEnum.SHOW_ACTIVE_BRAND_EDIT_MODAL));
        },
        divider: account.brands.length > 0
      },{
        value: 'create-brand',
        label: '+ Create new brand',
        to: brandsDisabled ? undefined : '/create-brand',
        onClick: () => {
          if (brandsDisabled) {
            showBrandLimit();
          }
        },
        divider: account.brands.length > 0
      }]
      : []
  ), [brandList, user, account.brands.length, brandsDisabled, dispatch, showBrandLimit]);

  const onSelectBrand = useCallback((value: number | string) => {
    if (activeBrandId === value) return;
    dispatch(clearCampaigns());
    dispatch(setActiveBrandId(value));
    //@ts-ignore
    const chosenBrand = brandList.find((brand: Brand) => brand.id === value);
    moment.tz.setDefault(chosenBrand?.timezone);
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [brandList, activeBrandId]);

  const onSelectSettingsBrand = useCallback((value: number | string) => {
    if (activeSettingsBrandId === value) return;
    dispatch(setActiveSettingsBrandId(value));
    dispatch(setActiveBrandId(value));//@ts-ignore
    const chosenBrand = brandList.find((brand: Brand) => brand.id === value);
    moment.tz.setDefault(chosenBrand?.timezone);
  }, [activeSettingsBrandId, dispatch, brandList]);

  const getBrand = (id: number) => {
    dispatch(increaseSpinner());
    api.getBrand(id)
      .then((response: AxiosResponse) => {
        dispatch(setActiveSettingsBrand(response.data));
        dispatch(decreaseSpinner());
      })
      .catch(() => {
        dispatch(decreaseSpinner());
      });
  }

  const brandHasCampaigns = useMemo(() => {
    const currentBrandFromList = user.brandsForFilter.find((item: BrandForFilter) => item.id === activeBrandId);
    return Boolean(currentBrandFromList?.hasCampaigns);
  }, [user, activeBrandId]);

  return {
    brandHasCampaigns,
    onSelectBrand,
    getBrand,
    activeSettingsBrandId,
    onSelectSettingsBrand,
    brandsForHeader,
    create,
    updateInList,
    update,
    busy,
    getBrandById,
    newUpdateVisual,
    getAdminAccountBrands,
    brands,
    newBrandDestroy,
    getBrands,
    activeBrandId,
    updateVisual, //@ts-ignore
    visualSettings,
    settingBrandVisualSettings,
    brand: activeBrand,
    activeSettingsBrand,
    makeSlug,
    destroy,
    errors,
    deleteBrand
  }
};

export default useBrand;
