import { Action } from '../types';
import { ImmutableObject } from 'seamless-immutable';
import {
  adminAccountAdapter,
  adminAccountBrandAdapter,
  adminAccountCountryAdapter,
  adminAccountJoinedAdapter,
  adminAccountListAdapter,
  adminAccountOwner,
  adminAccountStatisticsAdapter,
  AdminAccountTierSettingsAdapter,
  AdminBrandAdapter,
  adminCouponAdapter,
  adminCouponsAdapter,
  adminGameAdapter,
  adminNewsPostAdapter,
  adminPlayerAdapter,
  adminStoreAdapter,
  campaignTemplateAdapter,
  clearList,
} from './adapters';
import { campaignsMetaAdapter } from '../campaigns/adapters';
import { campaignAdapter, campaignStatisticsAdapter } from '../campaign/adapters';
import { brandAdapter } from '../auth/adapters';
import {
  addAdminAccountBrand,
  clearAdminAccount,
  clearAdminAccounts,
  clearAdminAdminUser,
  clearAdminAdminUsers,
  clearAdminBrand,
  clearAdminBrands,
  clearAdminCampaigns,
  clearAdminCampaignsTemplate,
  clearAdminCampaignsTemplates,
  clearAdminCoupon,
  clearAdminGame,
  clearAdminGames,
  clearAdminInvitations,
  clearAdminNewsPost,
  clearAdminNewsPosts,
  clearAdminPlayers,
  clearCustomerSuccessLists,
  deleteCouponAction,
  removeAdminAccount,
  removeAdminAdminUser,
  removeAdminBrand,
  removeAdminCampaignsTemplate,
  removeAdminInvitation,
  removeAdminNewsPost,
  setAdminAccount,
  setAdminAccountDesign,
  setAdminAccountInvoiceStatus,
  setAdminAccountPayments,
  setAdminAccounts,
  setAdminAccountsCountries,
  setAdminAccountsJoined,
  setAdminAccountStatistics,
  setAdminAccountTierSettings,
  setAdminAdminUser,
  setAdminAdminUsers,
  setAdminBrand,
  setAdminBrands,
  setAdminCampaigns,
  setAdminCampaignsStatistics,
  setAdminCampaignsTemplate,
  setAdminCampaignsTemplates,
  setAdminCoupon,
  setAdminCouponList,
  setAdminGame,
  setAdminGames,
  setAdminInvitations,
  setAdminNewsPost,
  setAdminNewsPosts,
  setAdminPlayers,
  updateAdminAccountBrandAction,
  updateAdminAccounts,
  updateAdminAdminUser,
  updateAdminAdminUsers,
  updateAdminBrands,
  updateAdminCampaigns,
  updateAdminCampaignsStatistics,
  updateAdminCampaignsTemplate,
  updateAdminCampaignsTemplates,
  updateAdminNewsPost,
  updateAdminNewsPosts,
  updateAdminPlayer,
  updateAdminPlayers,
  updateOneBrandAdminBrands,
  updateOneCampaignAdminCampaigns
} from './actions';
import {
  AccountBrand,
  AdminAccountUser,
  AdminBrand,
  AdminCampaignTemplate,
  AdminInvitation,
  AdminListAccount,
  AdminNewsPost,
  AdminPlayer,
  AdminState
} from './types';

const initialStore = adminStoreAdapter()

const reducer = (state:AdminState = initialStore, action: Action) => {
  switch (action.type) {
    case setAdminCampaigns.type: {
      return state
        .setIn(['campaigns', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn(['campaigns', 'items'], action.payload.items.map(campaignAdapter));
    }
    case updateAdminCampaigns.type: {
      return state
        .setIn(['campaigns', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['campaigns', 'items'], items => items.concat(action.payload.items.map(campaignAdapter)));
    }

    case updateOneCampaignAdminCampaigns.type: {
      return state
        .updateIn(['campaigns', 'items'], items => items.map((item) => {
          if (item.id === action.payload.id) {
            return campaignAdapter(action.payload);
          }
          return item;
        }));
    }

    case clearAdminCampaigns.type: {
      return state
        .set('campaigns', clearList());
    }

    case setAdminGames.type: {
      return state.set('games', action.payload.map(adminGameAdapter));
    }

    case setAdminGame.type: {
      return state.set('game', adminGameAdapter(action.payload));
    }

    case clearAdminGames.type: {
      return state.set('games', []);
    }

    case clearAdminGame.type: {
      return state.set('game', adminGameAdapter());
    }

    case setAdminCouponList.type: {
      return state.set('coupons', adminCouponsAdapter(action.payload));
    }

    case deleteCouponAction.type: {
      return state.updateIn(['coupons', 'data'], data => data.filter((item) => item.id !== action.payload));
    }

    case setAdminCoupon.type: {
      return state.set('coupon', adminCouponAdapter(action.payload));
    }

    case clearAdminCoupon.type: {
      return state.set('coupon', adminCouponAdapter());
    }

    case clearAdminPlayers.type: {
      return state
        .set('players', clearList());
    }

    case setAdminPlayers.type: {
      return state
        .setIn(['players', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn(['players', 'items'], action.payload.items.map(adminPlayerAdapter));
    }

    case updateAdminPlayers.type: {
      return state
        .setIn(['players', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['players', 'items'], items => items.concat(action.payload.items.map(adminPlayerAdapter)));
    }

    case updateAdminPlayer.type: {
      return state
        .updateIn(['players', 'items'], items => items.map((player: AdminPlayer) => {
          if (player._id === action.payload._id) return adminPlayerAdapter(action.payload);
          return player;
        }));
    }

    case setAdminBrands.type: {
      return state
        .setIn(['brands', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn(['brands', 'items'], action.payload.items.map(AdminBrandAdapter));
    }

    case updateAdminBrands.type: {
      return state
        .setIn(['brands', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['brands', 'items'], items => items.concat(action.payload.items.map(AdminBrandAdapter)));
    }

    case removeAdminBrand.type: {
      return state
        .updateIn(['brands', 'items'], items => items.filter((brand: AdminBrand) => brand.id !== action.payload));
    }

    case updateOneBrandAdminBrands.type: {
      return state
        .updateIn(['brands', 'items'], items => items.map((item) => {
          if (item.id === action.payload.id) {
            return AdminBrandAdapter(action.payload);
          }
          return item;
        }));
    }

    case setAdminAccounts.type: {
      return state
        .setIn(['accounts', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn(['accounts', 'items'], action.payload.items.map(adminAccountListAdapter));
    }

    case updateAdminAccounts.type: {
      return state
        .setIn(['accounts', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['accounts', 'items'], items => items.concat(action.payload.items.map(adminAccountAdapter)));
    }

    case removeAdminAccount.type: {
      return state
        .updateIn(['accounts', 'items'], items => items.filter((item: ImmutableObject<AdminListAccount>) => item.id !== action.payload));
    }

    case clearAdminAccounts.type: {
      return state
        .set('accounts', clearList());
    }

    case setAdminAccount.type: {
      return state
        .set('account', adminAccountAdapter(action.payload));
    }

    case setAdminAccountInvoiceStatus.type: {
      return state
        .setIn(['account', 'last_invoice_status'], action.payload);
    }

    case setAdminAccountPayments.type: {
      return state
        .setIn(['account', 'disabled_payment'], action.payload);
    }

    case setAdminAccountDesign.type: {
      return state
        .setIn(['account', 'use_new_design'], action.payload);
    }

    case addAdminAccountBrand.type: {
      return state
        .updateIn(['account', 'brands'], brands =>  brands.concat([adminAccountBrandAdapter(action.payload)]));
    }

    case updateAdminAccountBrandAction.type: {
      return state
        .updateIn(['account', 'brands'], brands =>  brands.map((brand: ImmutableObject<AccountBrand>) => {
          if (action.payload.id === brand.id) {
            return adminAccountBrandAdapter(action.payload);
          }
          return brand;
        }));
    }

    case clearAdminAccount.type: {
      return state
        .set('account', adminAccountAdapter());
    }

    case clearAdminBrands.type: {
      return state
        .set('brands', clearList());
    }

    case setAdminBrand.type: {
      return state
        .set('activeBrand', brandAdapter(action.payload));
    }

    case setAdminAccountTierSettings.type: {
      return state
        .setIn(['account', 'tierSettings'], AdminAccountTierSettingsAdapter({...state.account.tierSettings, ...action.payload}));
    }

    case clearAdminBrand.type: {
      return state
        .set('activeBrand', brandAdapter());
    }

    case setAdminNewsPosts.type: {
      return state
        .setIn(['newsPosts', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn(['newsPosts', 'items'], action.payload.items.map(adminNewsPostAdapter));
    }

    case clearAdminNewsPosts.type: {
      return state
        .set('newsPosts', clearList());
    }

    case setAdminNewsPost.type: {
      return state
        .set('newsPost', adminNewsPostAdapter(action.payload));
    }

    case clearAdminNewsPost.type: {
      return state
        .set('newsPost', adminNewsPostAdapter());
    }

    case removeAdminNewsPost.type: {
      return state
        .updateIn(['newsPosts', 'items'], newsPosts => newsPosts.filter((post: AdminNewsPost) => post.id !== action.payload));
    }

    case updateAdminNewsPosts.type: {
      return state
        .setIn(['newsPosts', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['newsPosts', 'items'], items => items.concat(action.payload.items.map(adminNewsPostAdapter)));
    }

    case updateAdminNewsPost.type: {
      return state
        .updateIn(['newsPosts', 'items'], newsPosts => newsPosts.map((post: ImmutableObject<AdminNewsPost>) => {
          if (action.payload.id === post.id) {
            return adminNewsPostAdapter(action.payload);
          }
          return post;
        }));
    }


    // ADMINS
    case setAdminAdminUsers.type: {
      return state
        .setIn(['adminUsers', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['adminUsers', 'items'], action.payload.items.map(adminAccountOwner));
    }

    case clearAdminAdminUsers.type: {
      return state
        .set('adminUsers', clearList());
    }

    case setAdminAdminUser.type: {
      return state
        .set('adminUser', adminAccountOwner(action.payload));
    }

    case clearAdminAdminUser.type: {
      return state
        .set('adminUser', adminAccountOwner());
    }

    case removeAdminAdminUser.type: {
      return state
        .setIn(['adminUsers', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['adminUsers', 'items'], items => items.filter((admin: AdminAccountUser) => admin.id !== action.payload));
    }

    case updateAdminAdminUsers.type: {
      return state
        .setIn(['adminUsers', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['adminUsers', 'items'], items => items.concat(action.payload.items.map(adminAccountOwner)));
    }

    case updateAdminAdminUser.type: {
      return state
        .update('adminUsers', users => users.items.map((account: ImmutableObject<AdminAccountUser>) => {
          if (action.payload.id === account.id) {
            return adminAccountOwner(action.payload);
          }
          return account;
        }));
    }

    case setAdminInvitations.type: {
      return state
        .setIn(['adminInvitations', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn(['adminInvitations', 'items'], action.payload.items);
    }

    case clearAdminInvitations.type: {
      return state
        .set('adminInvitations', clearList());
    }

    case removeAdminInvitation.type: {
      return state
        .updateIn(['adminInvitations', 'items'], items => items.filter((invitation: AdminInvitation) => invitation.id !== action.payload));
    }

    // ADMINS CAMPAIGN TEMPLATES
    case setAdminCampaignsTemplates.type: {
      return state
        .setIn(['campaignTemplate', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn(['campaignTemplates', 'items'], action.payload.items.map(campaignTemplateAdapter));
    }

    case clearAdminCampaignsTemplates.type: {
      return state
        .set('campaignTemplates', clearList());
    }

    case setAdminCampaignsTemplate.type: {
      return state
        .set('campaignTemplate', campaignTemplateAdapter(action.payload));
    }

    case clearAdminCampaignsTemplate.type: {
      return state
        .set('campaignTemplate', campaignTemplateAdapter());
    }

    case removeAdminCampaignsTemplate.type: {
      return state
        .setIn(['campaignTemplates', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['campaignTemplates', 'items'], items => items.filter((template: ImmutableObject<AdminCampaignTemplate>) => template.id !== action.payload));
    }

    case updateAdminCampaignsTemplates.type: {
      return state
        .setIn(['campaignTemplates', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['campaignTemplates', 'items'], items => items.concat(action.payload.items.map(campaignTemplateAdapter)));
    }

    case updateAdminCampaignsTemplate.type: {
      return state
        .updateIn(['campaignTemplates', 'items'], templates => templates.map((account: ImmutableObject<AdminCampaignTemplate>) => {
          if (action.payload.id === account.id) {
            return campaignTemplateAdapter(action.payload);
          }
          return account;
        }));
    }

    case setAdminAccountsCountries.type: {
      return state
        .set('accountsCountries', action.payload.map(adminAccountCountryAdapter));
    }

    case setAdminAccountsJoined.type: {
      return state
        .set('accountsJoined', action.payload.map(adminAccountJoinedAdapter));
    }

    case setAdminAccountStatistics.type: {
      return state
        .set('accountStatistics', action.payload.map(adminAccountStatisticsAdapter));
    }

    case clearCustomerSuccessLists.type: {
      return state
        .set('accountStatistics', [])
        .set('accountsJoined', [])
        .set('accountsCountries', []);
    }

    case setAdminCampaignsStatistics.type: {
      return state
        .setIn(['campaignsStatistics', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .setIn(['campaignsStatistics', 'items'], action.payload.items.map(campaignStatisticsAdapter));
    }
    case updateAdminCampaignsStatistics.type: {
      return state
        .setIn(['campaignsStatistics', 'meta'], campaignsMetaAdapter(action.payload.meta))
        .updateIn(['campaignsStatistics', 'items'], items => items.concat(action.payload.items.map(campaignStatisticsAdapter)));
    }

    default: {
      return state;
    }
  }
};

export default reducer;
