import I, { ImmutableObject } from 'seamless-immutable';
import { AccountLicense } from './types';

export function licenseAdapter(data: AccountLicense): ImmutableObject<AccountLicense> {
  if (data.account_permissions && typeof data.account_permissions === 'string') {
    data.account_permissions = JSON.parse(data.account_permissions);
  }

  return I(data);
}
