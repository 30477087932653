import { EmailSendingStatusEnum } from '../enums';

const EMAIL_STATUSES = [
  {
    value: EmailSendingStatusEnum.NotSent,
    label: 'Not sent'
  },
  {
    value: EmailSendingStatusEnum.Failed,
    label: 'Failed'
  },
  {
    value: EmailSendingStatusEnum.Sent,
    label: 'Sent'
  },
  {
    value: EmailSendingStatusEnum.Delivered,
    label: 'Delivered'
  },
  {
    value: EmailSendingStatusEnum.Opened,
    label: 'Opened'
  },
  {
    value: EmailSendingStatusEnum.Clicked,
    label: 'Opened and redirected'
  },
];

export default EMAIL_STATUSES;
