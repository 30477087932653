import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from '../../modal';
import { Props } from './index';
import MediaFilesList from './mediaFiles';
import useMediaFiles from '../../../domain/mediaFiles/useMediaFiles';
import FileEditModal from './editModal';
import { MediaFileTypesEnum } from '../../../utils/enums';
import styles from './upload.module.css';
import { ImageSize } from './editModal/useImageSize';
import { MediaFile } from '../../../utils/type';
import { CommonStoreEnum } from '../../../store/common/types';
import { useDispatch } from 'react-redux';
import { setCommonField } from '../../../store/common';

type ModalsProps = Props & {
  show: boolean;
  setShowModal: (value: boolean) => void;
};

const formatMimeTypeMapping: Record<string, string> = {
  gif: 'image/gif',
  svg: 'image/svg+xml',
  avif: 'image/avif',
};

const ModalBody:FunctionComponent<ModalsProps> = (props) => {
  const {
    name,
    value,
    onChange,
    onChangeUrl,
    tourId,
    changeConfigField,
    show,
    setShowModal,
    categories,
    multiUpload,
  } = props;

  const [ urls, setUrls ] = useState<string[]>(value ? [value] : []);
  const [ dirty, setDirty ] = useState<boolean>(false);

  const [ showEditModal, setShowEditModal ] = useState<boolean>(false);
  const [ showMedia, setShowMedia ] = useState<boolean>(false);
  const [ files, setFiles ] = useState<File[]>([]);

  const iMediaFiles = useMediaFiles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      iMediaFiles.getMediaFilesList(1, { categories, file_type: MediaFileTypesEnum.IMAGE, adminCreated: true });
      iMediaFiles.getRecentMediaFiles(1, MediaFileTypesEnum.IMAGE);

      return () => {
        iMediaFiles.clearMediaFiles();
      }
    }
  }, [show]);

  useEffect(() => {
    setDirty(true);
  }, []);

  const onCloseModal = (closeLibrary: boolean = true) => {
    setShowEditModal(false);
    if (closeLibrary) {
      setShowModal(false);
      setShowMedia(false);
    } else {
      setShowMedia(true);
    }
  };

  useEffect(() => {
    if (urls.length && dirty) {
      const newValue = multiUpload ? urls : urls[0];
      if (onChange) onChange(newValue);
      if (changeConfigField) changeConfigField({ [name]: newValue });
      if (onChangeUrl) onChangeUrl(newValue);
      onCloseModal();
      setFiles([]);
    }
  }, [urls]);

  useEffect(() => {
    if (files.length) {
      setShowEditModal(true);
      setShowMedia(false);
    }
  }, [files]);

  useEffect(() => {
    if (show) {
      setShowMedia(true);
    }
  }, [tourId, show]);

  const submitFileUpload = async (file: File, sizes: ImageSize, multiUpload?: boolean) => {
    const needsToBeAddedToList = true;

    const newMediaFile: MediaFile | null = await iMediaFiles.createMediaFile({
      categories: categories || [],
      file_name: file.name,
      file,
      file_type: 'image',
      width: sizes.width,
      height: sizes.height,
    }, needsToBeAddedToList);

    if (newMediaFile && !multiUpload) {
      setShowEditModal(false);
      setShowMedia(true);
    }

    return newMediaFile;
  }

  const editFile = (url: string) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = () => {
      const array = url.split('.');
      const existingFile = new File(
        [request.response],
        `${new Date().getTime()}.${array[array.length - 1]}`,
        { type: formatMimeTypeMapping[array[array.length - 1]] || 'image/png' }
      );
      setFiles([existingFile]);
    };
    request.send();
  }

  const onSetFiles = (files: File[]) => {
    setFiles(files);
  }

  useEffect(() => {
    if (!showMedia) {
      dispatch(setCommonField([], CommonStoreEnum.NEW_MEDIA_FILES));
    }
  }, [showMedia]);

  return (
    <>
      <Modal visible={showEditModal} onCancel={() => onCloseModal(false)} wide title="Image Upload">
        { Boolean(files.length) && (
          <FileEditModal
            { ...props }
            files={files}
            isUploading={iMediaFiles.isUploading}
            onCloseModal={() => onCloseModal(false)}
            show={show}
            submitFileUpload={submitFileUpload}
            cancelUpload={iMediaFiles.cancelUpload}
            progress={iMediaFiles.progress}
          />
        )}
      </Modal>
      <Modal visible={showMedia} onCancel={onCloseModal} wide className={styles.modal} title="Media Library">
        <MediaFilesList
          { ...props }
          urls={urls}
          selectFiles={onSetFiles}
          iMediaFiles={iMediaFiles}
          chooseFiles={setUrls}
          onEditFile={editFile}
        />
      </Modal>
    </>
  );
};

export default ModalBody;
