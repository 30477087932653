export const DEFAULT_PAGINATION = {
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0
    },
    items: []
  }
;
