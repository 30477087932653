import { createSelector } from 'reselect';
import { Store } from '../types';
import { CommonStoreEnum } from './types';

export const mainSelector = (store: Store) => store.common;
export const brandsTitlesAdapter = createSelector(mainSelector, (store) => store[CommonStoreEnum.BRAND_TITLES].asMutable({ deep: true }));
export const domainsSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.DOMAIN]?.asMutable({ deep: true }));
export const screenTemplateSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.SCREEN_TEMPLATES]?.asMutable({ deep: true }));
export const copyElementSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.COPY_ELEMENT]?.asMutable({ deep: true }) || null);
export const emailRecipientsSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.EMAIL_RECIPIENTS]?.asMutable({ deep: true }));
export const foldersSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.FOLDERS]?.asMutable({ deep: true }));
export const activeFolderSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.ACTIVE_FOLDER]);
export const accountLicensesSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.LICENSES]?.asMutable({ deep: true }));
export const showTicketSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.SHOW_TICKET]?.asMutable({ deep: true }) || null);
export const gameAnalyticsSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.GAME_ANALYTICS]?.asMutable({ deep: true }));
export const gameQuestionsAnalyticsSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.GAME_QUESTIONS_ANALYTICS]?.asMutable({ deep: true }));
export const playerQuestionsAnalyticsSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.PLAYERS_WITH_QUESTIONS_ANALYTICS]?.asMutable({ deep: true }));
export const gamesListSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.GAMES]?.asMutable({ deep: true }));
export const adventActiveDaysSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.ACTIVE_ADVENT_DAYS]?.asMutable({ deep: true }));
export const adventOriginDaySelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.ORIGIN_ADVENT_DAY]);
export const salesForceConnectionsSelector = createSelector(mainSelector, (store) => store[CommonStoreEnum.SALESFORCE_CONNECTIONS]?.asMutable({ deep: true }));
export const commonSelector = <T>(type: CommonStoreEnum) => createSelector(mainSelector, (store): T => {
  const fieldValue = store[type];

  if (fieldValue && (Array.isArray(fieldValue) || typeof fieldValue === 'object') && typeof (fieldValue as any)?.asMutable === 'function') {
    return (fieldValue as any)?.asMutable({ deep: true });
  }

  return fieldValue as unknown as T;
});
