import I, { ImmutableArray } from 'seamless-immutable';
import { Action, HubSpotConnection, MailChimpConnection, Segment, WebhookEvent } from './types';
import {
  addSegment,
  addWebhookEvents,
  clearIntegrationAction,
  removeHubSpotConnection,
  removeMailChimpConnection,
  removeSegment,
  setHubSpotConnections,
  setIntegration,
  setMailChimpConnections,
  setSegments,
  setWebhookEvents,
  updateSegment
} from './actions';
import {
  hubSpotConnectionAdapter,
  integrationAdapter,
  integrationStoreAdapter,
  mailChimpConnectionAdapter,
  metaAdapter,
  segmentAdapter,
  segmentsAdapter,
  webhookEventsAdapter,
  webhookEventsObjectAdapter
} from './adapters';

const initialState = integrationStoreAdapter();

const reducer = (state = initialState, action: Action) => {
  switch(action.type) {
    case setIntegration.type: {
      return state.set('integration', integrationAdapter(action.payload));
    }

    case setSegments.type: {
      return state.set('segments', segmentsAdapter(action.payload));
    }

    case setWebhookEvents.type: {
      return state.set('events', webhookEventsObjectAdapter(action.payload));
    }

    case addWebhookEvents.type: {
      return state
        .setIn(['events', 'meta'], metaAdapter(action.payload.meta))
        .updateIn(['events', 'items'], (items: ImmutableArray<WebhookEvent[]>) => items.concat(webhookEventsAdapter(action.payload.items)))
    }

    case addSegment.type: {
      return state.update('segments', (segments: ImmutableArray<Segment[]>) => segments.concat(I([segmentAdapter(action.payload)])));
    }

    case updateSegment.type: {//@ts-ignore
      return state.update('segments', (segments: ImmutableArray<Segment[]>) => segments.map((item: Segment) => {
        if (item.id === action.payload.id) return segmentAdapter(action.payload);
        return item;
      }));
    }

    case removeSegment.type: { //@ts-ignore
      return state.update('segments', (segments: ImmutableArray<Segment[]>) => segments.filter((item: Segment) => item.id !== action.payload));
    }

    case clearIntegrationAction.type: {
      return integrationStoreAdapter();
    }

    case setHubSpotConnections.type: {
      return state.set('hubspotConnections', action.payload.map(hubSpotConnectionAdapter));
    }

    case removeHubSpotConnection.type: {
      //@ts-ignore
      return state.update('hubspotConnections', items => items.filter((item: HubSpotConnection) => item.id !== action.payload));
    }

    case setMailChimpConnections.type: {
      return state.set('mailchimpConnections', action.payload.map(mailChimpConnectionAdapter));
    }

    case removeMailChimpConnection.type: {
      //@ts-ignore
      return state.update('mailchimpConnections', items => items.filter((item: MailChimpConnection) => item.id !== action.payload));
    }

    default:
      return state;
  }
}

export default reducer;
