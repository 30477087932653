import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import styles from '../upload.module.css';

type CropPointProps = {
  position: number;
  cropWrapperRef: any;
  cropSizes: any;
  containerRef: any
  onDragEnd: (width: number, height: number) => void;
  onChangeCropPoint: (value: any) => void;
}

let dragStartPosition: any = null;

type CropAreaSizes = {
  left: number;
  top: number;
  width: number;
  height: number;
}

const CropPoint:FunctionComponent<CropPointProps> = ({ onChangeCropPoint, cropSizes, cropWrapperRef, position, onDragEnd, containerRef }) => {
  const [dragging, onDragging] = useState<boolean>(false);
  const cropPointRef = useRef<HTMLDivElement | null>(null);

  const positions = [
    {x: 0, y: 0, cursor: 'nw-resize'},
    {x: 0, y: 50, cursor: 'w-resize'},
    {x: 0, y: 100, cursor: 'ne-resize'},
    {x: 50, y: 0, cursor: 'n-resize'},
    {x: 50, y: 100, cursor: 'n-resize'},
    {x: 100, y: 0, cursor: 'ne-resize'},
    {x: 100, y: 50, cursor: 'w-resize'},
    {x: 100, y: 100, cursor: 'nw-resize'},
  ];

  const cropPointNewSizes = (position: number, left: number, top: number): Partial<CropAreaSizes> | undefined => {
    switch (position) {
      //NW Corner
      case 0:
        return {
          left,
          top,
          width: dragStartPosition.leftPlusWidth - left,
          height: dragStartPosition.topPlusHeight - top,
        }

      //W Side
      case 1:
        return {
          left,
          width: dragStartPosition.leftPlusWidth - left,
        }

      //SW Corner
      case 2:
        return {
          left,
          width: dragStartPosition.leftPlusWidth - left,
          height: top - dragStartPosition.top * 2 + dragStartPosition.topPlusHeight,
        }

      //N Side
      case 3:
        return {
          top,
          height: dragStartPosition.topPlusHeight - top,
        }

      //S Side
      case 4:
        return {
          height: top - dragStartPosition.top * 2 + dragStartPosition.topPlusHeight,
        }

      //NE Corner
      case 5:
        return {
          top,
          width: left - dragStartPosition.left * 2 + dragStartPosition.leftPlusWidth,
          height: dragStartPosition.topPlusHeight - top,
        }

      //E Side
      case 6:
        return {
          width: left - dragStartPosition.left * 2 + dragStartPosition.leftPlusWidth,
        }

      //SE Corner
      case 7:
        return {
          width: left - dragStartPosition.left * 2 + dragStartPosition.leftPlusWidth,
          height: top - dragStartPosition.top * 2 + dragStartPosition.topPlusHeight,
        }

    }
  }

  const startDrag = useCallback((e: any) => {
    e.stopPropagation();
    onDragging(true);
    const rect = cropWrapperRef.current.getBoundingClientRect();
    const startLeft = e.clientX - rect.left;
    const startTop = e.clientY - rect.top;
    dragStartPosition = {
      left: startLeft,
      top: startTop,
      leftPlusWidth: startLeft + cropSizes.width,
      topPlusHeight: startTop + cropSizes.height,
    };
  }, [cropWrapperRef.current, cropSizes]);

  const endDrag = useCallback((e: any) => {
    e.stopPropagation();
    onDragging(false);
    onDragEnd(cropSizes.width, cropSizes.height);
  }, [cropSizes.width, cropSizes.height]);

  const dragArea = useCallback((event: any) => {
    event.stopPropagation();
    const point = cropPointRef.current;
    if (!dragging || !cropWrapperRef?.current || !point) return;

    const rect = cropWrapperRef.current.getBoundingClientRect();
    const newX: number = event.clientX - rect.left;
    const newY: number = event.clientY - rect.top;

    const newCropSizes: Partial<CropAreaSizes> | undefined = cropPointNewSizes(position, newX, newY);

    if (!newCropSizes) {
      return;
    }

    const newPoint = { ...cropSizes, ...newCropSizes };

    if (newPoint.width < 0 || newPoint.height < 0) return;

    // if cursor out of bounds correct new point positions
    if (newPoint.left < 0 ) {
      newPoint.left = 0;
      newPoint.width = cropSizes.width;
    }

    if (newPoint.top < 0 ) {
      newPoint.top = 0;
      newPoint.height = cropSizes.height;
    }

    if (newPoint.left + newPoint.width > cropWrapperRef.current.offsetWidth) {
      newPoint.width = cropWrapperRef.current.offsetWidth - newPoint.left;
    }

    if (newPoint.top + newPoint.height > cropWrapperRef.current.offsetHeight) {
      newPoint.height = cropWrapperRef.current.offsetHeight - newPoint.top;
    }

    onChangeCropPoint(newPoint);
  }, [cropSizes, dragging, cropWrapperRef, cropPointRef]);

  useEffect(() => {
    const point = cropPointRef.current;
    const wrapper = cropWrapperRef.current;
    const container = containerRef.current;
    if (!point || !wrapper || !container) return;
    point.addEventListener('pointerdown', startDrag);
    wrapper.addEventListener('pointerup', endDrag);
    document.addEventListener('pointermove', dragArea);
    document.addEventListener('pointerup', endDrag);
    return () => {
      point.removeEventListener('pointerdown', startDrag);
      wrapper.removeEventListener('pointerup', endDrag);
      document.removeEventListener('pointermove', dragArea);
      document.removeEventListener('pointerup', endDrag);
    };
  }, [cropPointRef.current, cropWrapperRef.current, containerRef.current, dragArea, dragArea, startDrag]);

  return (
    <div
      ref={cropPointRef}
      style={{ left: `${positions[position].x}%`, top: `${positions[position].y}%`, cursor: positions[position].cursor}}
      className={styles.cropPoint}
    />
  )
}

export default CropPoint;
