import React, { FunctionComponent } from 'react';
import Typo from '../../typo';
import cx from 'classnames';
import styles from './radio.module.css';
import WhiteField from '../field/white';

interface Props {
  className?: string;
  label?: string;
  name: string;
  refProp?: any;
  checked: boolean;
  value?: string | number;
  onRadioChange?: (value?: string | number) => void;
  onClick?: () => void;
  disabled?: boolean;
  errors?: string[];
  cypressId?: string;
  clickableLabel?: boolean;
}

const WhiteRadio:FunctionComponent<Props> = (props) => {
  const { className, label, value, refProp, errors, name, checked, onRadioChange, cypressId, disabled, onClick, clickableLabel } = props;
  return (
    <WhiteField name={name} className={cx(className, styles.wrapper)} errors={errors}>
      <label className={cx(styles.radio, {[styles.disabledRadio]: disabled})}>
        <span className={styles.radioWrapper}>
          <input type="radio" cypress-id={`${name}-component`} name={name} checked={checked} onChange={() => onRadioChange ? onRadioChange(value) : {}} onClick={onClick} disabled={disabled} ref={refProp} data-cypress-id={`${cypressId || name}-component`}/>
          <span className={cx(styles.spot, { [styles.checked]: checked, [styles.disabled]: disabled})} />
        </span>
        {label ? <Typo type="p" white className={styles.label}>{label}</Typo> : null}
      </label>
    </WhiteField>
  );
};

export default WhiteRadio;
